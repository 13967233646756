<template>
  <div class="modal fade login-modal" tabindex="-1" role="dialog">
    <login-dialog
      @cancel="close"
      @tfa-required="showTFADialog"
      v-if="dialog === 'login'">
    </login-dialog>

    <two-factor-dialog
      @cancel="close"
      v-if="dialog === 'tfa'">
    </two-factor-dialog>
  </div>
</template>

<script>
import LoginDialog from './dialogs/Login'
import TwoFactorDialog from './dialogs/TwoFactor'

export default {
  components: {
    LoginDialog,
    TwoFactorDialog
  },
  data () {
    return {
      dialog: 'login'
    }
  },
  methods: {
    showTFADialog () {
      this.dialog = 'tfa'
    }
  }
}
</script>
