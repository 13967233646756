<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <small-loader></small-loader>
      </div>
    </div>
  </div>
</template>

<script>
import SmallLoader from '../../common/SmallLoader'

export default {
  components: {
    SmallLoader
  }
}
</script>
