<template>
  <span>{{ name }}</span>
</template>

<script>
// Reference: https://github.com/stefangabos/world_countries
import _ from 'lodash'
import countries from '../../data/countries/en.json'

export default {
  props: {
    code: {
      type: String,
      required: true
    }
  },
  computed: {
    name () {
      if (this.code) {
        const lowerCode = this.code.toLowerCase()
        const country = _.find(countries, { alpha2: lowerCode })

        if (country) {
          return country.name
        }
      }

      return ''
    }
  }
}
</script>
