<template>
  <div class="standard-plan-selector">
    <div class="container">
      <div class="dashhead nosub">
        <div class="dashhead-titles">
          <h3 class="dashhead-title">Select a plan</h3>
        </div>
      </div>
    </div>

    <div class="container mt-3 mb-3" v-if="!isLoaded">
      <small-loader></small-loader>
    </div>

    <div class="container mt-3 mb-3" v-if="isLoaded">
      <div class="row">
        <div class="col-sm-4 mb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Free plan</h5>
              <p class="card-text">For private use only</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">AES 256 encryption</li>
              <li class="list-group-item">Unlimited passwords</li>
              <li class="list-group-item">1 bucket</li>
              <li class="list-group-item">No included sharing</li>
              <li class="list-group-item">No additional sharing</li>
              <li class="list-group-item">
                <div class="price">FREE</div>
              </li>
            </ul>
            <div class="card-body subscribe-button">
              <button class="btn btn-primary" @click="selectPlan('free')" :disabled="!isFreePlanAvailable">
                Subscribe
              </button>
            </div>
            <div class="card-body" v-if="!isFreePlanAvailable">
              Your current usage exceeds this plan limits. Remove buckets and/or sharings if you want to select it.
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Personal</h5>
              <p class="card-text">Suitable for non-commercial use</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">AES 256 encryption</li>
              <li class="list-group-item">Unlimited passwords</li>
              <li class="list-group-item">Unlimited buckets</li>
              <li class="list-group-item">3 included sharing</li>
              <li class="list-group-item">No additional sharing</li>
              <li class="list-group-item">
                <div class="price">12 $ / year</div>
              </li>
            </ul>
            <div class="card-body subscribe-button">
              <button class="btn btn-primary" @click="selectPlan('personal')" :disabled="!isPersonalPlanAvailable">
                Subscribe
              </button>
            </div>
            <div class="card-body" v-if="!isFreePlanAvailable">
              Your current usage exceeds this plan limits. Remove sharings if you want to select it.
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Premium</h5>
              <p class="card-text">For individual and small business</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">AES 256 encryption</li>
              <li class="list-group-item">Unlimited passwords</li>
              <li class="list-group-item">Unlimited buckets</li>
              <li class="list-group-item">10 included sharing</li>
              <li class="list-group-item">Additional sharing: 2$ / mo</li>
              <li class="list-group-item">
                <div class="price">10 $ / month</div>
              </li>
            </ul>
            <div class="card-body subscribe-button">
              <button class="btn btn-primary" @click="selectPlan('premium')">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>

      <p class="mt-2">All prices are in US Dollars. <span v-if="trial">{{ trial }} days FREE trial.</span></p>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import SmallLoader from '../common/SmallLoader'

export default {
  props: {
    guest: {
      type: Boolean,
      default: false
    },
    trial: {
      required: false,
      default: null
    }
  },
  components: {
    SmallLoader
  },
  data () {
    return {
      isLoaded: false,
      sharings: 0,
      buckets: 0
    }
  },
  computed: {
    isFreePlanAvailable () {
      return this.buckets === 0 && this.sharings === 0
    },
    isPersonalPlanAvailable () {
      return this.sharings <= 3
    }
  },
  methods: {
    fetchUsage () {
      return api.usage.getUsage()
        .then((usage) => {
          this.sharings = usage.sharings
          this.buckets = usage.buckets
          this.isLoaded = true
        })
    },
    canSelectPlan (type) {
      if (type === 'free' && !this.isFreePlanAvailable) {
        return false
      }

      if (type === 'personal' && !this.isPersonalPlanAvailable) {
        return false
      }

      return true
    },
    selectPlan (type) {
      const selectable = this.canSelectPlan(type)

      if (selectable) {
        this.$emit('selected', type)
      }
    }
  },
  created () {
    if (this.guest) {
      this.isLoaded = true
    } else {
      this.fetchUsage()
    }
  }
}
</script>
