<template>
  <select class="form-control" v-model="selection" :class="{ 'is-invalid': isInvalid }">
    <option v-for="option in options" :value="option.value" :key="option.value">{{ option.label }}</option>
  </select>
</template>

<script>
import _ from 'lodash'
import usStates from '../../data/states/us/en.json'
import caProvinces from '../../data/states/ca/en.json'

export default {
  props: {
    country: {
      type: String
    },
    value: {
      type: String,
      default: ''
    },
    isInvalid: {
      default: false
    }
  },
  data () {
    return {
      selection: this.value,
      options: []
    }
  },
  methods: {
    fillOptions () {
      let source = []
      const options = []

      if (this.country.toLowerCase() === 'ca') {
        source = caProvinces
      } else if (this.country.toLowerCase() === 'us') {
        source = usStates
      }

      source.forEach((state) => {
        options.push({
          value: state.code.toUpperCase(),
          label: state.name
        })
      })

      this.options = _.orderBy(options, ['label'], ['asc'])
    }
  },
  created () {
    this.fillOptions()
  },
  watch: {
    selection (value) {
      this.$emit('input', value)
    },
    country () {
      this.fillOptions()
    },
    options () {
      if (!_.find(this.options, { value: this.selection })) {
        this.selection = ''
      }
    }
  }
}
</script>
