/* eslint-disable */

import Vue from 'vue'
import VueNoty from 'vuejs-noty'
import VueJWT from 'vuejs-jwt'
import VueClipboard from 'vue-clipboard2'
import VueCollapse from 'vue2-collapse'
import jquery from 'jquery'
import fontawesome from '@fortawesome/fontawesome'
import faSolid from '@fortawesome/fontawesome-free-solid';
import faBrands from '@fortawesome/fontawesome-free-brands'
import faRegular from '@fortawesome/fontawesome-free-regular'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
import VueModal from './modules/vue-modal-box'
import config from './config'
import store from './store'

fontawesome.library.add(faSolid, faBrands, faRegular)

Vue.component('fa-icon', FontAwesomeIcon)

//
// Load jquery and other JS dependencies
//

window.$ = jquery
window.jQuery = jquery

require('popper.js/dist/popper')
require('bootstrap/dist/js/bootstrap')

require('./directives/tooltip')

// Fix clipboard.js issues - https://github.com/zenorocha/clipboard.js/issues/155#issuecomment-217372642
$.fn.modal.Constructor.prototype._enforceFocus = function() {};

//
// Plugin registration
//

Vue.use(VueModal)

Vue.use(VueNoty, {
  timeout: 4000,
  progressBar: true,
  theme: 'mint'
})

Vue.use(VueJWT, {
  keyName: config.auth.tokenKeyName,
  ignoreNotBefore: true,
  ignoreExpiration: true
})

Vue.use(VueClipboard)
Vue.use(VueCollapse)

//
// Handle JWT Token and profiles
//

store.dispatch('loadIdentities')

const token = Vue.$jwt.getToken()

if (token) {
  const payload = Vue.$jwt.decode()

  if (payload) {
    store.commit('SET_SEK', payload.sek)

    store.commit('SET_USER', {
      id: payload.sub
    })

    store.commit('USER_AUTHENTICATED')
  }
}

//
// Services settings
//

Stripe.setPublishableKey(config.stripe.pubKey)
