<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ modalTitle }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" v-if="errorType === 'internal'">
              <strong>Server error</strong>
              <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'forbidden'">
              <strong>Access denied</strong>
              <p class="mb-0">You are not allowed to create an entry here.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'not_found'">
              <strong>Error</strong>
              <p class="mb-0">Bucket or list not found.</p>
            </div>

            <note-form v-model="form" :errors="errors" v-if="entryType === 'note'"></note-form>
            <password-form v-model="form" :errors="errors" v-if="entryType === 'password'"></password-form>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
              Create
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../../api'
import createEditEntryMixin from '../../../mixins/entries/createEdit'

export default {
  mixins: [createEditEntryMixin],
  data () {
    return {
      entryType: null,
      bucketId: null,
      listId: null
    }
  },
  computed: {
    modalTitle () {
      return this.entryType === 'note' ? 'Create note' : 'Create password'
    }
  },
  methods: {
    submit () {
      this.startProcess()

      const encrypted = this.getEncryptedPayload(this.form)

      const payload = _.assign({}, encrypted, {
        type: this.entryType,
        bucketId: this.bucketId,
        list_id: this.listId
      })

      api.entries.create(payload)
        .then((entry) => {
          this.done(entry)
        })
        .catch(this.handleError)
    }
  },
  created () {
    this.entryType = this.data.type
    this.bucketId = this.data.bucket
    this.listId = this.data.list
  }
}
</script>
