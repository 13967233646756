<template>
  <div class="view-team-users-manage">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <div class="alert alert-danger" v-if="errorType === 'internal'">
          <strong>Server error</strong>
          <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
        </div>

        <div class="alert alert-danger" v-if="errorType === 'forbidden'">
          <strong>Sorry,</strong>
          <p class="mb-0">You are not allowed to perform this action.</p>
        </div>

        <form action="" @submit.prevent="submit()">
          <div class="form-group row">
            <label for="inputFirstname" class="col-sm-3 col-form-label">Firstname</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputFirstname" v-model="form.firstname" :class="{ 'is-invalid': errors.firstname }">
              <div class="invalid-feedback" v-if="errors.firstname">{{ errors.firstname[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputLastname" class="col-sm-3 col-form-label">Lastname</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputLastname" v-model="form.lastname" :class="{ 'is-invalid': errors.lastname }">
              <div class="invalid-feedback" v-if="errors.lastname">{{ errors.lastname[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail" class="col-sm-3 col-form-label">Email</label>
            <div class="col-sm-9 col-lg-4">
              <input type="email" class="form-control" id="inputEmail" v-model="form.email" :class="{ 'is-invalid': errors.email }">
              <div class="invalid-feedback" v-if="errors.email">{{ errors.email[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmailAgain" class="col-sm-3 col-form-label">Repeat email</label>
            <div class="col-sm-9 col-lg-4">
              <input type="email" class="form-control" id="inputEmailAgain" v-model="form.email_confirm" :class="{ 'is-invalid': errors.email_confirm }">
              <div class="invalid-feedback" v-if="errors.email_confirm">{{ errors.email_confirm[0] }}</div>
            </div>
          </div>

          <div class="form-group row mt-3">
            <div class="col-sm-9 col-lg-4 offset-sm-3">
              <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../../../api'
import SmallLoader from '../../../../components/common/SmallLoader'
import asyncProcess from '../../../../mixins/asyncProcess'
import withEnterprise from '../../../../mixins/withEnterprise'

export default {
  mixins: [asyncProcess, withEnterprise],
  components: {
    SmallLoader
  },
  data () {
    return {
      isLoading: true,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        email_confirm: ''
      }
    }
  },
  methods: {
    loadData () {
      api.users.get({
        enterpriseId: this.enterpriseId,
        id: this.$route.params.userId
      })
        .then((user) => {
          this.mapForm(user)
          this.isLoading = false
        })
    },
    mapForm (user) {
      this.form = _.assign({}, _.pick(user, ['firstname', 'lastname', 'email']), {
        email_confirm: user.email
      })
    },
    submit () {
      this.startProcess()

      const payload = {
        ...this.form,
        id: this.$route.params.userId,
        enterpriseId: this.enterpriseId
      }

      api.users.update(payload)
        .then(() => {
          this.endProcess()
          this.$noty.success('User account updated.')
        })
        .catch(this.handleError)
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    $route: function () {
      this.loadData()
    }
  }
}
</script>
