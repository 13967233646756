/* eslint-disable no-undef */
import Vue from 'vue'

Vue.directive('tooltip', {
  inserted (el, binding) {
    $(el).tooltip({
      title: binding.value,
      placement: 'bottom'
    })

    $(el).on('click', () => {
      $(el).tooltip('hide')
    })
  },
  unbind (el) {
    $(el).tooltip('dispose')
  }
})
