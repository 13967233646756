<template>
  <select class="form-control" v-model="selection" :class="{ 'is-invalid': isInvalid }">
    <option v-for="option in options" :value="option.value" :key="option.value">{{ option.label }}</option>
  </select>
</template>

<script>
import _ from 'lodash'
import countries from '../../data/countries/en.json'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    isInvalid: {
      default: false
    }
  },
  data () {
    return {
      selection: this.value,
      options: []
    }
  },
  methods: {
    fillOptions () {
      const options = []

      countries.forEach((country) => {
        options.push({
          value: country.alpha2.toUpperCase(),
          label: country.name
        })
      })

      this.options = _.orderBy(options, ['label'], ['asc'])
    }
  },
  created () {
    this.fillOptions()
  },
  watch: {
    selection (value) {
      this.$emit('input', value)
    }
  }
}
</script>
