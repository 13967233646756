<template>
  <div class="modal-dialog" role="document">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit list</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" v-if="errorType === 'internal'">
              <strong>Server error</strong>
              <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'forbidden'">
              <strong>Sorry,</strong>
              <p class="mb-0">You are not allowed to modify this list.</p>
            </div>

            <div class="form-group has-error">
              <label for="list-name" class="col-form-label">List name</label>
              <input type="text" class="form-control" id="list-name" v-model="list.name" :class="{ 'is-invalid': errors.name }">
              <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="mr-auto">
              <button type="button" class="btn btn-outline-secondary" @click="deleteList()">
                <fa-icon icon="trash"></fa-icon>
              </button>
            </div>

            <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
              Save
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from '../../../../api'
import asyncProcess from '../../../../mixins/asyncProcess'

export default {
  mixins: [asyncProcess],
  props: {
    list: {
      type: Object,
      required: true
    }
  },
  methods: {
    deleteList () {
      this.$emit('delete')
    },
    submit () {
      this.startProcess()

      const data = {
        id: this.list.uid,
        name: this.list.name
      }

      api.lists.update(data)
        .then((list) => {
          this.$store.commit('BUCKET_LISTS_UPDATED', list)
          this.$emit('updated')
        })
        .catch(this.handleError)
    }
  }
}
</script>
