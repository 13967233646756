<template>
  <div class="billing-cards">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <div class="no-result" v-if="!cards.length">
          No card found.
          <div class="mt-3">
            <button type="button" class="btn btn-outline-primary" @click="addCard()">Add card</button>
          </div>
        </div>

        <div v-else>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th></th>
                <th>Card Number</th>
                <th>Expiration</th>
                <th>Card Holder</th>
                <th>Default</th>
                <th style="width: 60px;"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="card in cards" :key="card.id">
                <td class="col-icon">
                  <span v-if="card.type === 'Visa'"><fa-icon :icon="['fab', 'cc-visa']" size="2x"></fa-icon></span>
                  <span v-else-if="card.type === 'MasterCard'"><fa-icon :icon="['fab', 'cc-mastercard']" size="2x"></fa-icon></span>
                  <span v-else-if="card.type === 'American Express'"><fa-icon :icon="['fab', 'cc-amex']" size="2x"></fa-icon></span>
                  <span v-else><fa-icon :icon="['far', 'credit-card']" size="2x"></fa-icon></span>
                </td>
                <td>**** {{ card.last4 }}</td>
                <td>{{ formatMonth(card.exp_month) }}/{{ card.exp_year }}</td>
                <td>{{ card.name }}</td>
                <td>
                  <span v-if="card.is_default === 1">Yes</span>
                  <span v-else>No</span>
                </td>
                <td class="col-actions text-right">
                <span class="action" @click="deleteCard(card)">
                  <fa-icon icon="times"></fa-icon>
                </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-4">
            <button type="button" class="btn btn-outline-primary" @click="addCard()">Add card</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '../../../api'
import SmallLoader from '../../../components/common/SmallLoader'
import AddCardModal from '../../../components/modals/cards/AddCard'
import DeleteCardModal from '../../../components/modals/cards/Delete'

export default {
  components: {
    SmallLoader
  },
  computed: {
    ...mapGetters({
      subscriber: 'getSubscriber',
      subscriberId: 'getSubscriberId'
    })
  },
  data () {
    return {
      isLoading: true,
      cards: []
    }
  },
  methods: {
    async fetchData () {
      this.isLoading = true

      if (!this.subscriberId) {
        await this.$store.dispatch('fetchSubscriber')
      }

      this.cards = await api.creditCards.getBySubscriber({ uid: this.subscriberId })
      this.isLoading = false
    },
    formatMonth (value) {
      return (`0${value}`).slice(-2)
    },
    addCard () {
      this.$modal.open(AddCardModal)
        .done(() => {
          this.fetchData()
        })
    },
    deleteCard (card) {
      this.$modal.open(DeleteCardModal, { card })
        .done((confirmed) => {
          if (!confirmed) {
            return
          }

          const index = this.cards.indexOf(card)
          if (index > -1) {
            this.cards.splice(index, 1)
          }

          api.creditCards.delete({
            uid: this.subscriberId,
            id: card.id
          })
            .catch(({ type }) => {
              this.cards.push(card)
              if (type === 'internal') {
                this.$noty.warning('Error while deleting card.')
              } else if (type === 'forbidden') {
                this.$noty.warning('You are not allowed to perform this action.')
              }
            })
        })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
