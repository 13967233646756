<template>
  <div class="result-row" @click="openEntry">
    <div class="result-title" v-html="title"></div>
    <div class="result-description">{{ result.bucket.name }}</div>
  </div>
</template>

<script>
import _ from 'lodash'
import ShowEntryModal from '../../modals/entries/Show'

export default {
  props: {
    result: {
      type: Object,
      required: true
    },
    keyword: {
      type: String,
      required: true
    }
  },
  computed: {
    title () {
      return _.replace(this.result.name, new RegExp(this.keyword, 'ig'), text => `<span class="highlight">${text}</span>`)
    }
  },
  methods: {
    openEntry () {
      this.$modal.open(ShowEntryModal, {
        uid: this.result.uid,
        bucket: this.result.bucket.uid
      })

      this.$emit('clicked')
    }
  }
}
</script>
