<template>
  <tr class="align-items-center bucket-user-access-row">
    <td class="col-name">{{ user.display_email }}</td>
    <td class="col-level">
      <span v-if="user.access_type === 'owner'">Owner</span>
      <select class="form-control" v-model="access_type" v-else>
        <option value="admin">Administrator</option>
        <option value="write">Write</option>
        <option value="read">Read</option>
      </select>
    </td>
    <td class="text-right">
      <span class="text-secondary remove-icon" @click="initiateDelete()" v-if="user.access_type !== 'owner' && !pendingUpdate">
        <fa-icon icon="times"></fa-icon>
      </span>
      <span v-if="pendingUpdate">
        <button type="button" class="btn btn-secondary"
                @click="updateAccess()"
                :class="{ 'loading-btn': processingUpate }"
                :disabled="processingUpate">
          Apply
        </button>
      </span>
    </td>
  </tr>
</template>

<script>
import api from '../../../api'
import DeleteUserModal from '../../modals/buckets/access/Delete'

export default {
  props: {
    bucketId: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      access_type: this.user.access_type,
      pendingUpdate: false,
      processingUpate: false
    }
  },
  methods: {
    initiateDelete () {
      this.$modal.open(DeleteUserModal)
        .done((confirmed) => {
          if (confirmed === true) {
            this.doDelete()
          }
        })
    },
    doDelete () {
      api.userAccess.delete({ bucketId: this.bucketId, id: this.user.id })
        .then(() => {
          this.$emit('deleted', this.user.id)
        })
        .catch(({ type }) => {
          if (type === 'forbidden') {
            this.$noty.warning('Sorry, you can not modify bucket access.')
          } else {
            this.$noty.warning('A server error occured, please retry in a few moment!')
          }
        })
    },
    updateAccess () {
      this.processingUpate = true

      const data = {
        access_type: this.access_type,
        bucketId: this.bucketId,
        id: this.user.id
      }

      api.userAccess.update(data)
        .then((access) => {
          this.$emit('updated', access)
          this.pendingUpdate = false
          this.processingUpate = false
          this.$noty.success('User access has been updated.')
        })
        .catch(({ type }) => {
          if (type === 'forbidden') {
            this.$noty.warning('Sorry, you can not modify bucket access.')
          } else {
            this.$noty.warning('A server error occured, please retry in a few moment!')
          }

          this.pendingUpdate = false
          this.processingUpate = false
        })
    }
  },
  computed: {
    accessType () {
      const accessTypes = {
        owner: 'Owner',
        admin: 'Administrator',
        write: 'Write',
        read: 'Read'
      }

      return accessTypes[this.user.access_type] ? accessTypes[this.user.access_type] : ''
    }
  },
  watch: {
    access_type (newValue, oldValue) {
      if (oldValue && newValue !== oldValue) {
        this.pendingUpdate = true
      }
      if (newValue === this.user.access_type) {
        this.pendingUpdate = false
      }
    }
  }
}
</script>

<style lang="scss">
  .bucket-user-access-row {
    td {
      vertical-align: middle;
    }

    .remove-icon {
      cursor: pointer;
    }

    select.form-control {
      width: auto;
    }
  }
</style>
