<template>
  <sub-menu-layout
    :nav-items="navItems"
    :select-label="activeMenu"
    header-title="My settings">
    <router-view></router-view>
  </sub-menu-layout>
</template>

<script>
import SubMenuLayout from '../../components/layouts/SubMenuLayout'

export default {
  components: {
    SubMenuLayout
  },
  data () {
    return {
      bucket: {},
      navItems: [
        {
          name: 'My details',
          link: { name: 'settings.details' }
        },
        {
          name: 'Account password',
          link: { name: 'settings.password' }
        },
        {
          name: 'Two-factor security',
          link: { name: 'settings.tfa' }
        }
      ]
    }
  },
  computed: {
    activeMenu () {
      return this.$route.meta && this.$route.meta.selectorTitle
        ? this.$route.meta.selectorTitle : 'My details'
    }
  }
}
</script>
