<template>
  <div class="view-team-permissions">
    <div class="card" v-if="canManagePermissions">
      <div class="card-body">
        <permission-manager model="enterprise" :object-id="enterpriseId"></permission-manager>
      </div>
    </div>
    <access-denied v-else></access-denied>
  </div>
</template>

<script>
import withEnterprise from '../../../mixins/withEnterprise'
import withRootPermissions from '../../../mixins/withRootPermissions'
import PermissionManager from '../../../components/permissions/Manager'

export default {
  components: {
    PermissionManager
  },
  mixins: [withEnterprise, withRootPermissions]
}
</script>
