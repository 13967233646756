<template>
  <div id="app">
    <router-view></router-view>
    <modal></modal>
  </div>
</template>

<script>
/* eslint-disable */
import './styles/main.scss'
import Modal from './modules/vue-modal-box/components/ModalContainer'

export default {
  name: 'app',
  components: {
    Modal
  }
}
</script>
