<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <loading-dialog v-if="isLoading"></loading-dialog>

    <display-entry v-if="!isLoading && dialog === 'show'"
                   :entry="decrypted"
                   @delete="switchToDeleteMode"
                   @edit="switchToEditMode"
                   @manage-permissions="managePermissions">
    </display-entry>

    <delete-entry v-if="!isLoading && dialog === 'delete'"
                  :id="entry.uid"
                  :bucket="data.bucket"
                  @deleted="close"
                  @cancel="switchToDisplayMode">
    </delete-entry>

    <edit-entry v-if="!isLoading && dialog === 'edit'"
                :type="entry.type"
                :entry="decrypted"
                :id="entry.uid"
                :bucket="data.bucket"
                @updated="close"
                @cancel="switchToDisplayMode">
    </edit-entry>
  </div>
</template>

<script>
import api from '../../../api'
import LoadingDialog from '../dialogs/Loading'
import DisplayEntry from './dialogs/Display'
import DeleteEntry from './dialogs/Delete'
import EditEntry from './dialogs/Edit'
import Crypto from '../../../providers/Crypto'
import PermissionManager from '../permissions/Manage'

export default {
  components: {
    LoadingDialog,
    DisplayEntry,
    DeleteEntry,
    EditEntry
  },
  data () {
    return {
      isLoading: true,
      dialog: 'show',
      entry: {},
      decrypted: {}
    }
  },
  methods: {
    decrypt (entry) {
      return Object.assign({}, entry, {
        username: Crypto.decrypt(entry.username, this.$store.state.auth.sek),
        password: Crypto.decrypt(entry.password, this.$store.state.auth.sek),
        text: Crypto.decrypt(entry.text, this.$store.state.auth.sek)
      })
    },
    switchToDeleteMode () {
      this.dialog = 'delete'
    },
    switchToEditMode () {
      this.dialog = 'edit'
    },
    switchToDisplayMode () {
      this.dialog = 'show'
    },
    managePermissions () {
      this.close()

      setTimeout(() => {
        this.$modal.open(PermissionManager, {
          model: 'entry',
          objectId: this.entry.uid
        })
      }, 400)
    }
  },
  created () {
    api.entries.getEntry({
      bucketId: this.data.bucket,
      id: this.data.uid
    })
      .then((entry) => {
        this.entry = entry
        this.decrypted = this.decrypt(entry)
        this.isLoading = false
      })
      .catch(() => {
        this.$noty.warning('Server error. Please try again in a few moment.')
      })
  }
}
</script>
