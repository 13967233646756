/* eslint-disable */
import config from '../config'
import http from './http'
import resource from './resource'

/* eslint-disable camelcase */

const accessLists = resource(`${config.api.path}/enterprises/:enterpriseId/access-lists/:id`, {
  getListByEntity: { url: `${config.api.path}/enterprises/:enterpriseId/access-lists/:model/:objectId` }
})

const accessPolicies = resource(`${config.api.path}/enterprises/:enterpriseId/access-policy/:model/:objectId`)

const archives = resource(`${config.api.path}/archives/:id`, {
  getListByUser: { url: `${config.api.path}/me/archives/:type` },
  bulkCreate: { method: 'POST', url: `${config.api.path}/bulk/archive/:type` },
  bulkDelete: { method: 'POST', url: `${config.api.path}/bulk/unarchive/:type` }
})

const bookmarks = resource(`${config.api.path}/bookmarks/:id`, {
  getListByUser: { url: `${config.api.path}/me/bookmarks/:type` },
  bulkCreate: { method: 'POST', url: `${config.api.path}/bulk/bookmark/:type` },
  bulkDelete: { method: 'POST', url: `${config.api.path}/bulk/delete-bookmark/:type` }
})

const buckets = resource(`${config.api.path}/buckets/:id`)

const creditCards = resource(`${config.api.path}/credit-cards/:id`, {
  getBySubscriber: { url: `${config.api.path}/subscribers/:uid/credit-cards` },
  create: { method: 'POST', url: `${config.api.path}/subscribers/:uid/credit-cards` },
  delete: { method: 'DELETE', url: `${config.api.path}/subscribers/:uid/credit-cards/:id` },
})

const currentUser = resource(`${config.api.path}/me`, {
  changePassword: { method: 'PUT', url: `${config.api.path}/me/password` },
  getTFANumber: { url: `${config.api.path}/me/phone-number` },
  submitTFANumber: { method: 'POST', url: `${config.api.path}/me/phone-numbers` },
  confirmTFANumber: { method: 'POST', url: `${config.api.path}/me/phone-numbers/confirm` },
})

const lists = resource(`${config.api.path}/lists/:id`, {
  getByBucket: { url: `${config.api.path}/buckets/:id/lists` },
  createInBucket: { method: 'POST', url: `${config.api.path}/buckets/:id/lists` }
})

const enterprises = resource(`${config.api.path}/enterprises/:enterpriseId`, {
  getAllMembers: { url: `${config.api.path}/enterprises/:enterpriseId/team/members` }
})

const enterpriseAdmins = resource(`${config.api.path}/enterprises/:enterpriseId/admins/:id`)

const entries = resource(`${config.api.path}/entries/:id`, {
  getByBucket: { url: `${config.api.path}/buckets/:id/entries` },
  getByList: { url: `${config.api.path}/buckets/:bucketId/lists/:listId/entries` },
  getEntry: { url: `${config.api.path}/buckets/:bucketId/entries/:id` },
  create: { method: 'POST', url: `${config.api.path}/buckets/:bucketId/entries` },
  update: { method: 'PUT', url: `${config.api.path}/buckets/:bucketId/entries/:id` },
  delete: { method: 'DELETE', url: `${config.api.path}/buckets/:bucketId/entries/:id` },
  bulkDelete: { method: 'POST', url: `${config.api.path}/buckets/:bucketId/delete-entries` },
  bulkMoveToList: { method: 'PUT', url: `${config.api.path}/buckets/:bucketId/lists/:listId/entries` },
  bulkMoveToBucket: { method: 'PUT', url: `${config.api.path}/buckets/:bucketId/entries` }
})

const invoices = resource(`${config.api.path}/invoices/:id`)

const passwords = resource(`${config.api.path}/passwords`, {
  generate: { url: `${config.api.path}/passwords/generate` }
})

const permissions = resource(`${config.api.path}/enterprises/:enterpriseId/permissions`, {
  getContextPermissions: { url: `${config.api.path}/enterprises/:enterpriseId/context-permissions/:context` },
  getUserPermissions: { url: `${config.api.path}/enterprises/:enterpriseId/me/permissions/:model/:objectId`}
})

const search = resource(`${config.api.path}/search`)

const subscribers = resource(`${config.api.path}/subscribers/:uid`, {
  findByCustomer: { url: `${config.api.path}/subscribers/:type/:uid` },
  getInvoices: { url: `${config.api.path}/subscribers/:uid/invoices` }
})

const subscriptions = resource(`${config.api.path}/subscriptions/:uid`, {
  findActiveSubscription: { url: `${config.api.path}/subscribers/:subscriberId/active-subscription` },
  findActiveDetailedSubscription: { url: `${config.api.path}/subscribers/:subscriberId/active-subscription/details` },
  initiateSubscription: { url: `${config.api.path}/initiate-subscription`, method: 'POST' },
  changePlan: { method: 'PUT', url: `${config.api.path}/subscriptions/:uid/plan` }
})

const usage = resource(`${config.api.path}/usage`, {
  getUsage: { url: `${config.api.path}/usage/count` },
  getEnterpriseUsage: { url: `${config.api.path}/enterprises/:enterpriseId/usage` }
})

const userAccess = resource(`${config.api.path}/accesses/:id`, {
  getByBucket: { url: `${config.api.path}/buckets/:bucketId/accesses` },
  create: { method: 'POST', url: `${config.api.path}/buckets/:bucketId/accesses` },
  update: { method: 'PUT', url: `${config.api.path}/buckets/:bucketId/accesses/:id` },
  delete: { method: 'DELETE', url: `${config.api.path}/buckets/:bucketId/accesses/:id` }
})

const users = resource(`${config.api.path}/enterprises/:enterpriseId/users/:id`, {
  resetPassword: { method: 'POST', url: `${config.api.path}/enterprises/:enterpriseId/users/:id/password` },
  search: { url: `${config.api.path}/enterprises/:enterpriseId/users/search` }
})

const usergroups = resource(`${config.api.path}/enterprises/:enterpriseId/usergroups/:id`, {
  getMembers: { url: `${config.api.path}/enterprises/:enterpriseId/usergroups/:groupId/users` },
  addMember: { method: 'POST', url: `${config.api.path}/enterprises/:enterpriseId/usergroups/:groupId/users` },
  removeMember: { method: 'DELETE', url: `${config.api.path}/enterprises/:enterpriseId/usergroups/:groupId/users/:userId` }
})

export default {
  accessLists,
  accessPolicies,
  archives,
  bookmarks,
  buckets,
  creditCards,
  currentUser,
  lists,
  enterprises,
  enterpriseAdmins,
  entries,
  invoices,
  passwords,
  permissions,
  search,
  subscribers,
  subscriptions,
  usage,
  userAccess,
  users,
  usergroups,
  getAuthToken ({ email, password }) {
    return http.post(`${config.api.path}/authenticate`, {
      email,
      password
    })
  },
  postTFACode (payload) {
    return http.post(`${config.api.path}/authenticate/two-factor`, payload)
  },
  forgotPassword (payload) {
    return http.post(`${config.api.path}/password/reset-link`, payload)
  },
  resetPassword (payload) {
    return http.post(`${config.api.path}/password/reset`, payload)
  },
  refreshToken (payload) {
    return http.post(`${config.api.path}/token/refresh`, payload)
  },
  signup (payload) {
    return http.post(`${config.api.path}/signup`, payload)
  }
}
