<template>
  <div class="view-reset-password auth-layout">
    <div class="logo-wrapper">
      <img src="../../assets/images/logo-passbucket-320.png" alt="Passbucket | Cloud password manager" width="250" height="157">
    </div>

    <div class="login-box">
      <div class="block">
        <div class="block-body">
          <form action="" @submit.prevent="submit()">
            <div class="alert alert-danger" role="alert" v-if="hasError === 'internal'">
              <strong>Sorry,</strong>
              <p class="mb-0">we can not process your request. Make sure you've filled the form correctly and try again.</p>
            </div>

            <div class="alert alert-danger" role="alert" v-if="errorType === 'invalid_user'">
              <strong>Oops!</strong>
              <p class="mb-0">This email address does not match.</p>
            </div>

            <div class="alert alert-danger" role="alert" v-if="errorType === 'invalid_password'">
              <strong>Invalid password</strong>
              <p class="mb-0">Make sure its typed correctly and different than your previous one.</p>
            </div>

            <div class="alert alert-danger" role="alert" v-if="errorType === 'invalid_token'">
              <strong>Invalid link</strong>
              <p class="mb-0">This reset link is not valid or has expired.</p>
            </div>

            <div class="alert alert-success" role="alert" v-if="isSuccess">
              <strong>Thank you.</strong>
              <p class="mb-0">Your new password has been set. You can now login.</p>
            </div>

            <div v-if="!isSuccess">
              <p class="mb-4">You can now set a new password. Make it strong enough to protect your account. We recommend a minimum length of 12 or 16 characters.</p>

              <div class="form-group">
                <input type="email" class="form-control" placeholder="Your email address" autocomplete="off" v-model="form.email" :class="{ 'is-invalid': errors.email }">
                <div class="invalid-feedback" v-if="errors.email">{{ errors.email[0] }}</div>
              </div>

              <div class="form-group">
                <input type="password" class="form-control" placeholder="Choose a strong password" autocomplete="off" v-model="form.password" :class="{ 'is-invalid': errors.password }">
                <div class="invalid-feedback" v-if="errors.password">{{ errors.password[0] }}</div>
              </div>

              <div class="form-group">
                <input type="password" class="form-control" placeholder="Type your new password again" autocomplete="off" v-model="form.password_confirmation" :class="{ 'is-invalid': errors.password_confirmation }">
                <div class="invalid-feedback" v-if="errors.password_confirmation">{{ errors.password_confirmation[0] }}</div>
              </div>

              <div class="text-center mt-4">
                <button type="submit" class="btn btn-lg btn-primary submit-btn"
                        :class="{ 'loading-btn': isProcessing }"
                        :disabled="formDisabled">
                  Set password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="mt-3 text-center">
        <small><router-link :to="{ name: 'auth.login' }">Back to login</router-link></small>
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'reset-password-page',
  data () {
    return {
      isProcessing: false,
      form: {
        email: '',
        password: '',
        password_confirmation: ''
      },
      isSuccess: false,
      hasError: false,
      errorType: null,
      errors: {}
    }
  },
  computed: {
    formDisabled () {
      return this.form.email.length === 0 ||
          this.form.password.length === 0 ||
          this.form.password_confirmation.length === 0
    }
  },
  methods: {
    submit () {
      this.isProcessing = true
      this.hasError = false
      this.errors = {}
      this.errorType = null

      const data = _.assign({}, this.form, {
        token: this.$route.params.token
      })

      this.$store.dispatch('resetPassword', data)
        .then(() => {
          this.isProcessing = false
          this.isSuccess = true
        })
        .catch((error) => {
          this.isProcessing = false
          this.hasError = true
          this.errors = error.validationErrors
          this.errorType = error.type
        })
    }
  }
}
</script>
