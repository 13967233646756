<template>
  <div class="subscription-form">
    <div class="container">
      <div class="dashhead nosub">
        <div class="dashhead-titles">
          <h3 class="dashhead-title">Billing information</h3>
        </div>
      </div>
    </div>

    <div class="container mt-3 mb-3">
      <div class="card">
        <div class="card-body">
          <form action="">
            <div class="alert alert-danger" v-if="errorType === 'internal'">
              <strong>Server error</strong>
              <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
            </div>

            <div class="form-group row">
              <label for="inputFirstname" class="col-sm-3 col-form-label">Firstname</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputFirstname" v-model="form.firstname" :class="{ 'is-invalid': errors.firstname }">
                <div class="invalid-feedback" v-if="errors.firstname">{{ errors.firstname[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputLastname" class="col-sm-3 col-form-label">Lastname</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputLastname" v-model="form.lastname" :class="{ 'is-invalid': errors.lastname }">
                <div class="invalid-feedback" v-if="errors.lastname">{{ errors.lastname[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputOrg" class="col-sm-3 col-form-label">Organization</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputOrg" v-model="form.organization" :class="{ 'is-invalid': errors.organization }">
                <div class="invalid-feedback" v-if="errors.organization">{{ errors.organization[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputAddr" class="col-sm-3 col-form-label">Address</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputAddr" v-model="form.address" :class="{ 'is-invalid': errors.address }">
                <div class="invalid-feedback" v-if="errors.address">{{ errors.address[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputAddr2" class="col-sm-3 col-form-label">Address (line 2)</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputAddr2" v-model="form.address2" :class="{ 'is-invalid': errors.address2 }">
                <div class="invalid-feedback" v-if="errors.address2">{{ errors.address2[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputCity" class="col-sm-3 col-form-label">City</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputCity" v-model="form.city" :class="{ 'is-invalid': errors.city }">
                <div class="invalid-feedback" v-if="errors.city">{{ errors.city[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label for="inputPostal" class="col-sm-3 col-form-label">Postal code</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputPostal" v-model="form.postalcode" :class="{ 'is-invalid': errors.postalcode }">
                <div class="invalid-feedback" v-if="errors.postalcode">{{ errors.postalcode[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Country</label>
              <div class="col-sm-9 col-lg-4">
                <country-selector v-model="form.iso_country" :is-invalid="errors.iso_country"></country-selector>
                <div class="invalid-feedback" v-if="errors.iso_country">{{ errors.iso_country[0] }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">State / province</label>
              <div class="col-sm-9 col-lg-4">
                <state-selector v-model="form.iso_state" :is-invalid="errors.iso_state" :country="form.iso_country"></state-selector>
                <div class="invalid-feedback" v-if="errors.iso_state">{{ errors.iso_state[0] }}</div>
              </div>
            </div>

            <h5 class="mt-4 mb-4">Credit card information</h5>

            <div class="form-group row">
              <label for="inputCardNumber" class="col-sm-3 col-form-label">Credit card number</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputCardNumber" v-model="card.number">
              </div>
            </div>

            <div class="form-group row">
              <label for="inputCardHolder" class="col-sm-3 col-form-label">Card holder name</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputCardHolder" v-model="card.name">
              </div>
            </div>

            <div class="form-group row">
              <label for="inputCardCVV" class="col-sm-3 col-form-label">Security code</label>
              <div class="col-sm-9 col-lg-4">
                <input type="text" class="form-control" id="inputCardCVV" v-model="card.cvc">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Expiry</label>
              <div class="col-sm-9 col-lg-4">
                <select class="form-control" style="width: 50px; display: inline-block;" v-model="card.exp_month">
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>

                <select class="form-control" style="width: 150px; display: inline-block;"  v-model="card.exp_year">
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                </select>
              </div>
            </div>

            <div class="form-check mt-4">
              <input class="form-check-input" type="checkbox" id="checkTerms" v-model="termsAccepted">
              <label class="form-check-label" for="checkTerms">
                I confirm that I have read and understood the entire <router-link :to="{ name: 'legal.terms' }" target="_blank">Terms of Use</router-link> and <router-link :to="{ name: 'legal.privacy' }" target="_blank">Privacy Policy</router-link> and agree to all statements.
              </label>
            </div>

            <div class="mt-4">
              <button class="btn btn-primary btn-lg" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing || !termsAccepted" @click.prevent="submit()">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import asyncProcess from '../../mixins/asyncProcess'
import CountrySelector from '../../components/common/CountrySelector'
import StateSelector from '../../components/common/StateSelector'

export default {
  props: {
    plan: {
      type: String,
      required: true
    }
  },
  mixins: [asyncProcess],
  components: {
    CountrySelector,
    StateSelector
  },
  data () {
    return {
      termsAccepted: false,
      form: {
        firstname: '',
        lastname: '',
        organization: '',
        address: '',
        address2: '',
        city: '',
        postalcode: '',
        iso_country: 'CA',
        iso_state: 'QC'
      },
      card: {
        number: '',
        name: '',
        cvc: '',
        exp_month: '',
        exp_year: ''
      }
    }
  },
  methods: {
    async submit () {
      this.startProcess()

      try {
        const token = await this.getCardToken()

        const reqData = {
          ...this.form,
          card_token: token,
          plan: this.plan
        }

        await api.subscriptions.initiateSubscription(reqData)

        this.$store.commit('SUBSCRIPTION_PROCESSED')
      } catch (error) {
        if (error === 'card_error') {
          this.$noty.error('Can not process your credit card.')
        }

        this.handleError(error)
      }
    },
    getCardToken () {
      return new Promise((resolve, reject) => {
        /* eslint-disable no-undef */
        Stripe.card.createToken(this.card, (status, response) => {
          if (response.error) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('card_error')
          } else {
            resolve(response.id)
          }
        })
      })
    }
  }
}
</script>
