<template>
  <div class="billing-overview">
    <div class="card">
      <div class="card-body">
        <div class="float-right small">
          <router-link :to="{ name: 'change-plan' }">Change plan</router-link>
        </div>
        <h4>Subscription</h4>
        <div class="mt-3">
          <small-loader v-if="subscriptionLoading"></small-loader>
          <div v-else>
            <div class="row">
              <div class="col-6 col-md-3">Plan</div>
              <div class="col-6 col-md-9">
                <div v-if="subscription.plan === 'partner'">Partner - Unlimited Account</div>
                <div v-if="subscription.plan === 'free'">Free for private use</div>
                <div v-if="subscription.plan === 'personal'">Personal</div>
                <div v-if="subscription.plan === 'premium'">Premium</div>
                <div v-if="subscription.plan === 'small_business'">Small Business</div>
                <div v-if="subscription.plan === 'enterprise'">Enterprise</div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-3">Next invoice</div>
              <div class="col-6 col-md-9" v-if="subscription.plan === 'partner'">N/A</div>
              <div class="col-6 col-md-9" v-else-if="subscription.next_invoice">{{ nextInvoice }}</div>
              <div class="col-6 col-md-9" v-else>N/A</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-3">
      <div class="card-body">
        <div class="float-right small">
          <router-link :to="{ name: 'billing.details' }">Update</router-link>
        </div>
        <h4>Billing address</h4>
        <div class="mt-3 billing-address">
          <small-loader v-if="addressLoading"></small-loader>
          <div v-if="subscriber && !addressLoading">
            <div class="line">{{ subscriber.firstname }} {{ subscriber.lastname }}</div>
            <div class="line">{{ subscriber.address }}</div>
            <div class="line">{{ subscriber.address2 }}</div>
            <div class="line">{{ subscriber.city }} {{ subscriber.iso_state }} {{ subscriber.postalcode }}</div>
            <div class="line"><country-name :code="subscriber.iso_country"></country-name></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import SmallLoader from '../../../components/common/SmallLoader'
import CountryName from '../../../components/common/CountryName'
import api from '../../../api'

export default {
  components: {
    CountryName,
    SmallLoader
  },
  data () {
    return {
      subscriptionLoading: true,
      addressLoading: true,
      subscription: {}
    }
  },
  computed: {
    ...mapGetters({
      subscriber: 'getSubscriber',
      subscriberId: 'getSubscriberId'
    }),
    nextInvoice () {
      return this.subscription && this.subscription.next_invoice
        ? moment.unix(this.subscription.next_invoice).format('YYYY-MM-DD') : ''
    }
  },
  methods: {
    fetchAddress () {
      this.addressLoading = true

      return this.$store.dispatch('fetchSubscriber')
        .then(() => {
          this.addressLoading = false
        })
    },
    fetchSubscription () {
      this.subscriptionLoading = true

      return api.subscriptions.findActiveDetailedSubscription({ subscriberId: this.subscriberId })
        .then((subscription) => {
          this.subscription = subscription
          this.subscriptionLoading = false
        })
    }
  },
  created () {
    this.fetchAddress()
      .then(() => {
        this.fetchSubscription()
      })
  }
}
</script>
