import _ from 'lodash'
import api from '../api'
// import config from '../config'

export default {
  state: {
    fetching: false,
    list: [],
    selectedItems: [],
    archives: [],
    bookmarks: []
  },

  mutations: {
    BUCKETS_FETCHING: (state) => {
      state.fetching = true
    },

    BUCKETS_FETCHED: (state, list) => {
      state.list = list
      state.fetching = false
    },

    BUCKETS_CREATED: (state, obj) => {
      state.list.push(obj)
    },

    BUCKETS_UPDATED: (state, obj) => {
      const index = state.list.indexOf(_.find(state.list, { uid: obj.uid }))
      state.list.splice(index, 1, obj)
    },

    BUCKETS_DELETED: (state, uid) => {
      const index = state.list.indexOf(_.find(state.list, { uid }))
      state.list.splice(index, 1)
    },

    BUCKET_SELECT: (state, uid) => {
      state.selectedItems.push(uid)
    },

    BUCKET_UNSELECT: (state, uid) => {
      const index = state.selectedItems.indexOf(uid)

      if (index > -1) {
        state.selectedItems.splice(index, 1)
      }
    },

    BUCKET_UNSELECT_ALL: (state) => {
      state.selectedItems = []
    },

    BUCKET_BOOKMARKS_FETCHED: (state, collection) => {
      state.bookmarks = collection
    },

    BUCKET_BOOKMARK_CREATED: (state, object) => {
      state.bookmarks.push(object)
    },

    BUCKET_BOOKMARK_DELETED: (state, bookmarkId) => {
      const index = state.bookmarks.indexOf(_.find(state.bookmarks, { id: bookmarkId }))
      state.bookmarks.splice(index, 1)
    },

    BUCKET_ARCHIVES_FETCHED: (state, collection) => {
      state.archives = collection
    },

    BUCKETS_BULK_ARCHIVED: (state, newItems) => {
      newItems.forEach((item) => {
        state.archives.push(item)
      })
    },

    BUCKETS_BULK_UNARCHIVED: (state, deletedIds) => {
      state.archives = _.reject(state.archives, o => deletedIds.indexOf(o.id) > -1)
    },

    BUCKETS_BULK_STARRED: (state, newItems) => {
      newItems.forEach((item) => {
        state.bookmarks.push(item)
      })
    },

    BUCKETS_BULK_UNSTARRED: (state, deletedIds) => {
      state.bookmarks = _.reject(state.bookmarks, o => deletedIds.indexOf(o.id) > -1)
    }
  },

  actions: {
    fetchBuckets ({ commit }) {
      commit('BUCKETS_FETCHING')

      return api.buckets.query()
        .then((list) => {
          commit('BUCKETS_FETCHED', list)
        })
    },

    fetchBucketBookmarks ({ commit }) {
      return api.bookmarks.getListByUser({ type: 'bucket' })
        .then((bookmarks) => {
          commit('BUCKET_BOOKMARKS_FETCHED', bookmarks)
        })
    },

    fetchBucketArchives ({ commit }) {
      return api.archives.getListByUser({ type: 'bucket' })
        .then((archives) => {
          commit('BUCKET_ARCHIVES_FETCHED', archives)
        })
    },

    toggleSelectBucket ({ state, commit }, uid) {
      if (state.selectedItems.indexOf(uid) > -1) {
        commit('BUCKET_UNSELECT', uid)
      } else {
        commit('BUCKET_SELECT', uid)
      }
    },

    starBucket ({ commit }, bucketUid) {
      return api.bookmarks.create({
        type: 'bucket',
        uid: bucketUid
      })
        .then((bookmark) => {
          commit('BUCKET_BOOKMARK_CREATED', bookmark)
        })
    },

    unstarBucket ({ commit }, bookmarkId) {
      return api.bookmarks.delete({ id: bookmarkId })
        .then(() => {
          commit('BUCKET_BOOKMARK_DELETED', bookmarkId)
        })
    },

    archiveSelectedBuckets ({ commit, state }) {
      return api.archives.bulkCreate({ type: 'bucket', uids: state.selectedItems })
        .then((newItems) => {
          commit('BUCKETS_BULK_ARCHIVED', newItems)
        })
    },

    unarchiveSelectedBuckets ({ commit, state }) {
      return api.archives.bulkDelete({ type: 'bucket', uids: state.selectedItems })
        .then((deletedArchives) => {
          commit('BUCKETS_BULK_UNARCHIVED', deletedArchives)
        })
    },

    starSelectedBuckets ({ commit, state }) {
      return api.bookmarks.bulkCreate({ type: 'bucket', uids: state.selectedItems })
        .then((newItems) => {
          commit('BUCKETS_BULK_STARRED', newItems)
        })
    },

    unstarSelectedBuckets ({ commit, state }) {
      return api.bookmarks.bulkDelete({ type: 'bucket', uids: state.selectedItems })
        .then((deletedBookmarks) => {
          commit('BUCKETS_BULK_UNSTARRED', deletedBookmarks)
        })
    }
  }
}
