<template>
  <div class="global-search">
    <div class="input-with-icon right-icon">
      <span class="icon icon-magnifying-glass"></span>
      <input type="text" class="form-control nav-search" placeholder="Search..." @input="handleSearch" @keyup="resetResults">
    </div>
    <div class="search-results" v-if="displayResults" v-on-clickaway="resetResults">
      <div class="searching" v-if="searching">
        Searching...
      </div>
      <div class="no-search-result" v-if="!searching && !results.length">
        No result found.
      </div>
      <template v-if="results && !searching">
        <result-item
          v-for="result in results"
          :result="result"
          :keyword="searchTerm"
          :key="result.uid"
          @clicked="resetResults">
        </result-item>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mixin as clickaway } from 'vue-clickaway'
import api from '../../api'
import ResultItem from './global/ResultItem'

export default {
  components: {
    ResultItem
  },
  mixins: [clickaway],
  data () {
    return {
      searchTerm: '',
      displayResults: false,
      results: [],
      searching: false
    }
  },
  methods: {
    /* eslint-disable func-names */
    handleSearch: _.debounce(function (e) {
      const searchTerm = e.target.value

      if (searchTerm.length < 2) {
        return
      }

      this.searchTerm = searchTerm
      this.searching = true
      this.displayResults = true

      api.search.query({ q: searchTerm })
        .then((results) => {
          this.searching = false
          this.results = results
        })
    }, 500),
    resetResults () {
      this.displayResults = false
      this.results = []
      this.searching = false
      this.searchTerm = ''
    }
  }
}
</script>
