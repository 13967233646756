<template>
  <div class="view-login view-tfa auth-layout">
    <div class="logo-wrapper">
      <img src="../../assets/images/logo-passbucket-320.png" alt="Passbucket | Cloud password manager" width="250" height="157">
    </div>

    <div class="login-box">
      <div class="block">
        <div class="block-body">
          <form action="" @submit.prevent="submit()">
            <div class="alert alert-danger" role="alert" v-if="hasError">
              <strong>Access denied!</strong>
              <p class="mb-0">Please check your auth code and try again.</p>
            </div>

            <h4 class="mb-3">Two Factor Authentication</h4>

            <p>In order to protect your account, a text message has been sent to your phone number.</p>
            <p>Please enter the confirmation code to authenticate.</p>

            <div class="form-group">
              <input type="number" class="form-control" placeholder="Confirmation code" autocomplete="off" v-model="form.confirm_code">
            </div>

            <div class="text-center">
              <button type="submit" class="btn btn-lg btn-primary submit-btn"
                      :class="{ 'loading-btn': isProcessing }"
                      :disabled="formDisabled">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="mt-3 text-center">
        <small><router-link :to="{ name: 'auth.login' }">&laquo; Go back</router-link></small>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'two-factor-page',
  data () {
    return {
      isProcessing: false,
      form: {
        confirm_code: ''
      },
      hasError: false,
      error: {}
    }
  },
  computed: {
    formDisabled () {
      return this.form.confirm_code.length === 0
    },
    tfaUserToken () {
      return this.$store.state.auth.tfaUserToken
    }
  },
  methods: {
    submit () {
      this.isProcessing = true
      this.hasError = false

      const postData = Object.assign({}, this.form, {
        two_factor_token: this.tfaUserToken
      })

      const redirectPath = this.$route.query && this.$route.query.redirect ? this.$route.query.redirect : '/'

      this.$store.dispatch('verifyTFACode', postData)
        .then(() => {
          this.isProcessing = false
          this.$router.push(redirectPath)
        })
        .catch((error) => {
          this.isProcessing = false
          this.hasError = true
          this.error = error

          if (error.type === 'unauthorized') {
            this.form.confirm_code = ''
          }
        })
    }
  },
  created () {
    if (!this.tfaUserToken) {
      this.$router.replace({ name: 'auth.login' })
    }
  }
}
</script>
