<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <edit-dialog v-if="mode === 'edit'"
                 :list="list"
                 @delete="handleDelete"
                 @updated="done">
    </edit-dialog>

    <delete-dialog v-if="mode === 'delete'"
                 :id="list.uid"
                 @cancel="cancelDelete"
                 @deleted="done">
    </delete-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import asyncProcess from '../../../mixins/asyncProcess'
import EditDialog from './dialogs/Edit'
import DeleteDialog from './dialogs/Delete'

export default {
  mixins: [asyncProcess],
  components: {
    EditDialog,
    DeleteDialog
  },
  data () {
    return {
      mode: 'edit',
      list: {}
    }
  },
  methods: {
    handleDelete () {
      this.mode = 'delete'
    },
    cancelDelete () {
      this.mode = 'edit'
    }
  },
  created () {
    this.list = _.clone(this.data.list)
  }
}
</script>
