<template>
  <div class="user-settings-password">
    <div class="card">
      <div class="card-body">
        <form action="" @submit.prevent="submit()">
          <div class="alert alert-danger" v-if="errorType === 'internal'">
            <strong>Server error</strong>
            <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
          </div>

          <div class="form-group row">
            <label for="inputCurrentPwd" class="col-sm-3 col-form-label">Current password</label>
            <div class="col-sm-9 col-lg-4">
              <input type="password" autocomplete="off" class="form-control" id="inputCurrentPwd" v-model="form.password" :class="{ 'is-invalid': errors.password }">
              <div class="invalid-feedback" v-if="errors.password">{{ errors.password[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputNewPwd" class="col-sm-3 col-form-label">New password</label>
            <div class="col-sm-9 col-lg-4">
              <input type="password" autocomplete="off" class="form-control" id="inputNewPwd" v-model="form.new_password" :class="{ 'is-invalid': errors.new_password }">
              <div class="invalid-feedback" v-if="errors.new_password">{{ errors.new_password[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputNewPwdConfim" class="col-sm-3 col-form-label">Retype new password</label>
            <div class="col-sm-9 col-lg-4">
              <input type="password" autocomplete="off" class="form-control" id="inputNewPwdConfim" v-model="form.confirm_password" :class="{ 'is-invalid': errors.confirm_password }">
              <div class="invalid-feedback" v-if="errors.confirm_password">{{ errors.confirm_password[0] }}</div>
            </div>
          </div>

          <div class="form-group row mt-3">
            <div class="col-sm-9 col-lg-4 offset-sm-3">
              <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
                Change password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import asyncProcess from '../../../mixins/asyncProcess'
import api from '../../../api'

export default {
  mixins: [asyncProcess],
  data () {
    return {
      form: {
        password: '',
        new_password: '',
        confirm_password: ''
      }
    }
  },
  methods: {
    submit () {
      this.startProcess()

      api.currentUser.changePassword(this.form)
        .then(() => {
          this.endProcess()
          this.$noty.success('Password updated.')

          this.form = {
            password: '',
            new_password: '',
            confirm_password: ''
          }
        })
        .catch(this.handleError)
    }
  }
}
</script>
