import axios from 'axios'
import _ from 'lodash'
import config from '../config'
import store from '../store'

const API_URL = _.trimEnd(config.api.host, '/')

const http = axios.create({
  baseURL: API_URL
})

/* eslint-disable dot-notation */
http.defaults.headers.common['Accept'] = 'application/json'
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Inject JWT Token
 */

http.interceptors.request.use((request) => {
  const token = localStorage.getItem(config.auth.tokenKeyName)

  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`
  }

  return request
}, error => Promise.reject(error))

/**
 * Handle API status codes.
 *
 * Return an objet containing following properties:
 * - type : internal | validation | forbidden | unauthorized
 * - message : string to be displayed to user
 * - validationErrors : [] (if applicable)
 * - response : {} - original axios response object
 */

http.interceptors.response.use(response => Promise.resolve(response.data), (error) => {
  let type = 'internal'
  let validationErrors = {}
  const message = (error.response && error.response.data.user_message) ? error.response.data.user_message : ''

  if (error.response && error.response.status && error.response.status === 422) {
    type = 'validation'
    validationErrors = error.response.data
  }

  if (error.response && error.response.status && error.response.status === 400) {
    type = error.response.data.status ? error.response.data.status : 'bad_request'
  }

  if (error.response && error.response.status && error.response.status === 402) {
    type = 'payment'
  }

  if (error.response && error.response.status && error.response.status === 403) {
    type = 'forbidden'
  }

  if (error.response && error.response.status && error.response.status === 404) {
    type = 'not_found'
  }

  if (error.response && error.response.status && error.response.status === 401) {
    type = 'unauthorized'

    if (error.config.url.indexOf('/api/authenticate') === -1) {
      store.dispatch('handleUnauthorizedError')
    }
  }

  /* eslint-disable prefer-promise-reject-errors */
  return Promise.reject({
    type,
    message,
    validationErrors,
    response: error.response
  })
})

export default http
