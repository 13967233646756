<template>
  <div class="billing-details">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <form action="" @submit.prevent="submit()">
          <div class="alert alert-danger" v-if="errorType === 'internal'">
            <strong>Server error</strong>
            <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
          </div>

          <div class="form-group row">
            <label for="inputFirstname" class="col-sm-3 col-form-label">Firstname</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputFirstname" v-model="form.firstname" :class="{ 'is-invalid': errors.firstname }">
              <div class="invalid-feedback" v-if="errors.firstname">{{ errors.firstname[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputLastname" class="col-sm-3 col-form-label">Lastname</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputLastname" v-model="form.lastname" :class="{ 'is-invalid': errors.lastname }">
              <div class="invalid-feedback" v-if="errors.lastname">{{ errors.lastname[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputOrg" class="col-sm-3 col-form-label">Organization</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputOrg" v-model="form.organization" :class="{ 'is-invalid': errors.organization }">
              <div class="invalid-feedback" v-if="errors.organization">{{ errors.organization[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputAddress" class="col-sm-3 col-form-label">Address</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputAddress" v-model="form.address" :class="{ 'is-invalid': errors.address }">
              <div class="invalid-feedback" v-if="errors.address">{{ errors.address[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputAddress2" class="col-sm-3 col-form-label">Address (line 2)</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputAddress2" v-model="form.address2" :class="{ 'is-invalid': errors.address2 }">
              <div class="invalid-feedback" v-if="errors.address2">{{ errors.address2[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputCity" class="col-sm-3 col-form-label">City</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputCity" v-model="form.city" :class="{ 'is-invalid': errors.city }">
              <div class="invalid-feedback" v-if="errors.city">{{ errors.city[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputPostalCode" class="col-sm-3 col-form-label">Postal code</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputPostalCode" v-model="form.postalcode" :class="{ 'is-invalid': errors.postalcode }">
              <div class="invalid-feedback" v-if="errors.postalcode">{{ errors.postalcode[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Country</label>
            <div class="col-sm-9 col-lg-4">
              <country-selector v-model="form.iso_country" :is-invalid="errors.iso_country"></country-selector>
              <div class="invalid-feedback" v-if="errors.iso_country">{{ errors.iso_country[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">State / province</label>
            <div class="col-sm-9 col-lg-4">
              <state-selector v-model="form.iso_state" :is-invalid="errors.iso_state" :country="form.iso_country"></state-selector>
              <div class="invalid-feedback" v-if="errors.iso_state">{{ errors.iso_state[0] }}</div>
            </div>
          </div>

          <div class="form-group row mt-3">
            <div class="col-sm-9 col-lg-4 offset-sm-3">
              <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import api from '../../../api'
import asyncProcess from '../../../mixins/asyncProcess'
import SmallLoader from '../../../components/common/SmallLoader'
import CountrySelector from '../../../components/common/CountrySelector'
import StateSelector from '../../../components/common/StateSelector'

export default {
  components: {
    SmallLoader,
    CountrySelector,
    StateSelector
  },
  mixins: [asyncProcess],
  data () {
    return {
      isLoading: true,
      form: {
        uid: '',
        firstname: '',
        lastname: '',
        organization: '',
        address: '',
        address2: '',
        city: '',
        postalcode: '',
        iso_state: '',
        iso_country: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      subscriber: 'getSubscriber',
      subscriberId: 'getSubscriberId'
    })
  },
  methods: {
    submit () {
      this.startProcess()

      api.subscribers.update(this.form)
        .then(() => {
          this.endProcess()
          this.$noty.success('Account updated.')
        })
        .catch(this.handleError)
    },
    mapForm (object) {
      this.form = _.assign({}, _.pick(object, [
        'uid',
        'firstname',
        'lastname',
        'organization',
        'address',
        'address2',
        'city',
        'postalcode',
        'iso_state',
        'iso_country'
      ]))
    }
  },
  created () {
    this.$store.dispatch('fetchSubscriber')
      .then(() => {
        this.mapForm(this.subscriber)
        this.isLoading = false
      })
      .catch(() => {
        this.$noty.warning('Server error. Please reload the page and try again.')
      })
  }
}
</script>
