<template>
  <div class="billing-invoice-details">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <h3 class="invoice-number">Invoice # {{ invoice.reference }}</h3>

        <div class="row invoice-summary">
          <div class="col-md-8 mb-md-0 mb-3">
            <div class="billed-to summary-label">Billed to</div>
            <div class="address">
              <div class="line">{{ subscriber.firstname }} {{ subscriber.lastname }}</div>
              <div class="line">{{ subscriber.address }}</div>
              <div class="line" v-if="subscriber.address2">{{ subscriber.address2 }}</div>
              <div class="line">{{ subscriber.city }} {{ subscriber.iso_state }} {{ subscriber.postalcode }}</div>
              <div class="line"><country-name :code="subscriber.iso_country"></country-name></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row mb-md-0 mb-3">
              <div class="col-md-6 summary-label">Invoice date</div>
              <div class="col-md-6 text-md-right">{{ date }}</div>
            </div>
            <div class="row mb-md-0 mb-3">
              <div class="col-md-6 summary-label">Status</div>
              <div class="col-md-6 text-md-right">{{ status }}</div>
            </div>
          </div>
        </div>

        <div class="invoice-content">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">DESCRIPTION</th>
                <th scope="col" class="text-right">QTY</th>
                <th scope="col" class="text-right">AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in invoice.lines" :key="index">
                <td>{{ line.description }}</td>
                <td class="text-right">{{ line.quantity }}</td>
                <td class="text-right">{{ getAmount(line.amount) }}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td colspan="2" class="text-right">Subtotal</td>
                <td class="text-right">{{ subtotal }}</td>
              </tr>
              <tr>
                <td colspan="2" class="text-right">Total</td>
                <td class="text-right">{{ total }}</td>
              </tr>
              <tr>
                <td colspan="2" class="text-right">Amount paid</td>
                <td class="text-right">-{{ amountPaid }}</td>
              </tr>
              <tr class="amount-due">
                <td colspan="2" class="text-right">Amount due</td>
                <td class="text-right">{{ amountRemaining }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeraljs'
import api from '../../../api'
import CountryName from '../../../components/common/CountryName'
import SmallLoader from '../../../components/common/SmallLoader'

export default {
  components: {
    CountryName,
    SmallLoader
  },
  data () {
    return {
      isLoading: true,
      invoice: {}
    }
  },
  computed: {
    ...mapGetters({
      subscriber: 'getSubscriber',
      subscriberId: 'getSubscriberId'
    }),
    date () {
      return this.invoice.date ? moment.unix(this.invoice.date).format('YYYY-MM-DD') : ''
    },
    status () {
      return this.invoice.paid ? 'Paid' : 'Due'
    },
    subtotal () {
      return this.invoice.subtotal !== undefined
        ? numeral(this.invoice.subtotal).format('$0,0.00') : ''
    },
    total () {
      return this.invoice.total !== undefined
        ? numeral(this.invoice.total).format('$0,0.00') : ''
    },
    amountPaid () {
      return numeral(this.invoice.amount_paid).format('$0,0.00')
    },
    amountRemaining () {
      return numeral(this.invoice.amount_remaining).format('$0,0.00')
    }
  },
  methods: {
    async fetchData () {
      if (!this.subscriberId) {
        await this.$store.dispatch('fetchSubscriber')
      }

      this.invoice = await api.invoices.get({ id: this.$route.params.id })
      this.isLoading = false
    },
    getAmount (amount) {
      return numeral(amount).format('$0,0.00')
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
