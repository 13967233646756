<template>
  <tr class="d-flex" :class="{ 'row-selected': selected }">
    <td class="col-checkbox">
      <input type="checkbox" :checked="selected" @change="$emit('toggle-select', bucket.uid)" v-if="!bucket.is_system">
      <span class="icon icon-lock" v-if="bucket.is_system"></span>
    </td>
    <td class="col-star">
      <span class="star-icon" :class="{ unstarred: !isStarred, starred: isStarred }" @click="toggleStar">
        <fa-icon :icon="['far', 'star']" v-if="!isStarred"></fa-icon>
        <fa-icon :icon="['fas', 'star']" v-if="isStarred"></fa-icon>
      </span>
    </td>
    <td class="col text-truncate">
      <router-link :to="{ name: 'bucket.view', params: { id: bucket.uid } }">{{ bucket.name }}</router-link>
    </td>
    <td class="col-shared text-right">
      <div class="label label-primary" v-if="bucket.is_shared">
        Shared
      </div>
    </td>
  </tr>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    bucket: {
      type: Object,
      required: true
    },
    bookmarks: {
      type: Array,
      default () {
        return []
      }
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      processingBookmark: false
    }
  },
  computed: {
    bookmarkId () {
      const hit = _.find(this.bookmarks, o => o.bookmarkable.uid === this.bucket.uid)
      return hit && hit.id ? hit.id : null
    },
    isStarred () {
      return this.bookmarkId !== null
    }
  },
  methods: {
    toggleStar () {
      if (this.processingBookmark) {
        return
      }

      if (this.isStarred) {
        this.removeBookmark()
      } else {
        this.addBookmark()
      }
    },
    addBookmark () {
      this.processingBookmark = true
      this.$store.dispatch('starBucket', this.bucket.uid)
        .then(() => {
          this.processingBookmark = false
        })
    },
    removeBookmark () {
      this.processingBookmark = true
      this.$store.dispatch('unstarBucket', this.bookmarkId)
        .then(() => {
          this.processingBookmark = false
        })
    }
  }
}
</script>
