<template>
  <div class="comp-password-view">

    <div class="form-group row">
      <label for="inputAddress" class="col-sm-2 col-form-label">Address</label>
      <div class="col-sm-10">
        <div class="input-group">
          <input type="text" class="form-control" id="inputAddress" readonly v-model="entry.url">
          <div class="input-group-append">
            <a class="btn btn-outline-secondary" :href="entry.url" target="_blank">
              <fa-icon :icon="['fas', 'external-link-alt']"></fa-icon>
            </a>
            <button class="btn btn-outline-secondary" type="button"
                    v-clipboard:copy="entry.url"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
              <fa-icon :icon="['fas', 'clipboard']"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputUsername" class="col-sm-2 col-form-label">Username</label>
      <div class="col-sm-10">
        <div class="input-group">
          <input type="text" class="form-control" id="inputUsername" readonly v-model="entry.username">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button"
                    v-clipboard:copy="entry.username"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
              <fa-icon :icon="['fas', 'clipboard']"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
      <div class="col-sm-10">
        <div class="input-group">
          <input :type="pwdInputType" class="form-control" id="inputPassword" readonly v-model="entry.password">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="togglePwdVisibility()">
              <fa-icon :icon="['fas', 'eye']" v-if="!isPwdClear"></fa-icon>
              <fa-icon :icon="['fas', 'eye-slash']" v-else></fa-icon>
            </button>
            <button class="btn btn-outline-secondary" type="button"
                    v-clipboard:copy="entry.password"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
              <fa-icon :icon="['fas', 'clipboard']"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputNotes" class="col-sm-2 col-form-label">Notes</label>
      <div class="col-sm-10">
        <textarea class="form-control" id="inputNotes" rows="5" readonly v-model="entry.text"></textarea>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isPwdClear: false
    }
  },
  computed: {
    pwdInputType () {
      return this.isPwdClear ? 'text' : 'password'
    }
  },
  methods: {
    togglePwdVisibility () {
      this.isPwdClear = !this.isPwdClear
    },
    onCopy (e) {
      const element = window.$(e.trigger)

      element.tooltip({
        title: 'Copied!',
        placement: 'bottom',
        trigger: 'manual'
      })

      element.tooltip('show')

      element.on('mouseleave', () => {
        element.tooltip('dispose')
      })

      return true
    },
    onError () {
      this.$noty.warning('Cannot copy to clipboard')
    }
  }
}
</script>
