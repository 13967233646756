<template>
  <sub-menu-layout
    :nav-items="navItems"
    :select-label="activeMenu"
    header-title=""
    :parent-label="parentLabel"
    :parent-link="parentLink">
    <router-view></router-view>
  </sub-menu-layout>
</template>

<script>
import SubMenuLayout from '../../components/layouts/SubMenuLayout'

const childRoutes = {
  'billing.invoices.details': {
    parentLabel: 'Back to invoices',
    parentLink: { name: 'billing.invoices' }
  }
}

export default {
  components: {
    SubMenuLayout
  },
  data () {
    return {
      bucket: {},
      navItems: [
        {
          name: 'Overview',
          link: { name: 'billing.overview' }
        },
        {
          name: 'Billing address',
          link: { name: 'billing.details' }
        },
        {
          name: 'Invoices',
          link: { name: 'billing.invoices' }
        },
        {
          name: 'Credit cards',
          link: { name: 'billing.cards' }
        }
      ]
    }
  },
  computed: {
    activeMenu () {
      return this.$route.meta && this.$route.meta.selectorTitle
        ? this.$route.meta.selectorTitle : 'Billing overview'
    },
    parentLabel () {
      return typeof childRoutes[this.$route.name] !== 'undefined'
        ? childRoutes[this.$route.name].parentLabel : ''
    },
    parentLink () {
      return typeof childRoutes[this.$route.name] !== 'undefined'
        ? childRoutes[this.$route.name].parentLink : ''
    }
  }
}
</script>
