export default {
  data () {
    return {
      isProcessing: false,
      errorType: null,
      errors: {}
    }
  },
  methods: {
    startProcess () {
      this.isProcessing = true
      this.errorType = null
      this.errors = {}
    },
    endProcess () {
      this.isProcessing = false
    },
    handleError (error) {
      this.errorType = error.type ? error.type : 'internal'
      this.errors = error.validationErrors ? error.validationErrors : {}
      this.isProcessing = false
    }
  }
}
