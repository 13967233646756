<template>
  <sub-menu-layout
    :nav-items="navItems"
    :select-label="activeMenu"
    header-title="Bucket settings"
    :parent-label="bucket.name"
    :parent-link="{ name: 'bucket.view', params: { id: this.bucket.uid } }">
    <router-view></router-view>
  </sub-menu-layout>
</template>

<script>
import _ from 'lodash'
import SubMenuLayout from '../../../components/layouts/SubMenuLayout'
import withEnterprise from '../../../mixins/withEnterprise'

export default {
  components: {
    SubMenuLayout
  },
  mixins: [withEnterprise],
  data () {
    return {
      bucket: {},
      navItems: []
    }
  },
  computed: {
    activeMenu () {
      return 'Overview'
    }
  },
  methods: {
    initMenu () {
      this.navItems.push({
        name: 'Overview',
        link: { name: 'bucket.settings' }
      })

      if (!this.isEnterpriseMember) {
        this.navItems.push({
          name: 'User access',
          link: { name: 'bucket.access' }
        })
      }
    }
  },
  created () {
    const bucketId = this.$route.params.id
    this.bucket = _.clone(_.find(this.$store.state.buckets.list, { uid: bucketId }))

    if (!this.bucket) {
      this.$noty.error('Bucket not found!')
      this.$router.push({ name: 'home' })
    }

    this.initMenu()
  }
}
</script>
