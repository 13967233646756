<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete entry</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" v-if="errorType === 'internal'">
          <strong>Server error</strong>
          <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
        </div>

        <div class="alert alert-danger" v-if="errorType === 'forbidden'">
          <strong>Access denied</strong>
          <p class="mb-0">You are not allowed to delete this entry.</p>
        </div>

        <div class="alert alert-danger" v-if="errorType === 'not_found'">
          <strong>Error</strong>
          <p class="mb-0">Entry not found.</p>
        </div>

        <p>Do you really want to delete this entry?</p>
        <p>This action can not be reverted!</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" @click="confirm()" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
          Delete
        </button>
        <button type="button" class="btn btn-secondary" @click="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../api'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    bucket: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isProcessing: false,
      errorType: null
    }
  },
  methods: {
    confirm () {
      this.isProcessing = true
      this.errorType = null

      api.entries.delete({ bucketId: this.bucket, id: this.id })
        .then(() => {
          this.$store.commit('BUCKET_ENTRIES_DELETED', this.id)
          this.$emit('deleted')
        })
        .catch(({ type }) => {
          this.errorType = type
        })
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
