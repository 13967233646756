import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isEnterpriseMember: 'isEnterpriseMember',
      enterprise: 'getEnterprise',
      enterpriseId: 'getEnterpriseId',
      enterpriseName: 'getEnterpriseName'
    })
  }
}
