import Vue from 'vue'
import Router from 'vue-router'
import config from '../config'
import SignupPage from '../views/Signup'
import LoginPage from '../views/auth/LoginPage'
import ForgotPassword from '../views/auth/ForgotPassword'
import ResetPassword from '../views/auth/ResetPassword'
import TwoFactorAuth from '../views/auth/TwoFactorAuth'
import AppInterface from '../views/AppInterface'
import Buckets from '../views/app/Buckets'
import BucketView from '../views/app/bucket/View'
import BucketSettings from '../views/app/bucket/Settings'
import BucketSettingsOverview from '../views/app/bucket/settings/Overview'
import BucketAccessSettings from '../views/app/bucket/settings/Access'
import Settings from '../views/app/Settings'
import SettingsDetails from '../views/app/settings/Details'
import SettingsPassword from '../views/app/settings/Password'
import SettingsTwoFactor from '../views/app/settings/TwoFactor'
import Billing from '../views/app/Billing'
import BillingOverview from '../views/app/billing/Overview'
import BillingDetails from '../views/app/billing/Details'
import BillingInvoices from '../views/app/billing/Invoices'
import BillingInvoice from '../views/app/billing/Invoice'
import BillingCards from '../views/app/billing/Cards'
import Team from '../views/app/Team'
import TeamUsers from '../views/app/team/Users'
import TeamUsersManage from '../views/app/team/users/Manage'
import TeamGroups from '../views/app/team/Groups'
import TeamGroupsManage from '../views/app/team/groups/Manage'
import TeamPermissions from '../views/app/team/RootPermissions'
import TeamAdmins from '../views/app/team/Admins'
import Subscribe from '../views/app/Subscribe'
import ChangePlan from '../views/app/ChangePlan'
import TermsOfUse from '../views/legal/TermsOfUse'
import PrivacyPolicy from '../views/legal/PrivacyPolicy'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/app/',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/signup',
      name: 'signup',
      component: SignupPage,
      meta: { requiresGuest: true }
    },
    {
      path: '/auth/login',
      name: 'auth.login',
      component: LoginPage,
      meta: { requiresGuest: true }
    },
    {
      path: '/auth/forgot-password',
      name: 'auth.forgot-password',
      component: ForgotPassword,
      meta: { requiresGuest: true }
    },
    {
      path: '/auth/reset-password/:token',
      name: 'auth.reset-password',
      component: ResetPassword,
      meta: { requiresGuest: true }
    },
    {
      path: '/auth/two-factor',
      name: 'auth.two-factor',
      component: TwoFactorAuth,
      meta: { requiresGuest: true }
    },
    {
      path: '/legal/terms-of-use',
      name: 'legal.terms',
      component: TermsOfUse
    },
    {
      path: '/legal/privacy-policy',
      name: 'legal.privacy',
      component: PrivacyPolicy
    },
    {
      path: '/',
      component: AppInterface,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          name: 'home',
          component: Buckets
        },
        {
          path: '/bucket/:id/settings',
          component: BucketSettings,
          children: [
            {
              path: '',
              name: 'bucket.settings',
              component: BucketSettingsOverview
            },
            {
              path: 'user-access',
              name: 'bucket.access',
              component: BucketAccessSettings
            }
          ]
        },
        {
          path: '/bucket/:id/:listId?',
          name: 'bucket.view',
          component: BucketView
        },
        {
          path: '/settings',
          component: Settings,
          children: [
            {
              path: 'details',
              name: 'settings.details',
              component: SettingsDetails,
              meta: {
                selectorTitle: 'My details'
              }
            },
            {
              path: 'password',
              name: 'settings.password',
              component: SettingsPassword,
              meta: {
                selectorTitle: 'Account password'
              }
            },
            {
              path: 'two-factor',
              name: 'settings.tfa',
              component: SettingsTwoFactor,
              meta: {
                selectorTitle: 'Two-factor security'
              }
            }
          ]
        },
        {
          path: '/billing',
          component: Billing,
          children: [
            {
              path: 'overview',
              name: 'billing.overview',
              component: BillingOverview,
              meta: {
                selectorTitle: 'Billing overview'
              }
            },
            {
              path: 'details',
              name: 'billing.details',
              component: BillingDetails,
              meta: {
                selectorTitle: 'Billing details'
              }
            },
            {
              path: 'invoices',
              name: 'billing.invoices',
              component: BillingInvoices,
              meta: {
                selectorTitle: 'Invoices'
              }
            },
            {
              path: 'invoices/:id',
              name: 'billing.invoices.details',
              component: BillingInvoice,
              meta: {
                selectorTitle: 'Invoices'
              }
            },
            {
              path: 'cards',
              name: 'billing.cards',
              component: BillingCards,
              meta: {
                selectorTitle: 'Credit cards'
              }
            }
          ]
        },
        {
          path: '/team',
          component: Team,
          children: [
            {
              path: 'users',
              name: 'team.users',
              component: TeamUsers,
              meta: {
                selectorTitle: 'Users'
              }
            },
            {
              path: 'users/:userId',
              name: 'team.users.manage',
              component: TeamUsersManage,
              meta: {
                selectorTitle: 'Users'
              }
            },
            {
              path: 'groups',
              name: 'team.groups',
              component: TeamGroups,
              meta: {
                selectorTitle: 'Groups'
              }
            },
            {
              path: 'groups/:groupId',
              name: 'team.groups.manage',
              component: TeamGroupsManage,
              meta: {
                selectorTitle: 'Groups'
              }
            },
            {
              path: 'root-permissions',
              name: 'team.permissions',
              component: TeamPermissions,
              meta: {
                selectorTitle: 'Root permissions'
              }
            },
            {
              path: 'admins',
              name: 'team.admins',
              component: TeamAdmins,
              meta: {
                selectorTitle: 'Admins'
              }
            }
          ]
        },
        {
          path: '/subscribe',
          name: 'subscribe',
          component: Subscribe
        },
        {
          path: '/change-plan',
          name: 'change-plan',
          component: ChangePlan
        }
      ]
    }
  ]
})

// TODO: 404 page

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem(config.auth.tokenKeyName)

    if (!token) {
      const query = to.fullPath !== '/' ? { redirect: to.fullPath } : null

      next({
        // query,
        path: config.auth.guestRedirectPath,
        query
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresGuest)) {
    const token = localStorage.getItem(config.auth.tokenKeyName)

    if (token) {
      next({
        path: config.auth.memberRedirectPath
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
