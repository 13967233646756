<template>
  <div class="access-list-form">
    <form role="form" v-if="!displayDeleteConfirm">

      <div class="alert alert-danger" v-if="errorType === 'internal'">
        <strong>Server error</strong>
        <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
      </div>

      <div class="alert alert-danger" v-if="errorType === 'forbidden'">
        <strong>Access denied</strong>
        <p class="mb-0">You are not allowed to perform this action.</p>
      </div>

      <div class="form-group">
        <label class="col-form-label">Grant following permissions:</label>

        <div class="checkbox" v-for="permission in permissions" :key="permission.key">
          <label>
            <input type="checkbox" v-model="form.permissions[permission.key]">
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="form-group">
        <label class="col-form-label">To users or groups:</label>

        <vue-select v-model="form.users"
                    multiple
                    :options="selectOptions"
                    label="name"
                    @search="handleSearch">
          <template slot="no-options">
            Enter a name...
          </template>
          <template slot="option" slot-scope="option">
            <span class="member-icon">
              <fa-icon icon="users" v-if="option.entity_type === 'group'" />
              <fa-icon icon="user" v-if="option.entity_type === 'user'" />
            </span>
            {{ option.name }}
          </template>
        </vue-select>

        <div class="invalid-feedback" v-if="errors.users">{{ errors.users[0] }}</div>
      </div>

      <div class="form-group">
        <label for="list-name" class="col-form-label">Save list as:</label>
        <input type="text" class="form-control" id="list-name" v-model="form.name" :class="{ 'is-invalid': errors.name }">
        <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
      </div>

      <div class="form-actions mt-3">
        <button type="button" class="btn btn-primary" @click.prevent="submit()" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
          Save
        </button>

        <div class="float-right">
          <button class="btn btn-link delete-button" @click.prevent="initiateDelete()">
            Delete
          </button>
        </div>
      </div>
    </form>

    <div class="mt-3" v-if="displayDeleteConfirm">
      <p>Do you really want to delete this access list?
        <br>This action can not be undone.</p>

      <div class="form-actions mt-3">
        <button type="button" class="btn btn-danger" @click.prevent="confirmDelete()" :class="{ 'loading-btn': isDeleting }" :disabled="isDeleting">
          Delete
        </button>

        <button type="button" class="btn btn-secondary" @click.prevent="cancelDelete()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { VueSelect } from 'vue-select'
import asyncProcess from '../../mixins/asyncProcess'
import withEnterprise from '../../mixins/withEnterprise'
import api from '../../api'

/**
   * Expected props:
   * - mode : create | edit
   * - permissions : array of available permissions
   * - model : model name, enterprise | list | bucket | entry
   * - objectId
   * - accessList : existing object, in edit mode
   */
export default {
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    permissions: {
      required: true,
      type: Array,
      default () {
        return []
      }
    },
    model: {
      type: String
    },
    objectId: {
      type: String
    },
    accessList: {
      type: Object
    }
  },
  mixins: [asyncProcess, withEnterprise],
  components: {
    VueSelect
  },
  data () {
    return {
      form: {
        name: '',
        users: [],
        permissions: {}
      },
      selectOptions: [],
      displayDeleteConfirm: false,
      isDeleting: false
    }
  },
  methods: {
    initPermissionValues () {
      this.permissions.forEach((permission) => {
        this.form.permissions[permission.key] = false
      })
    },
    handleSearch (search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce((loading, search, vm) => {
      vm.selectOptions = []

      api.enterprises.getAllMembers({
        enterpriseId: vm.enterpriseId,
        q: encodeURI(search)
      })
        .then((users) => {
          vm.selectOptions = users
          loading(false)
        })
    }, 350),
    submit () {
      if (this.mode === 'create') {
        this.create()
      } else if (this.mode === 'edit') {
        this.update()
      }
    },
    create () {
      this.startProcess()

      const reqData = {
        ...this.form,
        enterpriseId: this.enterpriseId,
        model: this.model,
        object_id: this.objectId
      }

      api.accessLists.create(reqData)
        .then((object) => {
          this.$emit('created', object)
          this.$noty.success('Permission list has been created.')
          this.resetForm()
          this.endProcess()
        })
        .catch(this.handleError)
    },
    update () {
      this.startProcess()

      const reqData = {
        ...this.form,
        enterpriseId: this.enterpriseId,
        id: this.accessList.id
      }

      api.accessLists.update(reqData)
        .then((object) => {
          this.$emit('updated', object)
          this.$noty.success('Permission list updated.')
          this.endProcess()
        })
        .catch(this.handleError)
    },
    resetForm () {
      this.form = {
        name: '',
        users: [],
        permissions: {}
      }

      this.initPermissionValues()
    },
    initEditForm () {
      this.form.name = this.accessList.name
      this.form.users = this.accessList.users

      if (this.accessList && this.accessList.permissions) {
        this.accessList.permissions.forEach((permission) => {
          this.form.permissions[permission.permission] = permission.value
        })
      }
    },
    initiateDelete () {
      this.displayDeleteConfirm = true
    },
    cancelDelete () {
      this.displayDeleteConfirm = false
    },
    confirmDelete () {
      this.isDeleting = true

      api.accessLists.delete({
        enterpriseId: this.enterpriseId,
        id: this.accessList.id
      })
        .then(() => {
          this.displayDeleteConfirm = false
          this.isDeleting = false
          this.$emit('deleted', this.accessList)
        })
        .catch(({ type }) => {
          if (type === 'forbidden') {
            this.$noty.error('You are not allowed to perform this action.')
          } else {
            this.$noty.error('Error while deleting permission list.')
          }
          this.isDeleting = false
        })
    }
  },
  created () {
    this.initPermissionValues()

    if (this.mode === 'edit') {
      this.initEditForm()
    }
  }
}
</script>

<style lang="scss">
  .member-icon {
    margin-right: 10px;
    color: silver;
  }
</style>
