<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="logo-wrapper">
          <img src="../../../../assets/images/logo-passbucket-320.png" alt="Passbucket | Cloud password manager" width="150" height="94">
        </div>

        <div class="login-box">
          <div class="block">
            <div class="block-body">
              <form action="" @submit.prevent="submit()">
                <div class="alert alert-danger" role="alert" v-if="hasError">
                  <strong>Access denied!</strong>
                  <p class="mb-0">Please check your auth code and try again.</p>
                </div>

                <h5 class="mb-3">Two Factor Authentication</h5>

                <p>In order to protect your account, a text message has been sent to your phone number.</p>
                <p>Please enter the confirmation code to authenticate.</p>

                <div class="form-group">
                  <input type="number" class="form-control" placeholder="Confirmation code" autocomplete="off" v-model="form.confirm_code">
                </div>

                <div class="text-center">
                  <button type="submit" class="btn btn-lg btn-primary submit-btn"
                          :class="{ 'loading-btn': isProcessing }"
                          :disabled="formDisabled">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="mt-3 text-center">
            <small><a href="" @click.prevent="cancel()">Cancel</a></small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../../../config'

export default {
  data () {
    return {
      isProcessing: false,
      form: {
        confirm_code: ''
      },
      hasError: false,
      error: {}
    }
  },
  computed: {
    formDisabled () {
      return this.form.confirm_code.length === 0
    },
    tfaUserToken () {
      return this.$store.state.auth.tfaUserToken
    }
  },
  methods: {
    submit () {
      this.isProcessing = true
      this.hasError = false

      const postData = Object.assign({}, this.form, {
        two_factor_token: this.tfaUserToken
      })

      this.$store.dispatch('verifyTFACode', postData)
        .then(() => {
          this.isProcessing = false
          window.location.href = config.auth.memberRedirectPath
          this.cancel()
        })
        .catch((error) => {
          this.isProcessing = false
          this.hasError = true
          this.error = error

          if (error.type === 'unauthorized') {
            this.form.confirm_code = ''
          }
        })
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
