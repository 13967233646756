<template>
  <div class="account-list">
    <div class="account-list-item" v-for="profile in identities" :key="profile.uid" :class="{ active: profile.isActive }" @click.prevent="switchProfile(profile)">
      <div v-if="!profile.isActive" class="float-right">
        <a href="" class="disconnect-icon" @click.prevent.stop="disconnectProfile(profile)">
          <fa-icon icon="times"></fa-icon>
        </a>
      </div>
      <div class="entity-name">{{ profile.entity }}</div>
      <div class="account-name">{{ profile.name }}</div>
      <div class="account-email">{{ profile.email }}</div>
      <div class="account-settings" v-if="profile.isActive">
        <router-link :to="{ name: 'settings.details' }" class="menu-item">Settings</router-link>
        <router-link :to="{ name: 'billing.overview' }" class="menu-item">Billing</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    identities () {
      return this.$store.getters.getIdentities
    }
  },
  methods: {
    switchProfile (profile) {
      if (!profile.isActive) {
        this.$store.dispatch('switchIdentity', profile)
      }
    },
    disconnectProfile (profile) {
      this.$store.dispatch('removeIdentity', profile.uid)
    }
  }
}
</script>
