<template>
  <div class="modal-dialog" role="document">
    <form action="" @submit.prevent="submit()">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ entry.name }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <password-view v-if="entry.type === 'password'" :entry="entry"></password-view>
          <note-view v-if="entry.type === 'note'" :entry="entry"></note-view>
        </div>
        <div class="modal-footer">
          <div class="mr-auto">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-secondary" @click="editEntry()">
                <fa-icon icon="pencil-alt"></fa-icon>
              </button>
              <button type="button" class="btn btn-outline-secondary" @click="deleteEntry()">
                <fa-icon icon="trash"></fa-icon>
              </button>
              <button type="button" class="btn btn-outline-secondary" v-if="isEnterpriseMember && !isSystemBucket" @click="managePermissions()">
                <fa-icon icon="users"></fa-icon>
              </button>
            </div>
          </div>

          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PasswordView from '../../../entries/passwords/PasswordView'
import NoteView from '../../../entries/notes/NoteView'
import withEnterprise from '../../../../mixins/withEnterprise'

export default {
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  mixins: [withEnterprise],
  components: {
    PasswordView,
    NoteView
  },
  computed: {
    isSystemBucket () {
      return this.entry && this.entry.bucket && this.entry.bucket.is_system
    }
  },
  methods: {
    deleteEntry () {
      this.$emit('delete')
    },
    editEntry () {
      this.$emit('edit')
    },
    managePermissions () {
      this.$emit('manage-permissions')
    }
  }
}
</script>
