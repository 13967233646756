<template>
  <div class="view-buckets-list">
    <div class="container mt-3 mb-3">
      <subscription-alert></subscription-alert>

      <div class="dashhead nosub">
        <div class="dashhead-titles">
          <h3 class="dashhead-title" v-if="isEnterpriseMember">{{ enterpriseName }}</h3>
          <h3 class="dashhead-title" v-else>My buckets</h3>
        </div>

        <div class="dashhead-toolbar" v-if="!fetching && buckets.length > 0">
          <ul class="nav nav-bordered">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: displayMode === 'active'}" href="" @click.prevent="switchMode('active')">Active</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: displayMode === 'starred'}" href="" @click.prevent="switchMode('starred')">Starred</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: displayMode === 'archived'}" href="" @click.prevent="switchMode('archived')">Archived</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container mt-3" v-if="fetching">
      <div class="ball-clip-rotate"><div></div></div>
    </div>

    <div class="container mt-3 mb-3" v-if="!fetching">
      <div class="row justify-content-between">
        <div class="col-6 col-sm-5 col-md-4">
          <quick-filter v-model="nameFilter"></quick-filter>
        </div>
        <div class="col-6 col-sm-7 col-md-8 text-right">
          <div class="btn-toolbar justify-content-end">
            <div class="btn-group mr-3 d-none d-md-inline-flex" v-if="hasSelection">
              <button class="btn btn-outline-secondary" @click="bulkStar()" :disabled="displayMode === 'starred'" v-tooltip="'Add to favorites'">
                <fa-icon :icon="['fas', 'star']"></fa-icon>
              </button>
              <button class="btn btn-outline-secondary" @click="bulkUnstar()" v-tooltip="'Remove from favorites'">
                <fa-icon :icon="['far', 'star']"></fa-icon>
              </button>
            </div>

            <div class="btn-group mr-3 d-none d-md-inline-flex" v-if="hasSelection">
              <button class="btn btn-outline-secondary" @click="bulkArchive()" :disabled="displayMode === 'archived'" v-tooltip="'Archive'">
                <fa-icon :icon="['fas', 'archive']"></fa-icon>
              </button>
              <button class="btn btn-outline-secondary" @click="bulkUnarchive()" :disabled="displayMode === 'active'" v-tooltip="'Unarchive'">
                <fa-icon :icon="['far', 'caret-square-up']"></fa-icon>
              </button>
            </div>

            <div class="btn-group mr-3 d-inline-flex d-md-none" v-if="hasSelection">
              <div class="btn-group">
                <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">
                  Manage...
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="" @click.prevent="bulkStar()" v-if="displayMode !== 'starred'">Add star</a>
                  <a class="dropdown-item" href="" @click.prevent="bulkUnstar()">Remove star</a>
                  <a class="dropdown-item" href="" @click.prevent="bulkArchive()" v-if="displayMode !== 'archived'">Archive</a>
                  <a class="dropdown-item" href="" @click.prevent="bulkUnarchive()" v-if="displayMode !== 'active'">Unarchive</a>
                </div>
              </div>
            </div>

            <button type="button" class="btn btn-outline-primary" @click="createBucket()">
              <fa-icon icon="plus"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div v-if="showNoResultText" class="no-result">
        Nothing found.
      </div>

      <div class="card pb-table-card mt-3" v-if="sortedBuckets.length > 0">
        <div class="card-body">
          <div class="pb-table-list">
            <table class="table bucket-list-table">
              <tbody>
                <bucket-table-row v-for="bucket in sortedBuckets"
                                  :key="bucket.uid"
                                  :bucket="bucket"
                                  :bookmarks="bookmarks"
                                  :selected="selectedItems.indexOf(bucket.uid) > -1"
                                  @toggle-select="toggleSelect">
                </bucket-table-row>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="welcome-box" v-if="showWelcomeBox">
        <div class="row">
          <div class="col-3 col-sm-2 text-center info-col">
            <fa-icon icon="info-circle" size="lg"></fa-icon>
          </div>
          <div class="col-7 col-sm-8">
            <p>&laquo; Private passwords &raquo; is your first bucket.</p>
            <p>Buckets are like containers for your passwords. You can create additional buckets and share them with your family, friends or colleagues.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BucketTableRow from '../../components/buckets/table/Row'
import CreateBucketModal from '../../components/modals/buckets/Create'
import withEnterprise from '../../mixins/withEnterprise'
import QuickFilter from '../../components/common/QuickFilter'
import SubscriptionAlert from '../../components/common/SubscriptionAlert'

export default {
  name: 'buckets-list',
  mixins: [withEnterprise],
  components: {
    BucketTableRow,
    QuickFilter,
    SubscriptionAlert
  },
  data () {
    return {
      displayMode: 'active',
      nameFilter: ''
    }
  },
  computed: {
    buckets () {
      return this.$store.state.buckets.list
    },
    starredBuckets () {
      return _.filter(this.buckets, o => this.bookmarkUids.indexOf(o.uid) > -1)
    },
    archivedBuckets () {
      return _.filter(this.buckets, o => this.archiveUids.indexOf(o.uid) > -1)
    },
    activeBuckets () {
      return _.filter(this.buckets, o => this.archiveUids.indexOf(o.uid) === -1)
    },
    visibleBuckets () {
      if (this.displayMode === 'starred') {
        return this.starredBuckets
      }

      if (this.displayMode === 'archived') {
        return this.archivedBuckets
      }

      return this.activeBuckets
    },
    bookmarks () {
      return this.$store.state.buckets.bookmarks
    },
    archives () {
      return this.$store.state.buckets.archives
    },
    bookmarkUids () {
      const list = []

      this.bookmarks.forEach((o) => {
        if (o.bookmarkable) {
          list.push(o.bookmarkable.uid)
        }
      })

      return list
    },
    archiveUids () {
      const list = []

      this.archives.forEach((o) => {
        if (o.archivable) {
          list.push(o.archivable.uid)
        }
      })

      return list
    },
    fetching () {
      return this.$store.state.buckets.fetching
    },
    filteredBuckets () {
      if (this.nameFilter.length > 0) {
        return _.filter(
          this.visibleBuckets,
          o => o.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
        )
      }
      return this.visibleBuckets
    },
    sortedBuckets () {
      return _.orderBy(this.filteredBuckets, ['is_system', o => o.name.toLowerCase()], ['desc', 'asc'])
    },
    showWelcomeBox () {
      return this.buckets.length === 1 && this.nameFilter.length === 0 && !this.fetching
    },
    showNoResultText () {
      return this.nameFilter.length > 0 && this.filteredBuckets.length === 0 && !this.fetching
    },
    selectedItems () {
      return this.$store.state.buckets.selectedItems
    },
    hasSelection () {
      return this.selectedItems.length > 0
    }
  },
  methods: {
    switchMode (mode) {
      this.displayMode = mode
      this.$store.commit('BUCKET_UNSELECT_ALL')
    },
    toggleSelect (uid) {
      this.$store.dispatch('toggleSelectBucket', uid)
    },
    createBucket () {
      this.$modal.open(CreateBucketModal)
        .done((bucket) => {
          this.$store.commit('BUCKETS_CREATED', bucket)
          this.$router.push({ name: 'bucket.view', params: { id: bucket.uid } })
        })
    },
    bulkArchive () {
      this.$store.dispatch('archiveSelectedBuckets')
    },
    bulkUnarchive () {
      this.$store.dispatch('unarchiveSelectedBuckets')
    },
    bulkStar () {
      this.$store.dispatch('starSelectedBuckets')
    },
    bulkUnstar () {
      this.$store.dispatch('unstarSelectedBuckets')
    }
  }
}
</script>
