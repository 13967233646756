<template>
  <div class="billing-invoices">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <div class="no-result" v-if="!invoices.length">
          No invoice found.
        </div>

        <div v-if="invoices.length" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="col-name">Date</th>
                <th scope="col" class="col-level">Reference</th>
                <th scope="col" class="col-level">Amount</th>
                <th scope="col" class="col-level">Status</th>
              </tr>
            </thead>
            <tbody>
              <invoice-table-row v-for="invoice in invoices"
                                 :key="invoice.reference"
                                 :invoice="invoice"></invoice-table-row>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '../../../api'
import SmallLoader from '../../../components/common/SmallLoader'
import InvoiceTableRow from '../../../components/billing/InvoiceTableRow'

export default {
  components: {
    SmallLoader,
    InvoiceTableRow
  },
  data () {
    return {
      isLoading: true,
      invoices: []
    }
  },
  computed: {
    ...mapGetters({
      subscriber: 'getSubscriber',
      subscriberId: 'getSubscriberId'
    })
  },
  methods: {
    async fetchData () {
      if (!this.subscriberId) {
        await this.$store.dispatch('fetchSubscriber')
      }

      this.invoices = await api.subscribers.getInvoices({ uid: this.subscriberId })
      this.isLoading = false
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
