<template>
  <div class="view-forgot-password auth-layout">
    <div class="logo-wrapper">
      <img src="../../assets/images/logo-passbucket-320.png" alt="Passbucket | Cloud password manager" width="250" height="157">
    </div>

    <div class="login-box">
      <div class="block">
        <div class="block-body">
          <form action="" @submit.prevent="submit()">
            <div class="alert alert-danger" role="alert" v-if="hasError">
              <strong>Sorry,</strong>
              <p class="mb-0">we can not process your request. Make sure you've provided the right email address and try again.</p>
            </div>

            <div class="alert alert-success" role="alert" v-if="isSuccess">
              <strong>Thank you.</strong>
              <p class="mb-0">We've just sent you instructions to recover your account. You should receive the email within a few minutes.</p>
            </div>

            <div v-if="!isSuccess">
              <p class="mb-4">Please enter the email address linked to your account. We will send you instructions to reset your password.</p>

              <div class="form-group">
                <input type="email" class="form-control" placeholder="Email" name="email" autocomplete="off" v-model="form.email">
              </div>

              <div class="text-center mt-4">
                <button type="submit" class="btn btn-lg btn-primary submit-btn"
                        :class="{ 'loading-btn': isProcessing }"
                        :disabled="formDisabled">
                  Continue
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="mt-3 text-center">
        <small><router-link :to="{ name: 'auth.login' }">Back to login</router-link></small>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'forgot-password-page',
  data () {
    return {
      isProcessing: false,
      form: {
        email: ''
      },
      isSuccess: false,
      hasError: false,
      error: {}
    }
  },
  computed: {
    formDisabled () {
      return this.form.email.length === 0
    }
  },
  methods: {
    submit () {
      this.isProcessing = true
      this.hasError = false

      this.$store.dispatch('forgotPassword', this.form)
        .then(() => {
          this.isProcessing = false
          this.isSuccess = true
        })
        .catch((error) => {
          this.isProcessing = false
          this.hasError = true
          this.error = error
        })
    }
  }
}
</script>
