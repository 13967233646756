<template>
  <div class="view-team-groups">
    <div class="row justify-content-between mb-3" v-if="canManageUsers">
      <div class="col-6 col-sm-5 col-md-4">
        <quick-filter v-model="nameFilter"></quick-filter>
      </div>
      <div class="col-6 col-sm-7 col-md-8 text-right">
        <button type="button" class="btn btn-outline-primary" @click="createGroup()">
          <fa-icon icon="plus"></fa-icon>
        </button>
      </div>
    </div>

    <div class="card" v-if="canManageUsers">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <div class="table-responsive">
          <table class="table table-team-groups">
            <thead>
            <tr>
              <th scope="col" class="col-name">Name</th>
              <th scope="col" class="col-email d-none d-lg-table-cell">System name</th>
              <th scope="col" class="col-created">Created</th>
              <th scope="col" class="col-actions"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="group in sortedGroups" :key="group.id">
              <td class="col-name">
                <router-link :to="{ name: 'team.groups.manage', params: { groupId: group.uid } }">{{ group.friendly_name }}</router-link>
              </td>
              <td class="col-email d-none d-lg-table-cell">{{ group.name }}</td>
              <td class="col-created">{{ group.created_at }}</td>
              <td class="col-actions text-right">
                <span class="action" @click="deleteGroup(group)">
                  <fa-icon icon="times"></fa-icon>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <access-denied v-if="!canManageUsers"></access-denied>
  </div>
</template>

<script>
import _ from 'lodash'
import asyncProcess from '../../../mixins/asyncProcess'
import api from '../../../api'
import SmallLoader from '../../../components/common/SmallLoader'
import QuickFilter from '../../../components/common/QuickFilter'
import withEnterprise from '../../../mixins/withEnterprise'
import withRootPermissions from '../../../mixins/withRootPermissions'
import CreateGroupModal from '../../../components/modals/team/groups/Create'
import DeleteGroupModal from '../../../components/modals/team/groups/Delete'

export default {
  mixins: [withEnterprise, withRootPermissions, asyncProcess],
  components: {
    SmallLoader,
    QuickFilter
  },
  data () {
    return {
      isLoading: true,
      groups: [],
      nameFilter: ''
    }
  },
  computed: {
    sortedGroups () {
      return _.orderBy(this.filteredGroups, ['friendly_name'], ['asc'])
    },
    filteredGroups () {
      if (this.nameFilter.length > 0) {
        return _.filter(
          this.groups,
          o => o.friendly_name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1 ||
              o.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
        )
      }
      return this.groups
    }
  },
  methods: {
    createGroup () {
      this.$modal.open(CreateGroupModal)
        .done((group) => {
          this.groups.push(group)
        })
    },
    deleteGroup (group) {
      this.$modal.open(DeleteGroupModal, { group })
        .done((confirmed) => {
          if (!confirmed) {
            return
          }

          const index = this.groups.indexOf(group)
          if (index > -1) {
            this.groups.splice(index, 1)
          }

          api.usergroups.delete({
            enterpriseId: this.enterpriseId,
            id: group.uid
          })
            .catch(({ type }) => {
              this.groups.push(group)
              if (type === 'internal') {
                this.$noty.warning('Error while deleting group.')
              } else if (type === 'forbidden') {
                this.$noty.warning('You are not allowed to perform this action.')
              }
            })
        })
    }
  },
  created () {
    api.usergroups.query({ enterpriseId: this.enterpriseId })
      .then((groups) => {
        this.groups = groups
        this.isLoading = false
      })
      .catch(() => {
        this.$noty.warning('Server error. Please reload the page and try again.')
      })
  }
}
</script>
