<template>
  <div class="bucket-settings-overview">
    <div class="card">
      <div class="card-body">
        <form action="" @submit.prevent="updateName()">
          <div class="form-group">
            <label for="bucketName">Bucket name</label>
            <input type="text" class="form-control" id="bucketName" v-model="bucket.name">
          </div>

          <button type="submit" class="btn btn-primary"
                  :class="{ 'loading-btn': processing.name }"
                  :disabled="processing.name">
            Save
          </button>
        </form>

        <div v-if="false">
          <hr>

          <div class="row align-items-center">
            <div class="col-7">
              This bucket is currently active
            </div>
            <div class="col-5 text-right">
              <button type="button" class="btn btn-outline-secondary"
                      :class="{ 'loading-btn': processing.archiving }"
                      :disabled="processing.archiving">
                Archive bucket
              </button>
            </div>
          </div>
        </div>

        <div v-if="false">
          <hr>

          <div class="row align-items-center"  v-if="false">
            <div class="col-7">
              This bucket has been archived
            </div>
            <div class="col-5 text-right">
              <button type="button" class="btn btn-outline-secondary"
                      :class="{ 'loading-btn': processing.unarchiving }"
                      :disabled="processing.unarchiving">
                Unarchive
              </button>
            </div>
          </div>
        </div>

        <hr>

        <div class="row align-items-center">
          <div class="col-7">
            Destroy this bucket and its content
          </div>
          <div class="col-5 text-right">
            <button type="button" class="btn btn-outline-secondary"
                    :class="{ 'loading-btn': processing.deletion }"
                    :disabled="processing.deletion"
                    @click="askDeletionConfirm()">
              Delete bucket
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../../../api'
import DeleteBucketModal from '../../../../components/modals/buckets/Delete'

export default {
  data () {
    return {
      bucket: {},
      processing: {
        name: false,
        archiving: false,
        unarchiving: false,
        deletion: false
      }
    }
  },
  methods: {
    updateName () {
      this.processing.name = true

      const data = _.assign({}, this.bucket, {
        id: this.bucket.uid
      })

      api.buckets.update(data)
        .then((bucket) => {
          this.$noty.success('Bucket has been updated.')
          this.$store.commit('BUCKETS_UPDATED', bucket)
          this.processing.name = false
        })
        .catch(({ type }) => {
          if (type === 'forbidden') {
            this.$noty.warning('Sorry, you can not modify this bucket.')
          } else {
            this.$noty.warning('A server error occured, please retry in a few moment!')
          }
          this.processing.name = false
        })
    },
    askDeletionConfirm () {
      this.$modal.open(DeleteBucketModal)
        .done((confirmed) => {
          if (confirmed === true) {
            this.deleteBucket()
          }
        })
    },
    deleteBucket () {
      this.processing.deletion = true

      api.buckets.delete({ id: this.bucket.uid })
        .then(() => {
          this.$noty.success('Bucket has been deleted.')
          this.$store.commit('BUCKETS_DELETED', this.bucket.uid)
          this.$router.push({ name: 'home' })
        })
        .catch(({ type }) => {
          if (type === 'forbidden') {
            this.$noty.warning('Sorry, you can not delete this bucket.')
          } else {
            this.$noty.warning('A server error occured, please retry in a few moment!')
          }
          this.processing.deletion = false
        })
    }
  },
  created () {
    const bucketId = this.$route.params.id
    this.bucket = _.clone(_.find(this.$store.state.buckets.list, { uid: bucketId }))

    if (!this.bucket) {
      this.$noty.error('Bucket not found!')
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
