import { render, staticRenderFns } from "./AccessPolicyForm.vue?vue&type=template&id=3f61e80b&"
import script from "./AccessPolicyForm.vue?vue&type=script&lang=js&"
export * from "./AccessPolicyForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports