<template>
  <div class="access-policy-form">
    <small-loader v-if="isLoading"></small-loader>
    <form role="form" class="form-inline" v-else>
      <select class="form-control policy-selector" id="inputPolicySelect" v-model="form.policy">
        <option value="inherit">Inherit permissions from parents</option>
        <option value="restrict">Restricted permissions</option>
      </select>

      <button type="button" class="btn btn-outline-secondary" @click.prevent="apply()" :disabled="isProcessing" :class="{ 'loading-btn': isProcessing }">
        Apply
      </button>
    </form>
  </div>
</template>

<script>
import api from '../../api'
import SmallLoader from '../common/SmallLoader'
import withEnterprise from '../../mixins/withEnterprise'

export default {
  props: {
    model: {
      type: String,
      required: true
    },
    objectId: {
      type: String,
      required: true
    }
  },
  mixins: [withEnterprise],
  components: {
    SmallLoader
  },
  data () {
    return {
      isLoading: true,
      isProcessing: false,
      form: {
        policy: ''
      }
    }
  },
  methods: {
    fetchData () {
      api.accessPolicies.get({
        enterpriseId: this.enterpriseId,
        model: this.model,
        objectId: this.objectId
      })
        .then((response) => {
          this.form.policy = response.policy
          this.isLoading = false
        })
    },
    apply () {
      this.isProcessing = true

      api.accessPolicies.update({
        enterpriseId: this.enterpriseId,
        model: this.model,
        objectId: this.objectId,
        policy: this.form.policy
      })
        .then(() => {
          this.$noty.success('Access policy saved.')
          this.isProcessing = false
        })
        .catch(({ type }) => {
          if (type === 'forbidden') {
            this.$noty.error('You are not allowed to perform this action.')
          } else {
            this.$noty.error('Error while saving access policy.')
          }

          this.isProcessing = false
        })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
