<template>
  <div class="comp-note-form">
    <div class="form-group row">
      <label for="inputName" class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" :class="{ 'is-invalid': errors.name }" id="inputName" v-model="form.name" @change="handleChange" tabindex="1">
        <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputNotes" class="col-sm-2 col-form-label">Text</label>
      <div class="col-sm-10">
        <textarea class="form-control" :class="{ 'is-invalid': errors.text }" id="inputNotes" rows="13" v-model="form.text" @change="handleChange" tabindex="2"></textarea>
        <div class="invalid-feedback" v-if="errors.text">{{ errors.text[0] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    handleChange () {
      this.$emit('input', this.form)
    }
  },
  created () {
    this.form = this.value
  },
  updated () {
    this.form = this.value
  }
}
</script>
