<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create user group</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" v-if="errorType === 'internal'">
              <strong>Server error</strong>
              <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'forbidden'">
              <strong>Sorry,</strong>
              <p class="mb-0">You are not allowed to manage users and groups.</p>
            </div>

            <div class="form-group">
              <label for="group-name" class="col-form-label">Name</label>
              <input type="text" class="form-control" id="group-name" v-model="form.friendly_name" :class="{ 'is-invalid': errors.friendly_name }" @keyup="handleNameChange" @change="handleNameChange">
              <div class="invalid-feedback" v-if="errors.friendly_name">{{ errors.friendly_name[0] }}</div>
            </div>

            <div class="form-group">
              <label for="group-sysname" class="col-form-label">System name</label>
              <input type="text" class="form-control" id="group-sysname" v-model="form.name" :class="{ 'is-invalid': errors.name }" placeholder="Alphanumeric characters only">
              <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
              Create account
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import slugify from 'slugify'
import api from '../../../../api'
import asyncProcess from '../../../../mixins/asyncProcess'
import withEnterprise from '../../../../mixins/withEnterprise'

export default {
  mixins: [asyncProcess, withEnterprise],
  data () {
    return {
      isLoading: false,
      form: {
        friendly_name: '',
        name: ''
      }
    }
  },
  methods: {
    submit () {
      this.startProcess()

      const payload = {
        ...this.form,
        enterpriseId: this.enterpriseId
      }

      api.usergroups.create(payload)
        .then((group) => {
          this.done(group)
        })
        .catch(this.handleError)
    },
    handleNameChange () {
      this.form.name = slugify(this.form.friendly_name, {
        replacement: '_',
        lower: true
      })
    }
  }
}
</script>
