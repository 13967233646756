<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change plan</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Do you really want to change your subcription?</p>
          <p>Price changes will be reflected on your next invoice.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="confirm()" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
            Change plan
          </button>
          <button type="button" class="btn btn-secondary" @click="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api'

export default {
  data () {
    return {
      plan: '',
      isProcessing: false
    }
  },
  methods: {
    confirm () {
      this.isProcessing = true

      api.subscriptions.changePlan({
        uid: this.$store.state.subscription.activeSubscription.uid,
        plan: this.plan
      })
        .then(() => {
          this.isProcessing = false
          this.done()
        })
        .catch(() => {
          this.$noty.error('An error occured. Please try again later.')
        })
    }
  },
  created () {
    this.plan = this.data.plan
  }
}
</script>
