<template>
  <div class="permission-manager">
    <div v-if="isLoading">
      <small-loader></small-loader>
    </div>
    <div v-else>

      <v-collapse-group :onlyOneActive="true" class="access-lists-wrapper">
        <v-collapse-wrapper class="card">
          <div class="card-header" v-collapse-toggle>
            <fa-icon icon="plus"></fa-icon>
            Create new permission list
          </div>
          <div class="content" v-collapse-content>
            <div class="card-body">
              <access-list-form :permissions="availablePermissions" mode="create" :model="model" :object-id="objectId" @created="onCreate"></access-list-form>
            </div>
          </div>
        </v-collapse-wrapper>

        <v-collapse-wrapper class="card" v-for="accessList in accessLists" :key="accessList.id">
          <div class="card-header" v-collapse-toggle>
            {{ accessList.name  || 'Untitled list' }}
          </div>
          <div class="content" v-collapse-content>
            <div class="card-body">
              <access-list-form :permissions="availablePermissions" mode="edit" :access-list="accessList" @updated="onUpdate" @deleted="onDelete"></access-list-form>
            </div>
          </div>
        </v-collapse-wrapper>
      </v-collapse-group>

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../api'
import withEnterprise from '../../mixins/withEnterprise'
import SmallLoader from '../common/SmallLoader'
import AccessListForm from './AccessListForm'

export default {
  props: {
    model: {
      type: String,
      required: true
    },
    objectId: {
      type: String,
      required: true
    }
  },
  mixins: [withEnterprise],
  components: {
    AccessListForm,
    SmallLoader
  },
  data () {
    return {
      isLoading: true,
      availablePermissions: [],
      accessLists: []
    }
  },
  methods: {
    fetchContextPermissions () {
      return api.permissions.getContextPermissions({
        enterpriseId: this.enterpriseId,
        context: this.model
      })
        .then((permissions) => {
          this.availablePermissions = permissions
        })
    },
    fetchAccessLists () {
      return api.accessLists.getListByEntity({
        enterpriseId: this.enterpriseId,
        model: this.model,
        objectId: this.objectId
      })
        .then((lists) => {
          this.accessLists = lists
        })
    },
    onCreate (accessList) {
      this.accessLists.push(accessList)
    },
    onUpdate (accessList) {
      const object = _.find(this.accessLists, { id: accessList.id })
      const index = this.accessLists.indexOf(object)

      if (index > -1) {
        this.accessLists.splice(index, 1, accessList)
      }
    },
    onDelete (accessList) {
      const object = _.find(this.accessLists, { id: accessList.id })
      const index = this.accessLists.indexOf(object)

      if (index > -1) {
        this.accessLists.splice(index, 1)
      }
    }
  },
  created () {
    const promises = [
      this.fetchContextPermissions(),
      this.fetchAccessLists()
    ]

    Promise.all(promises)
      .then(() => {
        this.isLoading = false
      })
  }
}
</script>
