<template>
  <div class="view-subscribe mt-3 mb-3">
    <div v-if="isLoading" class="container">
      <small-loader></small-loader>
    </div>
    <div v-else>
      <div v-if="isComplete">
        <div class="subscription-thank-you mt-3 mb-3">
          <div class="container">
            <div class="text mt-5">Your subscription has been updated.</div>
            <div class="text mt-2">Proration will be applied to your next invoice.</div>
          </div>
        </div>
      </div>
      <div v-else>
        <standard-plan-selector v-if="!isComplete && !isEnterpriseMember" @selected="handleSelectedPlan"></standard-plan-selector>
        <enterprise-plan-selector v-if="!isComplete && isEnterpriseMember" @selected="handleSelectedPlan"></enterprise-plan-selector>
      </div>
    </div>
  </div>
</template>

<script>
import SmallLoader from '../../components/common/SmallLoader'
import StandardPlanSelector from '../../components/subscribe/StandardPlanSelector'
import EnterprisePlanSelector from '../../components/subscribe/EnterprisePlanSelector'
import withEnterprise from '../../mixins/withEnterprise'
import ChangePlanModal from '../../components/modals/subscriptions/ChangePlan'

export default {
  mixins: [withEnterprise],
  components: {
    SmallLoader,
    EnterprisePlanSelector,
    StandardPlanSelector
  },
  data () {
    return {
      isLoading: true,
      isComplete: false,
      selectedPlan: null
    }
  },
  methods: {
    handleSelectedPlan (plan) {
      this.selectedPlan = plan

      this.$modal.open(ChangePlanModal, { plan })
        .done(() => {
          this.isComplete = true
        })
    }
  },
  created () {
    this.$store.dispatch('searchValidSubscription')
      .then(() => {
        this.isLoading = false
      })
  }
}
</script>

<style lang="scss" scoped>
  .subscription-thank-you {
    text-align: center;

    h3 {
      font-size: 40px;
    }

    .text {
      font-size: 18px;
      font-weight: 200;
    }
  }
</style>
