<template>
  <div class="comp-password-form">
    <div class="form-group row">
      <label for="inputName" class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" :class="{ 'is-invalid': errors.name }" id="inputName" v-model="form.name" @change="handleChange" tabindex="1">
        <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputAddress" class="col-sm-2 col-form-label">Address</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" :class="{ 'is-invalid': errors.url }" id="inputAddress" v-model="form.url" @change="handleChange" tabindex="2">
        <div class="invalid-feedback" v-if="errors.url">{{ errors.url[0] }}</div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputUsername" class="col-sm-2 col-form-label">Username</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" :class="{ 'is-invalid': errors.username }" id="inputUsername" v-model="form.username" @change="handleChange" tabindex="3">
        <div class="invalid-feedback" v-if="errors.username">{{ errors.username[0] }}</div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
      <div class="col-sm-10">
        <div class="input-group">
          <input :type="pwdInputType" class="form-control" :class="{ 'is-invalid': errors.password }" id="inputPassword" v-model="form.password" @change="handleChange" tabindex="4">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="togglePwdVisibility()">
              <fa-icon :icon="['fas', 'eye']" v-if="!isPwdClear"></fa-icon>
              <fa-icon :icon="['fas', 'eye-slash']" v-else></fa-icon>
            </button>
            <button class="btn btn-outline-secondary" type="button" @click.prevent="generatePassword(8)" v-tooltip="'Generate'">
              <fa-icon :icon="['fas', 'magic']"></fa-icon>
            </button>
            <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="" @click.prevent="generatePassword(8)">8 characters</a>
              <a class="dropdown-item" href="" @click.prevent="generatePassword(16)">16 characters</a>
              <a class="dropdown-item" href="" @click.prevent="generatePassword(24)">24 characters</a>
              <a class="dropdown-item" href="" @click.prevent="generatePassword(32)">32 characters</a>
            </div>
          </div>
        </div>
        <div class="invalid-feedback" v-if="errors.password">{{ errors.password[0] }}</div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPasswordConfirm" class="col-sm-2 col-form-label">Repeat</label>
      <div class="col-sm-10">
        <input :type="pwdInputType" class="form-control" :class="{ 'is-invalid': errors.confirm_password }" id="inputPasswordConfirm" v-model="form.confirm_password" @change="handleChange" tabindex="5">
        <div class="invalid-feedback" v-if="errors.confirm_password">{{ errors.confirm_password[0] }}</div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputNotes" class="col-sm-2 col-form-label">Notes</label>
      <div class="col-sm-10">
        <textarea class="form-control" :class="{ 'is-invalid': errors.text }" id="inputNotes" rows="5" v-model="form.text" @change="handleChange" tabindex="6"></textarea>
        <div class="invalid-feedback" v-if="errors.text">{{ errors.text[0] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      form: {},
      isPwdClear: false
    }
  },
  computed: {
    pwdInputType () {
      return this.isPwdClear ? 'text' : 'password'
    }
  },
  methods: {
    generatePassword (length) {
      api.passwords.generate({ length })
        .then(({ password }) => {
          this.form.password = password
          this.form.confirm_password = password
          this.handleChange()
        })
    },
    togglePwdVisibility () {
      this.isPwdClear = !this.isPwdClear
    },
    handleChange () {
      this.$emit('input', this.form)
    }
  },
  created () {
    this.form = this.value
  },
  updated () {
    this.form = this.value
  }
}
</script>
