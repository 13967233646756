<template>
  <div class="pb-navbar navbar navbar-expand-sm fixed-top navbar-dark">
    <div class="container">
      <div class="navbar-brand">
        <router-link :to="{ name: 'home' }" class="pb-logo d-none d-sm-block"></router-link>
        <router-link :to="{ name: 'home' }" class="pb-xs-logo d-block d-sm-none"></router-link>
      </div>

      <form action="" class="form-inline flex-row ml-auto pr-3">
        <global-search></global-search>
      </form>

      <ul class="navbar-nav d-none d-sm-flex">
        <li class="nav-item" v-if="enterprise && canAccessTeamMenu">
          <router-link class="nav-link" :to="{ name: 'team.users' }">
            <fa-icon icon="users" size="lg" />
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <a href="" class="nav-link dropdown-toggle" data-toggle="dropdown">
            <fa-icon icon="user" size="lg" />
          </a>
          <div class="account-dropdown dropdown-menu dropdown-menu-right">
            <account-list v-if="identities.length > 0"></account-list>
            <a class="dropdown-item" href="" @click.prevent="addAccount()">Add existing account</a>
            <a class="dropdown-item" href="" @click.prevent="logoutFromAll()" v-if="identities.length > 1">Sign out from all accounts</a>
            <a class="dropdown-item" href="" @click.prevent="logout()" v-else>Sign out</a>
          </div>
        </li>
      </ul>

      <ul class="navbar-nav d-flex d-sm-none">
        <a href="" class="nav-link" @click.prevent="toggleMobileMenu">
          <fa-icon icon="bars" size="lg"></fa-icon>
        </a>
      </ul>
    </div>
  </div>
</template>

<script>
import AccountList from '../auth/AccountList'
import AddAccountModal from '../modals/auth/AddAccount'
import GlobalSearch from '../search/GlobalSearch'
import withRootPermissions from '../../mixins/withRootPermissions'

export default {
  props: {
    enterprise: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AccountList,
    GlobalSearch
  },
  mixins: [withRootPermissions],
  computed: {
    identities () {
      return this.$store.getters.getIdentities
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    },
    logoutFromAll () {
      this.$store.dispatch('logoutFromAll')
    },
    toggleMobileMenu () {
      this.$emit('toggle-mobile-menu')
    },
    addAccount () {
      this.$modal.open(AddAccountModal)
    }
  }
}
</script>
