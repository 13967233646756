<template>
  <div class="view-team-groups-manage">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <div class="alert alert-danger" v-if="errorType === 'internal'">
          <strong>Server error</strong>
          <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
        </div>

        <div class="alert alert-danger" v-if="errorType === 'forbidden'">
          <strong>Sorry,</strong>
          <p class="mb-0">You are not allowed to perform this action.</p>
        </div>

        <form action="" @submit.prevent="submit()">
          <div class="form-group row">
            <label for="inputName" class="col-sm-3 col-form-label">Name</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputName" v-model="form.friendly_name" :class="{ 'is-invalid': errors.friendly_name }">
              <div class="invalid-feedback" v-if="errors.friendly_name">{{ errors.friendly_name[0] }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputSysname" class="col-sm-3 col-form-label">System name</label>
            <div class="col-sm-9 col-lg-4">
              <input type="text" class="form-control" id="inputSysname" v-model="form.name" :class="{ 'is-invalid': errors.name }">
              <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
            </div>
          </div>

          <div class="form-group row mt-3">
            <div class="col-sm-9 col-lg-4 offset-sm-3">
              <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <group-members :enterprise-id="enterpriseId" :group-id="groupId"></group-members>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../../../api'
import SmallLoader from '../../../../components/common/SmallLoader'
import GroupMembers from '../../../../components/team/groups/Members'
import asyncProcess from '../../../../mixins/asyncProcess'
import withEnterprise from '../../../../mixins/withEnterprise'

export default {
  mixins: [asyncProcess, withEnterprise],
  components: {
    SmallLoader,
    GroupMembers
  },
  data () {
    return {
      isLoading: true,
      form: {
        friendly_name: '',
        name: ''
      }
    }
  },
  computed: {
    groupId () {
      return this.$route.params.groupId
    }
  },
  methods: {
    loadData () {
      api.usergroups.get({
        enterpriseId: this.enterpriseId,
        id: this.$route.params.groupId
      })
        .then((group) => {
          this.mapForm(group)
          this.isLoading = false
        })
    },
    mapForm (group) {
      this.form = _.assign({}, _.pick(group, ['friendly_name', 'name']))
    },
    submit () {
      this.startProcess()

      const payload = {
        ...this.form,
        id: this.$route.params.groupId,
        enterpriseId: this.enterpriseId
      }

      api.usergroups.update(payload)
        .then(() => {
          this.endProcess()
          this.$noty.success('User group updated.')
        })
        .catch(this.handleError)
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    $route: function () {
      this.loadData()
    }
  }
}
</script>
