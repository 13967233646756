<template>
  <div class="view-signup">
    <div class="logo-wrapper">
      <img src="../assets/images/logo-passbucket-320.png" alt="Passbucket | Cloud password manager" width="100" height="63">
    </div>

    <div class="plan-selector mt-5 mb-3" v-if="!selectedPlan">
      <div class="account-type-selector">
        <button type="button"
                class="btn btn-lg"
                :class="{ 'btn-primary': accountType === 'standard', 'btn-outline-primary': accountType !== 'standard' }"
                @click="setStandardAccount()">
          Standard account
        </button>

        <button type="button"
                class="btn btn-lg"
                :class="{ 'btn-primary': accountType === 'enterprise', 'btn-outline-primary': accountType !== 'enterprise' }"
                @click="setEnterpriseAccount()">
          Enterprise account
        </button>
      </div>

      <standard-plan-selector :guest="true" trial="30" v-if="accountType === 'standard'" @selected="handleSelectedPlan"></standard-plan-selector>
      <enterprise-plan-selector :guest="true" trial="30" v-if="accountType === 'enterprise'" @selected="handleSelectedPlan"></enterprise-plan-selector>
    </div>

    <div class="signup-details" v-if="selectedPlan">
      <div class="container small-container">
        <div class="card">
          <div class="card-body">
            <form action="" @submit.prevent="submit()">
              <div class="alert alert-danger" v-if="errorType === 'internal'">
                <strong>Server error</strong>
                <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
              </div>

              <h5 class="mb-4">Create your account</h5>

              <div class="form-group" v-if="selectedPlan === 'enterprise' || selectedPlan === 'small_business'">
                <input type="text" class="form-control" placeholder="Enterprise name" v-model="form.enterprise" :class="{ 'is-invalid': errors.enterprise }" @change="handleEnterpriseChange">
                <div class="invalid-feedback" v-if="errors.enterprise">{{ errors.enterprise[0] }}</div>
              </div>

              <div class="form-group">
                <input type="text" class="form-control" placeholder="Firstname" v-model="form.firstname" :class="{ 'is-invalid': errors.firstname }">
                <div class="invalid-feedback" v-if="errors.firstname">{{ errors.firstname[0] }}</div>
              </div>

              <div class="form-group">
                <input type="text" class="form-control" placeholder="Lastname" v-model="form.lastname" :class="{ 'is-invalid': errors.lastname }">
                <div class="invalid-feedback" v-if="errors.lastname">{{ errors.lastname[0] }}</div>
              </div>

              <div class="form-group">
                <input type="email" class="form-control" placeholder="Your email address" autocomplete="off" v-model="form.email" :class="{ 'is-invalid': errors.email }">
                <div class="invalid-feedback" v-if="errors.email">{{ errors.email[0] }}</div>
              </div>

              <div class="form-group">
                <input type="email" class="form-control" placeholder="Re-enter your email" autocomplete="off" v-model="form.email_confirm" :class="{ 'is-invalid': errors.email_confirm }">
                <div class="invalid-feedback" v-if="errors.email_confirm">{{ errors.email_confirm[0] }}</div>
              </div>

              <div class="form-group">
                <input type="password" class="form-control" placeholder="Choose a strong password" autocomplete="off" v-model="form.password" :class="{ 'is-invalid': errors.password }">
                <div class="invalid-feedback" v-if="errors.password">{{ errors.password[0] }}</div>
              </div>

              <div v-if="selectedPlan !== 'free'">
                <h5 class="mt-4 mb-4">Billing address</h5>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Organization (optional)" v-model="form.organization" :class="{ 'is-invalid': errors.organization }">
                  <div class="invalid-feedback" v-if="errors.organization">{{ errors.organization[0] }}</div>
                </div>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Address" v-model="form.address" :class="{ 'is-invalid': errors.address }">
                  <div class="invalid-feedback" v-if="errors.address">{{ errors.address[0] }}</div>
                </div>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Address (line 2)" v-model="form.address2" :class="{ 'is-invalid': errors.address2 }">
                  <div class="invalid-feedback" v-if="errors.address2">{{ errors.address2[0] }}</div>
                </div>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="City" v-model="form.city" :class="{ 'is-invalid': errors.city }">
                  <div class="invalid-feedback" v-if="errors.city">{{ errors.city[0] }}</div>
                </div>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Postal code" v-model="form.postalcode" :class="{ 'is-invalid': errors.postalcode }">
                  <div class="invalid-feedback" v-if="errors.postalcode">{{ errors.postalcode[0] }}</div>
                </div>

                <div class="form-group">
                  <country-selector v-model="form.iso_country" :is-invalid="errors.iso_country"></country-selector>
                  <div class="invalid-feedback" v-if="errors.iso_country">{{ errors.iso_country[0] }}</div>
                </div>

                <div class="form-group">
                  <state-selector v-model="form.iso_state" :is-invalid="errors.iso_state" :country="form.iso_country"></state-selector>
                  <div class="invalid-feedback" v-if="errors.iso_state">{{ errors.iso_state[0] }}</div>
                </div>

                <h5 class="mt-4 mb-3">Credit card information</h5>

                <p class="text-info mb-3 small">You will be charged only after your 30 days trial period.</p>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Credit card number" v-model="card.number" :class="{ 'is-invalid': errorType === 'card_error' }">
                  <div class="invalid-feedback" v-if="errorType === 'card_error'">Please check your credit card information</div>
                </div>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Name on credit card" v-model="card.name" :class="{ 'is-invalid': errorType === 'card_error' }">
                </div>

                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Security code" v-model="card.cvc" :class="{ 'is-invalid': errorType === 'card_error' }">
                </div>

                <div class="form-group row">
                  <div class="col-6">
                    <select class="form-control" v-model="card.exp_month" :class="{ 'is-invalid': errorType === 'card_error' }">
                      <option value="">MM</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <select class="form-control" v-model="card.exp_year" :class="{ 'is-invalid': errorType === 'card_error' }">
                      <option value="">YYYY</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-check mt-4">
                <input class="form-check-input" type="checkbox" id="checkTerms" v-model="termsAccepted">
                <label class="form-check-label" for="checkTerms">
                  I confirm that I have read and understood the entire <router-link :to="{ name: 'legal.terms' }" target="_blank">Terms of Use</router-link> and <router-link :to="{ name: 'legal.privacy' }" target="_blank">Privacy Policy</router-link> and agree to all statements.
                </label>
              </div>

              <div class="text-center mt-4">
                <button type="submit" class="btn btn-lg btn-primary submit-btn"  :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing || !termsAccepted" @click.prevent="submit()">
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import StandardPlanSelector from '../components/subscribe/StandardPlanSelector'
import EnterprisePlanSelector from '../components/subscribe/EnterprisePlanSelector'
import CountrySelector from '../components/common/CountrySelector'
import StateSelector from '../components/common/StateSelector'
import asyncProcess from '../mixins/asyncProcess'
import api from '../api'

const acceptedPlans = [
  'free',
  'personal',
  'premium',
  'small_business',
  'enterprise'
]

export default {
  mixins: [asyncProcess],
  data () {
    return {
      accountType: 'standard',
      selectedPlan: null,
      form: {
        enterprise: '',
        firstname: '',
        lastname: '',
        email: '',
        email_confirm: '',
        password: '',
        organization: '',
        address: '',
        address2: '',
        city: '',
        postalcode: '',
        iso_country: 'CA',
        iso_state: 'QC'
      },
      card: {
        number: '',
        name: '',
        cvc: '',
        exp_month: '',
        exp_year: ''
      },
      termsAccepted: false,
      isSuccess: false
    }
  },
  components: {
    CountrySelector,
    EnterprisePlanSelector,
    StandardPlanSelector,
    StateSelector
  },
  methods: {
    setStandardAccount () {
      this.accountType = 'standard'
    },
    setEnterpriseAccount () {
      this.accountType = 'enterprise'
    },
    handleSelectedPlan (plan) {
      this.selectedPlan = plan
      this.$router.replace({ query: { plan } })
    },
    handleEnterpriseChange () {
      this.form.organization = this.form.enterprise
    },
    async submit () {
      this.startProcess()

      try {
        const token = this.selectedPlan !== 'free'
          ? await this.getCardToken() : null

        const reqData = {
          ...this.form,
          credit_card: token,
          plan: this.selectedPlan
        }

        const response = await api.signup(reqData)

        this.$store.dispatch('setActiveUser', response)
        this.$store.dispatch('addIdentity', _.pick(response, ['uid', 'name', 'email', 'entity', 'token']))

        const redirectPath = this.$route.query && this.$route.query.redirect ? this.$route.query.redirect : '/'

        this.$router.push(redirectPath)
      } catch (error) {
        if (error === 'card_error') {
          this.$noty.error('Can not process your credit card.')
          this.errorType = 'card_error'
          this.endProcess()
        } else {
          this.handleError(error)
        }
      }
    },
    getCardToken () {
      return new Promise((resolve, reject) => {
        /* eslint-disable no-undef */
        Stripe.card.createToken(this.card, (status, response) => {
          if (response.error) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('card_error')
          } else {
            resolve(response.id)
          }
        })
      })
    }
  },
  created () {
    if (
      this.$route.query &&
        this.$route.query.plan &&
        acceptedPlans.indexOf(this.$route.query.plan) > -1
    ) {
      this.selectedPlan = this.$route.query.plan
    }
  }
}
</script>
