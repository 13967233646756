<template>
  <sub-menu-layout
    :nav-items="navItems"
    :select-label="activeMenu"
    :header-title="headerTitle"
    :parent-label="parentLabel"
    :parent-link="parentLink">
    <router-view></router-view>
  </sub-menu-layout>
</template>

<script>
import SubMenuLayout from '../../components/layouts/SubMenuLayout'

const childRoutes = {
  'team.users.manage': {
    headerTitle: 'Manage user',
    parentLabel: 'Back to users',
    parentLink: { name: 'team.users' }
  },
  'team.groups.manage': {
    headerTitle: 'Manage group',
    parentLabel: 'Back to groups',
    parentLink: { name: 'team.groups' }
  }
}

export default {
  components: {
    SubMenuLayout
  },
  data () {
    return {
      bucket: {},
      navItems: [
        {
          name: 'Users',
          link: { name: 'team.users' }
        },
        {
          name: 'Groups',
          link: { name: 'team.groups' }
        },
        {
          name: 'Root permissions',
          link: { name: 'team.permissions' }
        },
        {
          name: 'Admins',
          link: { name: 'team.admins' }
        }
      ]
    }
  },
  computed: {
    activeMenu () {
      return this.$route.meta && this.$route.meta.selectorTitle
        ? this.$route.meta.selectorTitle : 'Users'
    },
    headerTitle () {
      return typeof childRoutes[this.$route.name] !== 'undefined'
        ? childRoutes[this.$route.name].headerTitle : 'Team management'
    },
    parentLabel () {
      return typeof childRoutes[this.$route.name] !== 'undefined'
        ? childRoutes[this.$route.name].parentLabel : ''
    },
    parentLink () {
      return typeof childRoutes[this.$route.name] !== 'undefined'
        ? childRoutes[this.$route.name].parentLink : ''
    }
  }
}
</script>
