<template>
  <div class="modal fade add-card-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add credit card</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" v-if="errorType === 'internal'">
              <strong>Server error</strong>
              <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'forbidden'">
              <strong>Sorry,</strong>
              <p class="mb-0">You are not allowed to perform this action.</p>
            </div>

            <div class="form-group">
              <label for="cardName" class="col-form-label">Card Holder</label>
              <input type="text" class="form-control" id="cardName" v-model="form.name">
            </div>

            <div class="form-group">
              <label for="cardNumber" class="col-form-label">Card Number</label>
              <input type="text" class="form-control" id="cardNumber" v-model="form.number">
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <label for="expMonth" class="col-form-label">Expiration</label>
                  </div>
                  <div class="expiration-fields">
                    <input type="text" class="form-control" id="expMonth" v-model="form.exp_month" placeholder="MM">
                    <input type="text" class="form-control" id="expYear" v-model="form.exp_year"  placeholder="YYYY">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cardCVC" class="col-form-label">Security code</label>
                  <input type="text" class="form-control" id="cardCVC" v-model="form.cvc">
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
              Save card
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '../../../api'

export default {
  data () {
    return {
      isLoading: false,
      errorType: null,
      errors: [],
      form: {
        number: '',
        name: '',
        cvc: '',
        exp_month: '',
        exp_year: ''
      },
      isProcessing: false
    }
  },
  computed: {
    ...mapGetters({
      subscriber: 'getSubscriber',
      subscriberId: 'getSubscriberId'
    })
  },
  methods: {
    async submit () {
      this.isProcessing = true

      try {
        const token = await this.getCardToken()
        await api.creditCards.create({ uid: this.subscriberId, card_token: token })
        this.done(true)
      } catch (error) {
        if (error === 'card_error') {
          this.$noty.error('Can not process your credit card.')
        } else {
          this.errorType = error.type
          this.errors = error.validationErrors
        }
        this.isProcessing = false
      }
    },
    getCardToken () {
      return new Promise((resolve, reject) => {
        /* eslint-disable no-undef */
        Stripe.card.createToken(this.form, (status, response) => {
          if (response.error) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('card_error')
          } else {
            resolve(response.id)
          }
        })
      })
    }
  }
}
</script>
