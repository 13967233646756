<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create user account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" v-if="errorType === 'internal'">
              <strong>Server error</strong>
              <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'forbidden'">
              <strong>Sorry,</strong>
              <p class="mb-0">You are not allowed to manage users.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'payment'">
              <strong>Limit exceeded</strong>
              <p class="mb-0">You have reached your capacity limits. Please consider upgrading your plan.</p>
            </div>

            <div class="form-group">
              <label for="user-firstname" class="col-form-label">Firstname</label>
              <input type="text" class="form-control" id="user-firstname" v-model="form.firstname" :class="{ 'is-invalid': errors.firstname }">
              <div class="invalid-feedback" v-if="errors.firstname">{{ errors.firstname[0] }}</div>
            </div>

            <div class="form-group">
              <label for="user-lastname" class="col-form-label">Lastname</label>
              <input type="text" class="form-control" id="user-lastname" v-model="form.lastname" :class="{ 'is-invalid': errors.lastname }">
              <div class="invalid-feedback" v-if="errors.lastname">{{ errors.lastname[0] }}</div>
            </div>

            <div class="form-group">
              <label for="user-email" class="col-form-label">Email</label>
              <input type="text" class="form-control" id="user-email" v-model="form.email" :class="{ 'is-invalid': errors.email }">
              <div class="invalid-feedback" v-if="errors.email">{{ errors.email[0] }}</div>
            </div>

            <div class="form-group">
              <label for="user-email-confirm" class="col-form-label">Confirm email</label>
              <input type="text" class="form-control" id="user-email-confirm" v-model="form.email_confirm" :class="{ 'is-invalid': errors.email_confirm }">
              <div class="invalid-feedback" v-if="errors.email_confirm">{{ errors.email_confirm[0] }}</div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
              Create account
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from '../../../../api'
import asyncProcess from '../../../../mixins/asyncProcess'
import withEnterprise from '../../../../mixins/withEnterprise'

export default {
  mixins: [asyncProcess, withEnterprise],
  data () {
    return {
      isLoading: false,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        email_confirm: ''
      }
    }
  },
  methods: {
    submit () {
      this.startProcess()

      const payload = {
        ...this.form,
        enterpriseId: this.enterpriseId
      }

      api.users.create(payload)
        .then((user) => {
          this.done(user)
        })
        .catch(this.handleError)
    }
  }
}
</script>
