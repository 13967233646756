<template>
  <div class="modal-dialog" role="document">
    <form action="" @submit.prevent="submit()">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" v-if="errorType === 'internal'">
            <strong>Server error</strong>
            <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
          </div>

          <div class="alert alert-danger" v-if="errorType === 'forbidden'">
            <strong>Access denied</strong>
            <p class="mb-0">You are not allowed to edit this entry.</p>
          </div>

          <div class="alert alert-danger" v-if="errorType === 'not_found'">
            <strong>Error</strong>
            <p class="mb-0">Object not found.</p>
          </div>

          <note-form v-model="form" :errors="errors" v-if="type === 'note'"></note-form>
          <password-form v-model="form" :errors="errors" v-if="type === 'password'"></password-form>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
            Save
          </button>
          <button type="button" class="btn btn-secondary" @click="cancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../../../api'
import createEditEntryMixin from '../../../../mixins/entries/createEdit'

export default {
  mixins: [createEditEntryMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    entry: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    bucket: {
      type: String,
      required: true
    }
  },
  computed: {
    modalTitle () {
      return this.entryType === 'note' ? 'Edit note' : 'Edit password'
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      this.startProcess()

      const encrypted = this.getEncryptedPayload(this.form)

      const payload = _.assign({}, encrypted, {
        id: this.id,
        bucketId: this.bucket
      })

      api.entries.update(payload)
        .then((entry) => {
          this.$store.commit('BUCKET_ENTRIES_UPDATED', entry)
          this.$emit('updated')
        })
        .catch(this.handleError)
    }
  },
  created () {
    this.form = _.assign({}, this.entry, {
      confirm_password: this.entry.password
    })
  }
}
</script>
