import $ from 'jquery'

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    open () {
      // TODO: replace jquery modal script by a native Vuejs feature
      $(this.$el).modal('show')
    },
    hide () {
      // TODO: replace jquery modal script by a native Vuejs feature
      $(this.$el).modal('hide')
    },
    close () {
      this.$modal.close()
    },
    handleState (value) {
      if (value === true) {
        this.open()
      } else {
        this.hide()
      }
    },
    detach () {
      this.$modal.detach()
    },
    done (data = null) {
      this.$modal.triggerOnDone(data)
      this.close()
    }
  },
  watch: {
    visible (newValue) {
      this.handleState(newValue)
    }
  },
  mounted () {
    // TODO: replace jquery modal script by a native Vuejs feature

    $(this.$el).modal()

    $(this.$el).on('hide.bs.modal', (e) => {
      this.close()
    })

    $(this.$el).on('hidden.bs.modal', (e) => {
      this.detach()
    })

    this.handleState(this.visible)
  }
}
