<template>
  <div class="comp-quick-filter input-with-icon right-icon">
    <span class="icon icon-magnifying-glass" v-if="!hasText"></span>
    <span class="icon icon-cross" v-else @click="clear()"></span>
    <input type="text" class="form-control" :placeholder="placeholder" v-model="text" @keydown.esc="clear()">
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Filter list'
    }
  },
  data () {
    return {
      text: this.value
    }
  },
  computed: {
    hasText () {
      return this.text.length > 0
    }
  },
  methods: {
    clear () {
      this.text = ''
    }
  },
  watch: {
    text (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style type="scss" scoped>
  .icon-cross {
    font-size: 16px;
    cursor: pointer;
  }
</style>
