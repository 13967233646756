<template>
  <tr>
    <td>{{ date }}</td>
    <td><router-link :to="{ name: 'billing.invoices.details', params: { id: invoice.id } }">{{ invoice.reference }}</router-link></td>
    <td>{{ totalAmount }}</td>
    <td>{{ status }}</td>
  </tr>
</template>

<script>
import moment from 'moment'
import numeral from 'numeraljs'

export default {
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  computed: {
    date () {
      return moment.unix(this.invoice.date).format('YYYY-MM-DD')
    },
    totalAmount () {
      return numeral(this.invoice.total).format('$0,0.00')
    },
    status () {
      return this.invoice.paid ? 'PAID' : 'DUE'
    }
  }
}
</script>
