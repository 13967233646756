<template>
  <div class="app-interface" :class="{ 'enterprise-member': isEnterpriseMember }">
    <div v-if="loaded && !switchingAccount">
      <nav-bar @toggle-mobile-menu="toggleOffcanvas()" :enterprise="isEnterpriseMember"></nav-bar>
      <offcanvas-menu :open="offcanvasOpened" @close="closeOffcanvas()" :enterprise="isEnterpriseMember"></offcanvas-menu>
      <router-view></router-view>
    </div>
    <app-loader v-else></app-loader>
  </div>
</template>

<script>
import config from '../config'
import NavBar from '../components/common/NavBar'
import AppLoader from '../components/common/AppLoader'
import OffcanvasMenu from '../components/common/OffcanvasMenu'
import withEnterprise from '../mixins/withEnterprise'

export default {
  name: 'app-interface',
  mixins: [withEnterprise],
  components: {
    NavBar,
    AppLoader,
    OffcanvasMenu
  },
  data () {
    return {
      loaded: false,
      offcanvasOpened: false
    }
  },
  computed: {
    switchingAccount () {
      return this.$store.state.auth.switching
    }
  },
  methods: {
    toggleOffcanvas () {
      this.offcanvasOpened = !this.offcanvasOpened
    },
    closeOffcanvas () {
      this.offcanvasOpened = false
    },
    fetchData () {
      const promises = [
        this.fetchUserData(),
        this.$store.dispatch('fetchBuckets'),
        this.$store.dispatch('fetchBucketBookmarks'),
        this.$store.dispatch('fetchBucketArchives')
      ]

      Promise.all(promises)
        .then(() => {
          this.loaded = true
        })
    },
    fetchUserData () {
      // Fetch user base profile
      // Then, if user is an enterprise member, fetch its root permissions

      return new Promise((resolve, reject) => {
        this.$store.dispatch('fetchUserInfo')
          .then(() => {
            const enterpriseId = this.$store.getters.getEnterpriseId

            if (enterpriseId) {
              this.$store.dispatch('fetchRootPermissions')
                .then(resolve)
                .catch(reject)
            } else {
              resolve()
            }
          })
          .catch(reject)
      })
    },
    handleTokenRefresh () {
      if (!config.auth.jwtRefreshEnabled) {
        return
      }

      setInterval(() => {
        this.$store.dispatch('refreshToken')
      }, 120000)
    }
  },
  created () {
    this.fetchData()
    this.handleTokenRefresh()
  },
  watch: {
    $route () {
      this.offcanvasOpened = false
    }
  }
}
</script>
