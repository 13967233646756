import CryptoJS from 'crypto-js'

export default {
  decrypt (encryptedPayload, sek) {
    const key = CryptoJS.enc.Utf8.parse(sek)

    const parsedPayload = CryptoJS.enc.Base64.parse(encryptedPayload).toString(CryptoJS.enc.Utf8)
    const jsonPayload = JSON.parse(parsedPayload)
    const payloadIV = CryptoJS.enc.Base64.parse(jsonPayload.iv)
    const payloadValue = CryptoJS.enc.Base64.parse(jsonPayload.value)

    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: payloadValue
    })

    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
      iv: payloadIV
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
  },

  encrypt (plaintext, sek) {
    const encrypted = CryptoJS.AES.encrypt(plaintext, sek, {
      format: {
        stringify (cipherParams) {
          // create json object with ciphertext
          const jsonObj = {
            value: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
          }

          // optionally add iv and salt
          if (cipherParams.iv) {
            jsonObj.iv = cipherParams.iv.toString(CryptoJS.enc.Base64)
          }

          if (cipherParams.salt) {
            jsonObj.salt = cipherParams.salt.toString()
          }

          // stringify json object
          return JSON.stringify(jsonObj)
        }
      }
    })

    const encryptedString = encrypted.toString()
    const base64payload = btoa(encryptedString)

    return base64payload
  },

  hash (string) {
    return CryptoJS.SHA256(string).toString()
  }
}
