<template>
  <div class="comp-note-view">
    <div class="form-group">
      <textarea class="form-control" rows="13" readonly v-model="entry.text"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true
    }
  }
}
</script>
