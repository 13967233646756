<template>
  <div class="offcanvas-menu" :class="{ open: open }">
    <account-list v-if="identities.length > 0"></account-list>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" v-if="enterprise && canAccessTeamMenu">
        <router-link :to="{ name: 'team.users' }">Manage Team</router-link>
      </li>
      <li class="list-group-item">
        <a href="" @click.prevent="addAccount()">Add existing account</a>
      </li>
      <li class="list-group-item" v-if="identities.length > 1">
        <a href="" @click.prevent="$store.dispatch('logoutFromAll')">Sign out from all accounts</a>
      </li>
      <li class="list-group-item" v-else>
        <a href="" @click.prevent="$store.dispatch('logout')">Sign out</a>
      </li>
    </ul>
  </div>
</template>

<script>
import AccountList from '../auth/AccountList'
import AddAccountModal from '../modals/auth/AddAccount'
import withRootPermissions from '../../mixins/withRootPermissions'

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    enterprise: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AccountList
  },
  mixins: [withRootPermissions],
  computed: {
    identities () {
      return this.$store.getters.getIdentities
    }
  },
  methods: {
    addAccount () {
      this.$modal.open(AddAccountModal)
      this.$emit('close')
    }
  }
}
</script>
