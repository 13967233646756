export default {

  host: process.env.VUE_APP_API_HOST || '',
  path: process.env.VUE_APP_API_PATH || '/',

  grantType: process.env.VUE_APP_GRANT_TYPE || 'password',

  clientId: process.env.VUE_APP_CLIENT_ID || '',
  clientSecret: process.env.VUE_APP_CLIENT_SECRET || ''

}
