<template>
  <div class="subscription-alert">
    <div v-if="shouldDisplay" class="card mb-5">
      <div class="card-body">
        <h5 class="card-title">
          <fa-icon icon="exclamation-triangle" size="lg"></fa-icon>
          Your license has expired
        </h5>
        <p class="card-text">Your account is now in grace period.
          <br>To avoid any temporary suspension, please choose a subscription now. <span v-if="!isEnterpriseMember">A free plan is still available for individual use!</span></p>
        <router-link :to="{ name: 'subscribe' }" class="card-link">Choose your plan now</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import withEnterprise from '../../mixins/withEnterprise'
import withRootPermissions from '../../mixins/withRootPermissions'

export default {
  mixins: [
    withEnterprise,
    withRootPermissions
  ],
  computed: {
    isFetchComplete () {
      return this.$store.state.subscription.loaded
    },
    hasValidSubscription () {
      return this.$store.state.subscription.hasValidSubscription
    },
    shouldDisplay () {
      if (!this.isEnterpriseMember) {
        return this.isFetchComplete && !this.hasValidSubscription
      }

      return this.isFetchComplete && !this.hasValidSubscription && this.canManageBilling
    }
  },
  methods: {
    fetchDataIfNeeded () {
      if (!this.isFetchComplete) {
        this.$store.dispatch('searchValidSubscription')
      }
    }
  },
  created () {
    this.fetchDataIfNeeded()
  }
}
</script>
