import _ from 'lodash'
import Vue from 'vue'
import api from '../api'
import config from '../config'
import router from '../router'

export default {
  state: {
    authenticated: false,
    user: {
      uid: null
    },
    sek: null,
    tfaUserToken: null,
    identities: [],
    switching: false,
    permissions: []
  },

  mutations: {
    USER_AUTHENTICATED: (state) => {
      state.authenticated = true
    },

    SET_USER: (state, user) => {
      state.user = user
    },

    USER_LOGGED_OUT: (state) => {
      state.authenticated = false
    },

    SET_SEK: (state, sek) => {
      state.sek = sek
    },

    SET_TFA_USER_TOKEN: (state, token) => {
      state.tfaUserToken = token
    },

    SET_IDENTITIES: (state, collection) => {
      state.identities = collection
    },

    ADD_IDENTITY: (state, object) => {
      // If identity already registered, replace entry
      state.identities = _.reject(state.identities, { uid: object.uid })
      state.identities.push(object)
    },

    CLEAR_IDENTITIES: (state) => {
      state.identities = []
    },

    REMOVE_IDENTITY: (state, uid) => {
      state.identities = _.reject(state.identities, { uid })
    },

    IDENTITY_TOKEN_CHANGED: (state, { uid, token }) => {
      const identity = _.find(state.identities, { uid })

      if (identity) {
        const index = state.identities.indexOf(identity)

        state.identities.splice(index, 1, {
          ...identity,
          token
        })
      }
    },

    SWITCH_START: (state) => {
      state.switching = true
    },

    SET_ROOT_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  getters: {
    getIdentities (state) {
      const identities = _.clone(state.identities)
      const list = []

      const activeIdentity = _.find(identities, { uid: state.user.uid })

      if (activeIdentity) {
        identities.splice(identities.indexOf(activeIdentity), 1)
        list.push(_.assign({}, activeIdentity, {
          isActive: true
        }))
      }

      identities.forEach((obj) => {
        list.push(obj)
      })

      return list
    },
    isEnterpriseMember (state) {
      return !!state.user.enterprise
    },
    getEnterpriseName (state) {
      return state.user.enterprise && state.user.enterprise.name
        ? state.user.enterprise.name : null
    },
    getEnterpriseId (state) {
      return state.user.enterprise ? state.user.enterprise.uid : null
    },
    getEnterprise (state) {
      return state.user.enterprise ? state.user.enterprise : {}
    },
    getRootPermissions (state) {
      return state.permissions
    }
  },

  actions: {
    fetchUserInfo ({ commit }) {
      return api.currentUser.get()
        .then((user) => {
          commit('SET_USER', user)
        })
    },

    fetchRootPermissions ({ commit, getters }) {
      return api.permissions.getUserPermissions({
        enterpriseId: getters.getEnterpriseId,
        model: 'enterprise',
        objectId: getters.getEnterpriseId
      })
        .then((permissions) => {
          commit('SET_ROOT_PERMISSIONS', permissions)
        })
    },

    login ({ commit, dispatch }, credentials) {
      return api.getAuthToken(credentials)
        .then((response) => {
          dispatch('setActiveUser', response)
          dispatch('addIdentity', _.pick(response, ['uid', 'name', 'email', 'entity', 'token']))
        })
    },

    verifyTFACode ({ commit, dispatch }, payload) {
      return api.postTFACode(payload)
        .then((response) => {
          dispatch('setActiveUser', response)
          dispatch('addIdentity', _.pick(response, ['uid', 'name', 'email', 'entity', 'token']))
        })
    },

    setActiveUser ({ commit, dispatch }, identity) {
      localStorage.setItem(config.auth.tokenKeyName, identity.token)

      commit('USER_AUTHENTICATED')

      commit('SET_USER', {
        uid: identity.uid
      })

      const payload = Vue.$jwt.decode(identity.token)

      if (payload) {
        commit('SET_SEK', payload.sek)
      }
    },

    forgotPassword ({ commit }, payload) {
      return api.forgotPassword(payload)
    },

    resetPassword ({ commit }, payload) {
      return api.resetPassword(payload)
    },

    logout ({ commit, getters, dispatch }) {
      const currentToken = localStorage.getItem(config.auth.tokenKeyName)

      if (currentToken) {
        const identity = _.find(getters.getIdentities, { token: currentToken })

        if (identity) {
          dispatch('removeIdentity', identity.uid)
        }
      }

      // TODO: revoke token
      localStorage.removeItem(config.auth.tokenKeyName)
      router.push(config.auth.guestRedirectPath)
      commit('USER_LOGGED_OUT')
    },

    logoutFromAll ({ commit, dispatch }) {
      dispatch('clearIdentities')

      localStorage.removeItem(config.auth.tokenKeyName)
      localStorage.removeItem(config.auth.profileKeyName)

      router.push(config.auth.guestRedirectPath)

      commit('USER_LOGGED_OUT')
    },

    loadIdentities ({ commit }) {
      const identities = localStorage.getItem(config.auth.profileKeyName)

      if (identities) {
        commit('SET_IDENTITIES', JSON.parse(identities))
      }
    },

    addIdentity ({ commit, dispatch }, payload) {
      commit('ADD_IDENTITY', payload)
      dispatch('storeIdentities')
    },

    removeIdentity ({ commit, dispatch }, uid) {
      commit('REMOVE_IDENTITY', uid)
      dispatch('storeIdentities')
    },

    clearIdentities ({ commit, dispatch }) {
      commit('CLEAR_IDENTITIES')
      dispatch('storeIdentities')
    },

    storeIdentities ({ state }) {
      const identities = JSON.stringify(state.identities)
      localStorage.setItem(config.auth.profileKeyName, identities)
    },

    switchIdentity ({ dispatch, commit }, identity) {
      commit('SWITCH_START')

      dispatch('setActiveUser', identity)

      dispatch('refreshToken')
        .then(() => {
          window.location.href = config.auth.memberRedirectPath
        })
        .catch(() => {
          // TODO: handle refresh token error
        })
    },

    handleUnauthorizedError ({ getters, dispatch }) {
      const expiredToken = localStorage.getItem(config.auth.tokenKeyName)

      if (expiredToken) {
        const identity = _.find(getters.getIdentities, { token: expiredToken })

        if (identity) {
          dispatch('removeIdentity', identity.uid)
        }
      }

      localStorage.removeItem(config.auth.tokenKeyName)
      router.push(config.auth.guestRedirectPath)
    },

    refreshToken ({ dispatch, state }) {
      return api.refreshToken()
        .then((response) => {
          const newToken = response.token

          localStorage.setItem(config.auth.tokenKeyName, newToken)

          dispatch('updateProfileToken', {
            uid: state.user.uid,
            token: newToken
          })
        })
    },

    updateProfileToken ({ commit, dispatch }, { uid, token }) {
      commit('IDENTITY_TOKEN_CHANGED', { uid, token })
      dispatch('storeIdentities')
    }
  }
}
