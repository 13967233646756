<template>
  <div class="bucket-view">
    <div class="mobile-header container mt-3 mb-3 d-block d-sm-none">
      <div class="float-right settings-icon" v-if="!bucket.is_system">
        <a href="" v-if="isEnterpriseMember" @click.prevent="managePermissions()">
          <fa-icon icon="users"></fa-icon>
        </a>

        <router-link :to="{ name: 'bucket.settings', params: { id: bucket.uid } }">
          <fa-icon icon="cog"></fa-icon>
        </router-link>
      </div>

      <h3>{{ bucket.name }}</h3>
    </div>

    <div class="list-selector mt-3 d-block d-sm-none" @click="toggleListSelector()" v-if="!loadingLists">
      <div class="container">
        {{ activeListName }}
        <span v-if="!displayListSelector">
          <fa-icon icon="caret-down"></fa-icon>
        </span>
        <span v-else>
          <fa-icon icon="caret-up"></fa-icon>
        </span>
      </div>
    </div>

    <div class="container relative-container">
      <div class="sidebar transition" :class="{ 'hidden-mobile': !displayListSelector }">
        <div v-if="loadingLists">
          <small-loader></small-loader>
        </div>
        <div class="new-list-btn" v-if="!loadingLists">
          <a href="" @click.prevent="createList()">
            <span class="icon">
              <fa-icon icon="plus"></fa-icon>
            </span>
            Create new list
          </a>
        </div>
        <ul class="nav nav-bordered nav-stacked flex-column lists-list" v-if="!loadingLists && lists.length">
          <li class="nav-item">
            <router-link class="nav-link" :class="{ active: !activeList }"
                         :to="{ name: 'bucket.view', params: { id: bucket.uid } }">
              All passwords
            </router-link>
          </li>
          <li class="nav-item" v-for="list in sortedLists" :key="list.uid">
            <router-link class="nav-link" :class="{ active: activeList === list.uid }"
                         :to="{ name: 'bucket.view', params: { id: bucket.uid, listId: list.uid } }">
              <div class="float-right list-actions">
                <a href="" v-if="isEnterpriseMember && !isSystemBucket" @click.prevent.stop="manageListPermissions(list)" class="settings-icon">
                  <fa-icon icon="users"></fa-icon>
                </a>

                <a href="" @click.prevent.stop="showListSettings(list)" class="settings-icon">
                  <fa-icon :icon="['fas', 'cog']"></fa-icon>
                </a>
              </div>
              {{ list.name }}
            </router-link>
          </li>
        </ul>
        <div class="no-list" v-if="!loadingLists && !lists.length" @click="createList()">
          Here, you can create lists to organize your passwords and notes.
        </div>
      </div>

      <div class="main-content mt-3">
        <div class="heading d-none d-sm-block mb-3">
          <div class="float-right settings-icon" v-if="!bucket.is_system">
            <a href="" v-if="isEnterpriseMember" @click.prevent="managePermissions()">
              <fa-icon icon="users"></fa-icon>
            </a>

            <router-link :to="{ name: 'bucket.settings', params: { id: bucket.uid } }">
              <fa-icon icon="cog"></fa-icon>
            </router-link>
          </div>

          <div class="dashhead nosub">
            <div class="dashhead-titles">
              <h3 class="dashhead-title">{{ bucket.name }}</h3>
            </div>
          </div>
        </div>

        <div v-if="loadingEntries">
          <small-loader></small-loader>
        </div>

        <div class="toolbar" v-if="!loadingEntries && entries.length > 0">
          <div class="row justify-content-between">
            <div class="col-6 col-sm-5 col-md-4">
              <quick-filter v-model="nameFilter"></quick-filter>
            </div>
            <div class="col-6 col-sm-7 col-md-8 text-right">
              <div class="btn-toolbar justify-content-end">
                <div class="btn-group mr-2 d-none d-md-inline-flex" v-if="hasSelection">
                  <div class="btn-group">
                    <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" v-tooltip="'Move to list'">
                      <fa-icon icon="list"></fa-icon>
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="" @click.prevent="moveSelectedToList(list)" v-for="list in sortedLists" :key="list.uid">
                        {{ list.name }}
                      </a>
                    </div>
                  </div>

                  <div class="btn-group">
                    <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" v-tooltip="'Move to bucket'">
                      <span class="icon icon-bucket"></span>
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="" @click.prevent="moveSelectedToBucket(bucket)" v-for="bucket in buckets" :key="bucket.uid">
                        {{ bucket.name }}
                      </a>
                    </div>
                  </div>

                  <button class="btn btn-outline-secondary" v-if="false" v-tooltip="'Transfer ownership'">
                    <fa-icon icon="share"></fa-icon>
                  </button>
                </div>

                <div class="btn-group mr-3 d-none d-md-inline-flex" v-if="hasSelection">
                  <button class="btn btn-outline-secondary" @click="bulkDelete()" v-tooltip="'Delete'">
                    <fa-icon icon="trash"></fa-icon>
                  </button>
                </div>

                <div class="btn-group mr-3 d-inline-flex d-md-none" v-if="hasSelection">
                  <div class="btn-group">
                    <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">
                      Manage...
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="" @click.prevent="selectTargetList()">Move to list</a>
                      <a class="dropdown-item" href="" @click.prevent="selectTargetBucket()">Move to bucket</a>
                      <a class="dropdown-item" href="" @click.prevent="bulkDelete()">Delete selected</a>
                    </div>
                  </div>
                </div>

                <div class="btn-group">
                  <div class="dropdown no-caret dropdown-create-entry">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <fa-icon icon="plus"></fa-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="" @click.prevent="createPassword()">
                        <span class="icon icon-key"></span>
                        Password
                      </a>
                      <a class="dropdown-item" href="" @click.prevent="createNote()">
                        <span class="icon">
                          <fa-icon icon="file-alt"></fa-icon>
                        </span>
                        Note
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card pb-table-card mt-3" v-if="!loadingEntries && sortedEntries.length">
          <div class="card-body">
            <div class="pb-table-list">
              <table class="table bucket-list-table">
                <tbody>
                <tr class="d-flex" v-for="entry in sortedEntries" :class="{ 'row-selected': selectedItems.indexOf(entry.uid) > -1 }" :key="entry.uid">
                  <td class="col-checkbox">
                    <input type="checkbox" :checked="selectedItems.indexOf(entry.uid) > -1" @change="toggleSelect(entry.uid)">
                  </td>
                  <td class="col-icon">
                    <span v-if="entry.type === 'note'">
                      <fa-icon icon="file-alt"></fa-icon>
                    </span>
                    <span v-if="entry.type === 'password'" class="icon icon-key"></span>
                  </td>
                  <td class="col text-truncate">
                    <a href="" @click.prevent="openEntry(entry)">
                      {{ entry.name }}
                    </a>
                  </td>
                  <td class="d-none d-sm-block text-right">
                    <div class="label label-primary" v-if="entry.password_list">
                      {{ entry.password_list.name }}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="showNoEntryText" class="no-result">
          <p>No entry here.</p>
          <p>You can create a <a href="" @click.prevent="createPassword()">password</a> or a <a href="" @click.prevent="createNote()">note</a>.</p>
        </div>

        <div v-if="showNoResultText" class="no-result">
          Nothing found.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SmallLoader from '../../../components/common/SmallLoader'
import QuickFilter from '../../../components/common/QuickFilter'
import CreateListModal from '../../../components/modals/lists/Create'
import ShowEntryModal from '../../../components/modals/entries/Show'
import CreateEntryModal from '../../../components/modals/entries/Create'
import BulkDeleteModal from '../../../components/modals/entries/BulkDelete'
import SelectListModal from '../../../components/modals/lists/Select'
import EditListModal from '../../../components/modals/lists/Edit'
import SelectBucketModal from '../../../components/modals/buckets/Select'
import withEnterprise from '../../../mixins/withEnterprise'
import ManagePermissionsModal from '../../../components/modals/permissions/Manage'

export default {
  name: 'bucket-view',
  components: {
    SmallLoader,
    QuickFilter
  },
  mixins: [withEnterprise],
  data () {
    return {
      displayListSelector: false,
      nameFilter: ''
    }
  },
  computed: {
    bucket () {
      return this.$store.state.currentBucket.bucket
    },
    isSystemBucket () {
      return this.bucket.is_system
    },
    lists () {
      return this.$store.state.currentBucket.lists
    },
    sortedLists () {
      return _.orderBy(this.lists, [o => o.name.toLowerCase()], ['asc'])
    },
    entries () {
      return this.$store.state.currentBucket.entries
    },
    filteredEntries () {
      if (this.nameFilter.length > 0) {
        return _.filter(
          this.entries,
          o => o.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
        )
      }
      return this.entries
    },
    sortedEntries () {
      return _.orderBy(this.filteredEntries, [o => o.name.toLowerCase()], ['asc'])
    },
    loadingLists () {
      return this.$store.state.currentBucket.loadingLists
    },
    loadingEntries () {
      return this.$store.state.currentBucket.loadingEntries
    },
    activeList () {
      return this.$store.state.currentBucket.activeList
    },
    activeListName () {
      if (this.activeList) {
        const list = _.find(this.lists, { uid: this.activeList })
        if (list.name) {
          return list.name
        }
      }

      return 'All passwords'
    },
    selectedItems () {
      return this.$store.state.currentBucket.selectedItems
    },
    hasSelection () {
      return this.selectedItems.length > 0
    },
    buckets () {
      return _.orderBy(this.$store.state.buckets.list, [o => o.name.toLowerCase()], ['asc'])
    },
    showNoResultText () {
      return this.nameFilter.length > 0 && this.filteredEntries.length === 0 && !this.loadingEntries
    },
    showNoEntryText () {
      return this.nameFilter.length === 0 &&
          this.filteredEntries.length === 0 &&
          !this.loadingEntries
    }
  },
  methods: {
    toggleSelect (uid) {
      this.$store.dispatch('toggleSelectEntry', uid)
    },
    toggleListSelector () {
      this.displayListSelector = !this.displayListSelector
    },
    createList () {
      this.$modal.open(CreateListModal)
    },
    handleRouteChange () {
      const listId = this.$route.params.listId || ''

      if (listId !== this.activeList) {
        this.displayListSelector = false

        if (listId) {
          this.$store.dispatch('fetchListEntries', listId)
        } else {
          this.$store.dispatch('fetchEntries')
        }
      }
    },
    openEntry (entry) {
      this.$modal.open(ShowEntryModal, {
        uid: entry.uid,
        bucket: this.bucket.uid
      })
    },
    createPassword () {
      this.createEntry('password')
    },
    createNote () {
      this.createEntry('note')
    },
    createEntry (type) {
      this.$modal.open(CreateEntryModal, {
        type,
        bucket: this.bucket.uid,
        list: this.activeList
      })
        .done((entry) => {
          this.$store.commit('BUCKET_ENTRIES_CREATED', entry)
        })
    },
    bulkDelete () {
      this.$modal.open(BulkDeleteModal)
        .done((confirmed) => {
          if (confirmed === true) {
            this.$store.dispatch('deleteSelectedEntries')
          }
        })
    },
    moveSelectedToList (list) {
      this.$store.dispatch('moveSelectedEntriesToList', list)
    },
    moveSelectedToBucket (bucket) {
      this.$store.dispatch('moveSelectedEntriesToBucket', bucket)
    },
    selectTargetList () {
      this.$modal.open(SelectListModal)
        .done((listId) => {
          const list = _.find(this.lists, { uid: listId })
          this.$store.dispatch('moveSelectedEntriesToList', list)
        })
    },
    selectTargetBucket () {
      this.$modal.open(SelectBucketModal)
        .done((bucketId) => {
          const bucket = _.find(this.buckets, { uid: bucketId })
          this.$store.dispatch('moveSelectedEntriesToBucket', bucket)
        })
    },
    showListSettings (list) {
      this.$modal.open(EditListModal, {
        list
      })
    },
    managePermissions () {
      this.$modal.open(ManagePermissionsModal, {
        model: 'bucket',
        objectId: this.bucket.uid
      })
    },
    manageListPermissions (list) {
      this.$modal.open(ManagePermissionsModal, {
        model: 'list',
        objectId: list.uid
      })
    }
  },
  created () {
    const bucketId = this.$route.params.id
    const listId = this.$route.params.listId || ''

    this.$store.dispatch('loadBucket', {
      bucketId,
      listId
    })
  },
  watch: {
    $route: 'handleRouteChange'
  }
}
</script>
