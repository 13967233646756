import { mapGetters } from 'vuex'
import AccessDenied from '../components/common/AccessDenied'

export default {
  components: {
    AccessDenied
  },
  computed: {
    ...mapGetters({
      rootPermissions: 'getRootPermissions'
    }),
    canManageBilling () {
      return this.rootPermissions.indexOf('manage_billing') > -1
    },
    canManageUsers () {
      return this.rootPermissions.indexOf('manage_users') > -1
    },
    canManagePermissions () {
      return this.rootPermissions.indexOf('manage_permissions') > -1
    },
    canAccessTeamMenu () {
      return this.canManageUsers || this.canManagePermissions
    },
    canCreateBucket () {
      return this.rootPermissions.indexOf('create_bucket') > -1
    }
  }
}
