<template>
  <div class="view-login auth-layout">
    <div class="logo-wrapper">
      <img src="../../assets/images/logo-passbucket-320.png" alt="Passbucket | Cloud password manager" width="250" height="157">
    </div>

    <div class="login-box">
      <div class="block">
        <div class="block-body">
          <form action="" @submit.prevent="submit()">
            <div class="alert alert-danger" role="alert" v-if="hasError">
              <strong>Access denied!</strong>
              <p class="mb-0">Please check your username and password.</p>
            </div>

            <div class="form-group">
              <input type="email" class="form-control" placeholder="Email" name="email" autocomplete="off" v-model="form.email">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" placeholder="Password" name="password" autocomplete="off" v-model="form.password">
            </div>

            <div class="mt-1 mb-2 right-link">
              <small><router-link :to="{ name: 'auth.forgot-password' }">Forgot password?</router-link></small>
            </div>

            <div class="text-center">
              <button type="submit" class="btn btn-lg btn-primary submit-btn"
                      :class="{ 'loading-btn': isProcessing }"
                      :disabled="formDisabled">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="mt-3 text-center secondary-link">
        New user? <router-link :to="{ name: 'signup' }">Create your account</router-link>.
      </div>
    </div>

    <div class="page-footer">
      &copy; {{ currentYear }} Passbucket, Inc. | <router-link :to="{ name: 'legal.terms' }" target="_blank">Terms of Use</router-link> | <router-link :to="{ name: 'legal.privacy' }" target="_blank">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'login-page',
  data () {
    return {
      isProcessing: false,
      form: {
        email: '',
        password: ''
      },
      hasError: false,
      error: {},
      currentYear: moment().format('YYYY')
    }
  },
  computed: {
    formDisabled () {
      return this.form.email.length === 0 || this.form.password.length === 0
    }
  },
  methods: {
    submit () {
      this.isProcessing = true
      this.hasError = false

      const redirectPath = this.$route.query && this.$route.query.redirect ? this.$route.query.redirect : '/'

      this.$store.dispatch('login', this.form)
        .then(() => {
          this.isProcessing = false
          this.$router.push(redirectPath)
        })
        .catch((error) => {
          // Handle 2-factor auth
          if (_.get(error, 'response.data.error') === 'tfa_required') {
            const tfaUserToken = _.get(error, 'response.data.user_token')

            this.$store.commit('SET_TFA_USER_TOKEN', tfaUserToken)

            const query = redirectPath.length > 1 ? { redirect: redirectPath } : null

            this.$router.replace({
              name: 'auth.two-factor',
              query
            })
          }

          this.isProcessing = false
          this.hasError = true
          this.error = error

          if (error.type === 'unauthorized') {
            this.form.password = ''
          }
        })
    }
  }
}
</script>
