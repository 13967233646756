<template>
  <div class="sub-menu-layout">
    <div class="mobile-header container mt-3 mb-3 d-block d-sm-none" v-if="headerTitle">
      <div class="mb-2" v-if="parentLabel">
        <router-link :to="parentLink" v-if="parentLink">{{ parentLabel }}</router-link>
        <span v-else>{{ parentLabel }}</span>
      </div>

      <h3>{{ headerTitle }}</h3>
    </div>

    <div class="menu-selector mt-3 d-block d-sm-none" @click="toggleSelector()">
      <div class="container">
        {{ selectLabel || 'Browse...' }}
        <fa-icon :icon="caretIcon"></fa-icon>
      </div>
    </div>

    <div class="container relative-container">
      <div class="sidebar transition" :class="{ 'hidden-mobile': !displaySelector }">
        <ul class="nav nav-bordered nav-stacked flex-column">
          <li class="nav-item" v-for="(item, index) in navItems" :key="index">
            <router-link
              class="nav-link"
              active-class="active"
              :to="item.link">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="main-content mt-3">
        <div class="heading d-none d-sm-block mb-3">
          <div class="mb-2" v-if="parentLabel">
            <router-link :to="parentLink" v-if="parentLink">{{ parentLabel }}</router-link>
            <span v-else>{{ parentLabel }}</span>
          </div>

          <div class="dashhead nosub">
            <div class="dashhead-titles">
              <h3 class="dashhead-title">{{ headerTitle }}</h3>
            </div>
          </div>
        </div>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      required: false
    },
    selectLabel: {
      type: String,
      required: false
    },
    navItems: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    parentLabel: {
      type: String,
      required: false
    },
    parentLink: {
      required: false
    }
  },
  data () {
    return {
      displaySelector: false
    }
  },
  computed: {
    caretIcon () {
      return this.displaySelector === true ? 'caret-up' : 'caret-down'
    }
  },
  methods: {
    toggleSelector () {
      this.displaySelector = !this.displaySelector
    },
    handleRouteChange () {
      this.displaySelector = false
    }
  },
  watch: {
    $route: 'handleRouteChange'
  }
}
</script>
