import api from '../api'

export default {
  state: {
    loaded: false,
    hasValidSubscription: null,
    subscriber: {},
    activeSubscription: {}
  },

  mutations: {
    NO_SUBSCRIPTION: (state) => {
      state.loaded = true
      state.hasValidSubscription = false
    },

    SUBSCRIBER_FETCHED: (state, object) => {
      state.subscriber = object
    },

    SUBSCRIPTION_FETCHED: (state, object) => {
      state.activeSubscription = object
      state.hasValidSubscription = true
      state.loaded = true
    },

    SUBSCRIPTION_PROCESSED: (state) => {
      state.hasValidSubscription = true
    }
  },

  getters: {
    getSubscriber (state) {
      return state.subscriber
    },
    getSubscriberId (state) {
      return state.subscriber ? state.subscriber.uid : null
    }
  },

  actions: {
    fetchSubscriber ({ getters, rootState, commit }) {
      return api.subscribers.findByCustomer({
        type: getters.isEnterpriseMember ? 'enterprise' : 'user',
        uid: getters.isEnterpriseMember ? getters.getEnterpriseId : rootState.auth.user.uid
      })
        .then((object) => {
          commit('SUBSCRIBER_FETCHED', object)
        })
    },

    fetchActiveSubscription ({ state, commit }) {
      return api.subscriptions.findActiveSubscription({
        subscriberId: state.subscriber ? state.subscriber.uid : ''
      })
        .then((object) => {
          commit('SUBSCRIPTION_FETCHED', object)
        })
    },

    async searchValidSubscription ({ dispatch, commit }) {
      try {
        await dispatch('fetchSubscriber')
        await dispatch('fetchActiveSubscription')
      } catch (error) {
        commit('NO_SUBSCRIPTION')
      }
    }
  }
}
