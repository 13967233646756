<template>
  <div class="user-settings-tfa">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <form action="" @submit.prevent="submit()">
          <div class="alert alert-danger" v-if="errorType === 'internal'">
            <strong>Server error</strong>
            <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
          </div>

          <div class="alert alert-danger" v-if="errorType === 'invalid_confirm_code'">
            <p class="mb-0">This confirmation code is not valid.</p>
          </div>

          <div class="form-group row">
            <label for="inputPhone" class="col-sm-3 col-form-label">Phone number</label>
            <div class="col-sm-9 col-lg-4">
              <input type="tel" class="form-control" id="inputPhone" v-model="form.phone_number" :class="{ 'is-invalid': errors.phone_number }" :readonly="pendingConfirm" @keydown="handleChange">
              <div class="invalid-feedback" v-if="errors.phone_number">{{ errors.phone_number[0] }}</div>
            </div>
          </div>

          <div class="form-group row mt-3">
            <div class="col-sm-9 col-lg-4 offset-sm-3">
              <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="!modified || !form.phone_number || isProcessing || pendingConfirm">
                Update number
              </button>
            </div>
          </div>
        </form>

        <form action="" @submit.prevent="submitCode()" class="mt-4" v-if="pendingConfirm">
          <p class="mb-3">We've sent you a confirmation code by text message. Please enter this code below to confirm your number.</p>

          <div class="form-group row">
            <label for="inputCode" class="col-sm-3 col-form-label">Validation code</label>
            <div class="col-sm-9 col-lg-4">
              <input type="number" class="form-control" id="inputCode" v-model="confirmCode">
            </div>
          </div>

          <div class="form-group row mt-3">
            <div class="col-sm-9 col-lg-4 offset-sm-3">
              <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': processingConfirm }" :disabled="processingConfirm || isSuccess">
                Verify number
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import asyncProcess from '../../../mixins/asyncProcess'
import SmallLoader from '../../../components/common/SmallLoader'
import api from '../../../api'

export default {
  components: {
    SmallLoader
  },
  mixins: [asyncProcess],
  data () {
    return {
      isLoading: true,
      modified: false,
      form: {
        phone_number: ''
      },
      pendingConfirm: false,
      confirmCode: '',
      processingConfirm: false,
      isSuccess: false
    }
  },
  methods: {
    handleChange () {
      this.modified = true
    },
    submit () {
      this.startProcess()

      api.currentUser.submitTFANumber(this.form)
        .then(() => {
          this.endProcess()
          this.pendingConfirm = true
        })
        .catch(this.handleError)
    },
    submitCode () {
      this.processingConfirm = true
      this.errorType = null

      api.currentUser.confirmTFANumber({ token: this.confirmCode })
        .then(() => {
          this.isSuccess = true
          this.$noty.success('Your number has been verified.')
          this.processingConfirm = false
        })
        .catch(({ type }) => {
          if (type === 'not_found') {
            this.errorType = 'invalid_confirm_code'
          }

          this.processingConfirm = false
        })
    }
  },
  created () {
    api.currentUser.getTFANumber()
      .then((number) => {
        this.form.phone_number = number.phone_number
        this.isLoading = false
      })
      .catch(() => {
        this.$noty.warning('Server error. Please reload the page and try again.')
      })
  }
}
</script>
