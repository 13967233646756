<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create new bucket</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" v-if="errorType === 'internal'">
              <strong>Server error</strong>
              <p class="mb-0">Something went wrong. Please try again in a few minutes.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'forbidden'">
              <strong>Sorry,</strong>
              <p class="mb-0">You are not allowed to perform this action.</p>
            </div>

            <div class="alert alert-danger" v-if="errorType === 'payment'">
              <strong>Limit exceeded</strong>
              <p class="mb-0">You have reached your capacity limits. Please consider upgrading your plan.</p>
            </div>

            <div class="form-group">
              <label for="bucket-name" class="col-form-label">Bucket name:</label>
              <input type="text" class="form-control" id="bucket-name" v-model="form.name" :class="{ 'is-invalid': errors.name }">
              <div class="invalid-feedback" v-if="errors.name">{{ errors.name[0] }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :class="{ 'loading-btn': isProcessing }" :disabled="isProcessing">
              Create bucket
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from '../../../api'

export default {
  data () {
    return {
      isLoading: false,
      errorType: null,
      errors: [],
      form: {
        name: ''
      },
      isProcessing: false
    }
  },
  methods: {
    submit () {
      this.isProcessing = true

      api.buckets.create(this.form)
        .then((bucket) => {
          this.done(bucket)
        })
        .catch((error) => {
          this.errorType = error.type
          this.errors = error.validationErrors
          this.isProcessing = false
        })
    }
  }
}
</script>
