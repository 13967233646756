<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="logo-wrapper">
          <img src="../../../../assets/images/logo-passbucket-320.png" alt="Passbucket | Cloud password manager" width="150" height="94">
        </div>

        <div class="login-box">
          <div class="block">
            <div class="block-body">
              <form action="" @submit.prevent="submit()">
                <div class="alert alert-danger" role="alert" v-if="hasError">
                  <strong>Access denied!</strong>
                  <p class="mb-0">Please check your username and password.</p>
                </div>

                <h5 class="mb-3">Add account</h5>

                <div class="form-group">
                  <input type="email" class="form-control" placeholder="Email" name="email" autocomplete="off" v-model="form.email">
                </div>
                <div class="form-group">
                  <input type="password" class="form-control" placeholder="Password" name="password" autocomplete="off" v-model="form.password">
                </div>

                <div class="text-center mt-3">
                  <button type="submit" class="btn btn-lg btn-primary submit-btn"
                          :class="{ 'loading-btn': isProcessing }"
                          :disabled="formDisabled">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="mt-3 text-center">
            <small><a href="" @click.prevent="cancel()">Cancel</a></small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from '../../../../config'

export default {
  data () {
    return {
      isProcessing: false,
      form: {
        email: '',
        password: ''
      },
      hasError: false,
      error: {}
    }
  },
  computed: {
    formDisabled () {
      return this.form.email.length === 0 || this.form.password.length === 0
    }
  },
  methods: {
    submit () {
      this.isProcessing = true
      this.hasError = false

      this.$store.dispatch('login', this.form)
        .then(() => {
          this.isProcessing = false
          window.location.href = config.auth.memberRedirectPath
          this.cancel()
        })
        .catch((error) => {
          // Handle 2-factor auth
          if (_.get(error, 'response.data.error') === 'tfa_required') {
            const tfaUserToken = _.get(error, 'response.data.user_token')
            this.$store.commit('SET_TFA_USER_TOKEN', tfaUserToken)
            this.$emit('tfa-required')
          }

          this.isProcessing = false
          this.hasError = true
          this.error = error

          if (error.type === 'unauthorized') {
            this.form.password = ''
          }
        })
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
