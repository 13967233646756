<template>
  <div class="modal fade permission-manager-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage permissions</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <section>
            <div class="section-header">
              <h6>Access policy</h6>
            </div>

            <access-policy-form :model="model" :object-id="objectId"></access-policy-form>
          </section>

          <section>
            <div class="section-header">
              <h6>Access lists</h6>
            </div>

            <permission-manager :model="model" :object-id="objectId"></permission-manager>
          </section>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccessPolicyForm from '../../permissions/AccessPolicyForm'
import PermissionManager from '../../permissions/Manager'

export default {
  components: {
    AccessPolicyForm,
    PermissionManager
  },
  data () {
    return {
      model: null,
      objectId: null
    }
  },
  created () {
    this.model = this.data.model
    this.objectId = this.data.objectId
  }
}
</script>
