<template>
  <div class="bucket-settings-user-access">
    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-if="!isLoading">
        <div class="intro-text mb-4">
          You can share this bucket with other members such as friends or colleagues.
          Invited users will have access to the whole content of this bucket: lists, passwords and notes.
          <br>Users can be removed at any time by owners and administrators.
        </div>

        <table class="table table-users">
          <thead>
            <tr>
              <th scope="col" class="col-name">User</th>
              <th scope="col" class="col-level">Access level</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <access-table-row
              v-for="user in bucketUsers"
              :key="user.id"
              :bucket-id="bucketId"
              :user="user"
              @deleted="handleDelete"
              @updated="handleUpdate">
            </access-table-row>
          </tbody>
        </table>

        <h5 class="mb-4">Add a user</h5>

        <form class="form-inline form-add-user" @submit.prevent="createAccess()">
          <label class="sr-only" for="inputUser">Name</label>
          <input type="text" class="form-control mb-2 mr-sm-2" id="inputUser" placeholder="User's email address" v-model="newUser.email">

          <label class="sr-only" for="inputLevel">Access</label>
          <select class="form-control mb-2 mr-sm-2" id="inputLevel" v-model="newUser.access_type">
            <option value="">Select...</option>
            <option value="admin">Administrator</option>
            <option value="write">Write access</option>
            <option value="read">Read access</option>
          </select>

          <button type="submit" class="btn btn-primary mb-2" :class="{ 'loading-btn': adding }" :disabled="adding">
            Add user
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '../../../../api'
import AccessTableRow from '../../../../components/buckets/access/AccessTableRow'
import SmallLoader from '../../../../components/common/SmallLoader'

export default {
  components: {
    AccessTableRow,
    SmallLoader
  },
  data () {
    return {
      isLoading: true,
      bucketId: null,
      bucketUsers: [],
      adding: false,
      newUser: {
        email: '',
        access_type: ''
      }
    }
  },
  methods: {
    createAccess () {
      this.adding = true

      const data = _.assign({}, this.newUser, {
        bucketId: this.bucketId
      })

      api.userAccess.create(data)
        .then((access) => {
          this.bucketUsers.push(access)

          this.$noty.success('User has been added.')

          this.adding = false

          this.newUser = {
            email: '',
            access_type: ''
          }
        })
        .catch(({ type }) => {
          if (type === 'forbidden') {
            this.$noty.warning('Sorry, you can not add a user in this bucket.')
          } else if (type === 'validation') {
            this.$noty.warning('Please provide a valid email address and select a level.')
          } else if (type === 'payment') {
            this.$noty.warning('Limit exceeded. Please consider upgrading your subscription.')
          } else {
            this.$noty.warning('A server error occured, please retry in a few moment!')
          }

          this.adding = false
        })
    },
    handleDelete (accessId) {
      const index = _.indexOf(this.bucketUsers, _.find(this.bucketUsers, { id: accessId }))

      if (index > -1) {
        this.bucketUsers.splice(index, 1)
      }
    },
    handleUpdate (access) {
      const index = _.indexOf(this.bucketUsers, _.find(this.bucketUsers, { id: access.id }))

      if (index > -1) {
        this.bucketUsers.splice(index, 1, access)
      }
    }
  },
  created () {
    this.bucketId = this.$route.params.id

    api.userAccess.getByBucket({ bucketId: this.bucketId })
      .then((bucketUsers) => {
        this.bucketUsers = bucketUsers
        this.isLoading = false
      })
      .catch(() => {
        this.$noty.error('Can not load access list. Please try again later.')
      })
  }
}
</script>

<style lang="scss" scoped>
  .form-add-user {
    align-items: normal;

    input.form-control {
      height: 38px;
    }
  }
</style>
