<template>
  <div class="view-subscribe mt-3 mb-3">
    <div v-if="hasSubscription">
      <thank-you></thank-you>
    </div>
    <div v-else>
      <standard-plan-selector trial="30" v-if="!selectedPlan && !isEnterpriseMember" @selected="handleSelectedPlan"></standard-plan-selector>
      <enterprise-plan-selector trial="30" v-if="!selectedPlan && isEnterpriseMember" @selected="handleSelectedPlan"></enterprise-plan-selector>
      <subscription-form v-if="selectedPlan" :plan="selectedPlan"></subscription-form>
    </div>
  </div>
</template>

<script>
import StandardPlanSelector from '../../components/subscribe/StandardPlanSelector'
import EnterprisePlanSelector from '../../components/subscribe/EnterprisePlanSelector'
import SubscriptionForm from '../../components/subscribe/SubscriptionForm'
import ThankYou from '../../components/subscribe/ThankYou'
import withEnterprise from '../../mixins/withEnterprise'

export default {
  mixins: [withEnterprise],
  components: {
    EnterprisePlanSelector,
    StandardPlanSelector,
    SubscriptionForm,
    ThankYou
  },
  data () {
    return {
      selectedPlan: null
    }
  },
  computed: {
    hasSubscription () {
      return this.$store.state.subscription.hasValidSubscription
    }
  },
  methods: {
    handleSelectedPlan (plan) {
      this.selectedPlan = plan
    }
  },
  created () {
    this.$store.dispatch('searchValidSubscription')
  }
}
</script>
