import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import buckets from './buckets'
import currentBucket from './currentBucket'
import subscription from './subscription'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    buckets,
    currentBucket,
    subscription
  }
})
