import _ from 'lodash'
import asyncProcessMixin from '../asyncProcess'
import Crypto from '../../providers/Crypto'
import NoteForm from '../../components/entries/notes/NoteForm'
import PasswordForm from '../../components/entries/passwords/PasswordForm'

export default {
  mixins: [asyncProcessMixin],
  components: {
    NoteForm,
    PasswordForm
  },
  data () {
    return {
      form: {
        name: '',
        url: '',
        username: '',
        password: '',
        confirm_password: '',
        text: ''
      }
    }
  },
  methods: {
    getEncryptedPayload (payload) {
      const encrypted = _.assign({}, payload)

      if (payload.username) {
        encrypted.username = Crypto.encrypt(payload.username, this.$store.state.auth.sek)
      }

      if (payload.password) {
        // Send hash so that the server can validate both password fields

        encrypted.pwd_hash = Crypto.hash(payload.password)

        if (payload.confirm_password) {
          encrypted.confirm_password = Crypto.hash(payload.confirm_password)
        }

        encrypted.password = Crypto.encrypt(payload.password, this.$store.state.auth.sek)
      }

      if (payload.text) {
        encrypted.text = Crypto.encrypt(payload.text, this.$store.state.auth.sek)
      }

      return encrypted
    }
  }
}
