<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select list</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <select class="form-control" id="list-name" v-model="selectedList">
                <option :value="list.uid" v-for="list in sortedLists" :key="list.uid">{{ list.name }}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="!selectedList">
              Select
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data () {
    return {
      selectedList: null
    }
  },
  computed: {
    lists () {
      return this.$store.state.currentBucket.lists
    },
    sortedLists () {
      return _.orderBy(this.lists, [o => o.name.toLowerCase()], ['asc'])
    }
  },
  methods: {
    submit () {
      this.done(this.selectedList)
    }
  }
}
</script>
