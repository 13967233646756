<template>
  <div class="enterprise-plan-selector">
    <div class="container">
      <div class="dashhead nosub">
        <div class="dashhead-titles">
          <h3 class="dashhead-title">Select a plan</h3>
        </div>
      </div>
    </div>

    <div class="container mt-3 mb-3" v-if="!isLoaded">
      <small-loader></small-loader>
    </div>

    <div class="container mt-3 mb-3" v-if="isLoaded">
      <div class="row">
        <div class="col-sm-4 mb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Small Business</h5>
              <p class="card-text">Up to 5 users</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">AES 256 encryption</li>
              <li class="list-group-item">Advanced permission system</li>
              <li class="list-group-item">Unlimited passwords</li>
              <li class="list-group-item">Unlimited buckets</li>
              <li class="list-group-item">5 enterprise users included</li>
              <li class="list-group-item">No additional user</li>
              <li class="list-group-item">
                <div class="price">29 $ /month</div>
              </li>
            </ul>
            <div class="card-body subscribe-button">
              <button class="btn btn-primary" @click="selectPlan('small_business')" :disabled="!isSmallPlanAvailable">
                Subscribe
              </button>
            </div>
            <div class="card-body" v-if="!isSmallPlanAvailable">
              Your current usage exceeds this plan limits. Remove users if you want to select it.
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Enterprise</h5>
              <p class="card-text">5 users and more</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">AES 256 encryption</li>
              <li class="list-group-item">Advanced permission system</li>
              <li class="list-group-item">Unlimited passwords</li>
              <li class="list-group-item">Unlimited buckets</li>
              <li class="list-group-item">5 enterprise users included</li>
              <li class="list-group-item">Unlimited additional users</li>
              <li class="list-group-item">
                <div class="price">49 $ / month</div>
                <div class="price">+ 7 $ / mo / additional user</div>
              </li>
            </ul>
            <div class="card-body subscribe-button">
              <button class="btn btn-primary" @click="selectPlan('enterprise')">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>

      <p class="mt-2">All prices are in US Dollars. <span v-if="trial">{{ trial }} days FREE trial.</span></p>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import SmallLoader from '../common/SmallLoader'
import withEnterprise from '../../mixins/withEnterprise'

export default {
  props: {
    guest: {
      type: Boolean,
      default: false
    },
    trial: {
      required: false,
      default: null
    }
  },
  mixins: [withEnterprise],
  components: {
    SmallLoader
  },
  data () {
    return {
      isLoaded: false,
      users: 0
    }
  },
  computed: {
    isSmallPlanAvailable () {
      return this.users <= 5
    }
  },
  methods: {
    fetchUsage () {
      return api.usage.getEnterpriseUsage({
        enterpriseId: this.enterpriseId
      })
        .then((usage) => {
          this.users = usage.users
          this.isLoaded = true
        })
    },
    canSelectPlan (type) {
      if (type === 'small_business' && !this.isSmallPlanAvailable) {
        return false
      }

      return true
    },
    selectPlan (type) {
      const selectable = this.canSelectPlan(type)

      if (selectable) {
        this.$emit('selected', type)
      }
    }
  },
  created () {
    if (this.guest) {
      this.isLoaded = true
    } else {
      this.fetchUsage()
    }
  }
}
</script>
