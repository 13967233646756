<template>
  <div class="comp-team-group-members mt-3">
    <h3 class="mb-3">Group members</h3>

    <div class="row justify-content-between mb-3">
      <div class="col-6 col-sm-5 col-md-4">
        <quick-filter v-model="nameFilter"></quick-filter>
      </div>
      <div class="col-6 col-sm-7 col-md-8 text-right"></div>
    </div>

    <div class="card">
      <div class="card-body" v-if="isLoading">
        <small-loader></small-loader>
      </div>
      <div class="card-body" v-else>
        <div class="select-wrapper mb-3">
          <vue-select v-model="selectedUser"
                      :placeholder="selectPlaceholder"
                      :options="selectOptions"
                      label="fullname"
                      @search="handleSearch"
                      :disabled="isInserting"
                      :resetOnOptionsChange="true">
            <template slot="no-options">
              Enter a name...
            </template>
          </vue-select>
        </div>

        <div class="table-responsive">
          <table class="table table-team-groups">
            <thead>
            <tr>
              <th scope="col" class="col-name">Name</th>
              <th scope="col" class="col-created">Added</th>
              <th scope="col" class="col-actions"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="member in sortedMembers" :key="member.user.uid">
              <td class="col-name">
                <router-link :to="{ name: 'team.users.manage', params: { userId: member.user.uid } }">
                  {{ member.user.fullname }}
                </router-link>
              </td>
              <td class="col-email d-none d-lg-table-cell">{{ member.created_at }}</td>
              <td class="col-actions text-right">
                <span class="action" @click="deleteMember(member)">
                  <fa-icon icon="times"></fa-icon>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { VueSelect } from 'vue-select'
import api from '../../../api'
import SmallLoader from '../../../components/common/SmallLoader'
import QuickFilter from '../../../components/common/QuickFilter'

export default {
  props: {
    enterpriseId: {
      required: true,
      type: String
    },
    groupId: {
      required: true,
      type: String
    }
  },
  components: {
    SmallLoader,
    VueSelect,
    QuickFilter
  },
  data () {
    return {
      isLoading: true,
      members: [],
      nameFilter: '',
      selectedUser: '',
      selectOptions: [],
      isInserting: false
    }
  },
  computed: {
    sortedMembers () {
      return _.orderBy(this.filteredMembers, ['user.fullname'], ['asc'])
    },
    filteredMembers () {
      if (this.nameFilter.length > 0) {
        return _.filter(
          this.members,
          o => o.user.fullname.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1
        )
      }
      return this.members
    },
    selectPlaceholder () {
      return this.isInserting ? 'Processing...' : 'Add a user to group'
    }
  },
  methods: {
    deleteMember (member) {
      const index = this.members.indexOf(member)
      if (index) {
        this.members.splice(index, 1)
      }

      api.usergroups.removeMember({
        enterpriseId: this.enterpriseId,
        groupId: this.groupId,
        userId: member.user.uid
      })
        .catch(({ type }) => {
          this.members.push(member)

          if (type === 'forbidden') {
            this.$noty.warning('You are not allowed to perform this action.')
          } else {
            this.$noty.warning('Error while removing member.')
          }
        })
    },
    handleSearch (search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce((loading, search, vm) => {
      vm.selectOptions = []

      api.users.search({
        enterpriseId: vm.enterpriseId,
        q: encodeURI(search)
      })
        .then((users) => {
          vm.selectOptions = users
          loading(false)
        })
    }, 350)
  },
  created () {
    api.usergroups.getMembers({
      enterpriseId: this.enterpriseId,
      groupId: this.groupId
    })
      .then((groupMembers) => {
        this.members = groupMembers
        this.isLoading = false
      })
  },
  watch: {
    selectedUser (user) {
      if (user && user.uid) {
        this.isInserting = true
        this.selectedUser = ''
        this.selectOptions = []

        api.usergroups.addMember({
          enterpriseId: this.enterpriseId,
          groupId: this.groupId,
          user_id: user.uid
        })
          .then((member) => {
            if (member) {
              this.$noty.success('User has been added to group.')
              this.members.push(member)
            } else {
              this.$noty.info('User already in group.')
            }

            this.isInserting = false
          })
      }
    }
  }
}
</script>
