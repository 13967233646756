<template>
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <form action="" @submit.prevent="submit()">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select bucket</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <select class="form-control" v-model="selectedBucket">
                <option :value="bucket.uid" v-for="bucket in sortedBuckets" :key="bucket.uid">{{ bucket.name }}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="!selectedBucket">
              Select
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data () {
    return {
      selectedBucket: null
    }
  },
  computed: {
    buckets () {
      return this.$store.state.buckets.list
    },
    sortedBuckets () {
      return _.orderBy(this.buckets, [o => o.name.toLowerCase()], ['asc'])
    }
  },
  methods: {
    submit () {
      this.done(this.selectedBucket)
    }
  }
}
</script>
