/* eslint-disable object-curly-newline */
import _ from 'lodash'
import Vue from 'vue'
import api from '../api'

export default {
  state: {
    loading: false,
    bucket: null,
    loadingLists: false,
    loadingEntries: false,
    lists: [],
    entries: [],
    activeList: '',
    selectedItems: []
  },

  mutations: {
    CURRENT_BUCKET_LOADING: (state) => {
      state.loading = true
    },

    SET_CURRENT_BUCKET: (state, obj) => {
      state.bucket = obj
      state.loading = false
    },

    BUCKET_LISTS_LOADING: (state) => {
      state.loadingLists = true
    },

    BUCKET_ENTRIES_LOADING: (state) => {
      state.loadingEntries = true
    },

    BUCKET_LISTS_LOADED: (state, collection) => {
      state.lists = collection || []
      state.loadingLists = false
    },

    BUCKET_ENTRIES_LOADED: (state, collection) => {
      state.entries = collection
      state.loadingEntries = false
    },

    BUCKET_LISTS_CREATED: (state, object) => {
      state.lists.push(object)
    },

    BUCKET_LISTS_UPDATED: (state, object) => {
      const index = state.lists.indexOf(_.find(state.lists, { uid: object.uid }))

      if (index > -1) {
        state.lists.splice(index, 1, object)
      }
    },

    BUCKET_LISTS_DELETED: (state, uid) => {
      const index = state.lists.indexOf(_.find(state.lists, { uid }))

      if (index > -1) {
        state.lists.splice(index, 1)
      }
    },

    BUCKET_ENTRIES_CREATED: (state, object) => {
      state.entries.push(object)
    },

    BUCKET_ENTRIES_DELETED: (state, uid) => {
      const index = state.entries.indexOf(_.find(state.entries, { uid }))

      if (index > -1) {
        state.entries.splice(index, 1)
      }
    },

    BUCKET_ENTRIES_UPDATED: (state, entry) => {
      const index = state.entries.indexOf(_.find(state.entries, { uid: entry.uid }))

      if (index > -1) {
        state.entries.splice(index, 1, entry)
      }
    },

    SET_ACTIVE_BUCKET_LIST: (state, listId) => {
      state.activeList = listId
    },

    BUCKET_ENTRIES_SELECT: (state, uid) => {
      state.selectedItems.push(uid)
    },

    BUCKET_ENTRIES_UNSELECT: (state, uid) => {
      const index = state.selectedItems.indexOf(uid)

      if (index > -1) {
        state.selectedItems.splice(index, 1)
      }
    },

    BUCKET_ENTRIES_UNSELECT_ALL: (state) => {
      state.selectedItems = []
    }
  },

  actions: {
    loadBucket ({ commit, state, rootState, dispatch }, { bucketId, listId }) {
      commit('CURRENT_BUCKET_LOADING')

      // TODO: Handle bucket not found exception

      const bucket = _.find(rootState.buckets.list, { uid: bucketId })

      if (bucket) {
        commit('SET_CURRENT_BUCKET', bucket)

        dispatch('fetchLists')

        if (listId) {
          dispatch('fetchListEntries', listId)
        } else {
          dispatch('fetchEntries')
        }
      }
    },

    fetchLists ({ commit, state }) {
      commit('BUCKET_LISTS_LOADING')

      return api.lists.getByBucket({ id: state.bucket.uid })
        .then((list) => {
          commit('BUCKET_LISTS_LOADED', list)
        })
    },

    fetchEntries ({ commit, state }) {
      commit('BUCKET_ENTRIES_LOADING')
      commit('SET_ACTIVE_BUCKET_LIST', '')
      commit('BUCKET_ENTRIES_UNSELECT_ALL')

      return api.entries.getByBucket({ id: state.bucket.uid })
        .then((list) => {
          commit('BUCKET_ENTRIES_LOADED', list)
        })
    },

    fetchListEntries ({ commit, state }, listId) {
      commit('BUCKET_ENTRIES_LOADING')
      commit('SET_ACTIVE_BUCKET_LIST', listId)
      commit('BUCKET_ENTRIES_UNSELECT_ALL')

      return api.entries.getByList({
        bucketId: state.bucket.uid,
        listId
      })
        .then((items) => {
          commit('BUCKET_ENTRIES_LOADED', items)
        })
    },

    refreshEntries ({ state, dispatch }) {
      if (state.activeList) {
        dispatch('fetchListEntries', state.activeList)
      } else {
        dispatch('fetchEntries')
      }
    },

    createListInCurrentBucket ({ commit, state }, { name }) {
      const postData = {
        name,
        id: state.bucket.uid
      }

      return api.lists.createInBucket(postData)
        .then((object) => {
          commit('BUCKET_LISTS_CREATED', object)
        })
    },

    toggleSelectEntry ({ state, commit }, uid) {
      if (state.selectedItems.indexOf(uid) > -1) {
        commit('BUCKET_ENTRIES_UNSELECT', uid)
      } else {
        commit('BUCKET_ENTRIES_SELECT', uid)
      }
    },

    deleteSelectedEntries ({ state, dispatch }) {
      if (state.selectedItems.length > 0) {
        api.entries.bulkDelete({ bucketId: state.bucket.uid, entries: state.selectedItems })
          .then((response) => {
            Vue.noty.success(`${response.deleted.length} item(s) deleted.`)
            dispatch('refreshEntries')
          })
          .catch((error) => {
            if (error.type === 403) {
              Vue.noty.warning('Sorry, you are not allowed to delete selected entries.')
            } else {
              Vue.noty.warning('Oops! Something went wrong, please try again.')
            }
          })
      }
    },

    moveSelectedEntriesToList ({ state, dispatch }, list) {
      if (state.selectedItems.length > 0) {
        api.entries.bulkMoveToList({
          listId: list.uid,
          bucketId: state.bucket.uid,
          entries: state.selectedItems
        })
          .then((response) => {
            Vue.noty.success(`${response.moved.length} item(s) moved to "${list.name}".`)
            dispatch('refreshEntries')
          })
          .catch((error) => {
            if (error.type === 403) {
              Vue.noty.warning('Sorry, you are not allowed to move selected entries.')
            } else {
              Vue.noty.warning('Oops! Something went wrong, please try again.')
            }
          })
      }
    },

    moveSelectedEntriesToBucket ({ state, dispatch }, bucket) {
      if (state.selectedItems.length > 0) {
        api.entries.bulkMoveToBucket({
          bucketId: bucket.uid,
          entries: state.selectedItems
        })
          .then((response) => {
            Vue.noty.success(`${response.moved.length} item(s) moved to "${bucket.name}".`)
            dispatch('refreshEntries')
          })
          .catch((error) => {
            if (error.type === 403) {
              Vue.noty.warning('Sorry, you are not allowed to move selected entries.')
            } else {
              Vue.noty.warning('Oops! Something went wrong, please try again.')
            }
          })
      }
    }
  }
}
