import _ from 'lodash'
import http from './http'

function extractUrlVariables (url) {
  const variables = []

  const segments = url.split('/')

  segments.forEach((segment) => {
    if (segment.charAt(0) === ':') {
      variables.push(_.trim(segment, ':'))
    }
  })

  return variables
}

function getActionUrl (pattern, variables, params = {}) {
  let actionUrl = pattern

  variables.forEach((variable) => {
    const value = params[variable] ? params[variable] : ''
    actionUrl = _.replace(actionUrl, `:${variable}`, value)
  })

  return _.trimEnd(actionUrl, '/')
}

function getRequestParams (urlVariables, params = {}) {
  const requestParams = {}

  Object.keys(params).forEach((key) => {
    if (urlVariables.indexOf(key) === -1) {
      requestParams[key] = params[key]
    }
  })

  return requestParams
}

export default function (url, actions = {}) {
  const urlPattern = _.trimEnd(url, '/')
  const urlVariables = extractUrlVariables(url)

  const defaultActions = {

    query (data) {
      const config = {}

      const requestUrl = getActionUrl(urlPattern, urlVariables, data)
      const requestParams = getRequestParams(urlVariables, data)

      if (Object.keys(requestParams).length > 0) {
        config.params = requestParams
      }

      return http.get(requestUrl, config)
    },

    get (data) {
      const requestUrl = getActionUrl(urlPattern, urlVariables, data)
      return http.get(requestUrl)
    },

    create (data) {
      const requestUrl = getActionUrl(urlPattern, urlVariables, data)
      const body = _.omit(data, urlVariables)
      return http.post(requestUrl, body)
    },

    update (data) {
      const requestUrl = getActionUrl(urlPattern, urlVariables, data)
      const body = _.omit(data, urlVariables)
      return http.put(requestUrl, body)
    },

    delete (data) {
      const requestUrl = getActionUrl(urlPattern, urlVariables, data)
      return http.delete(requestUrl)
    }
  }

  Object.keys(actions).forEach((key) => {
    const customActionUrl = actions[key].url || urlPattern
    const customActionVariables = extractUrlVariables(customActionUrl)
    const actionMethod = actions[key].method || 'GET'

    defaultActions[key] = (data) => {
      const body = _.omit(data, customActionVariables)

      let params = null

      if (actionMethod === 'GET') {
        const requestParams = getRequestParams(customActionVariables, data)

        if (Object.keys(requestParams).length > 0) {
          params = requestParams
        }
      }

      return http({
        method: actionMethod,
        url: getActionUrl(customActionUrl, customActionVariables, data),
        data: body,
        params
      })
    }
  })

  return defaultActions
}
